<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row style="padding: 0px 100px 10px 20px">
        <div>
          <h2>
            Air New Zealand x Brand USA <br />
            USA Getaway - Competition Terms and Conditions
          </h2>
        </div>
        <v-col cols="12" class="pa-0">
          <ol>
            <li>
              Information on how to enter the promotion forms part of these terms and conditions. Entries not made in
              accordance with these terms and conditions will be disqualified. Acceptance of the prize by the prize
              winner
              is deemed acceptance of these terms and conditions.
            </li>
            <li>
              The promotion commences at 00:01 05 February 2024 and closes at 23:59 10 March 2024 (the “Promotional
              Period”). All times recorded throughout these terms and conditions are in Australian Eastern Daylight Time
              (AEDT). Entries received outside the Promotional Period will be ineligible for entry.
            </li>
            <li>
              The promoter is Air New Zealand Limited, Level 12, 7 Macquarie Place, Sydney, NSW, 2000, (ABN 70 000 312
              685) (the “Promoter”).
            </li>
            <ol type="a">
              <li>
                Entry is only eligible for travel agents registered to duo (duo.airnzagent.com.au). Agents must be 18
                years or over, who comply with the entry criteria set out in these terms and conditions (“Entrants”).
                Entrants must be 21 years or over at the time of departure to be eligible for the MAJOR PRIZE draw.
              </li>
            </ol>
            <li>
              Employees of the Promoter and their immediate families, participating agents and their agencies associated
              with this Promotion are ineligible to enter. The Promoter reserves the right to verify the validity of
              each
              entry. The Promoter reserves the right to disqualify any person for tampering with the entry process.
            </li>
            <li>
              <b>Conditions of Entry:</b>
            </li>
            <li>
              To be eligible for entry into the <b>MAJOR PRIZE</b> draw, entrants must:
              <ol type="a">
                <li>
                  Register for <b>duo</b>
                </li>
                <li>
                  Log a minimum of one (1) eligible* Air New Zealand booking during the campaign period from Australia
                  to
                  the USA via <b>duo</b>sales
                </li>
                <li>
                  Complete all weekly USA Getaway quizzes. There are five (5) quizzes in total - Los Angeles, San
                  Fransisco, Houston, Chicago, New York. If one (1) week/destination is missed, entry is ineligible.
                </li>
              </ol>
              <br />
              <p>
                *Eligible tickets are classified as a child or adult one-way or return airfare sold and issued on 086
                ticket stock and operated by Air New Zealand from an Australian gateway to the USA (New York, Los
                Angeles,
                San Francisco Houston, Chicago) during the Promotional Period.
              </p>
              <p>
                By completing the steps above in section 6, participants will receive one (1) entry into the MAJOR PRIZE
                draw. For further entries into the draw, every *Eligible Ticket* logged through duosales will receive an
                additional one (1) entry.
              </p>
              <p>
                Cancelled bookings made during the Promotional Period will be ineligible for entry into the promotion.
                There is no limit to the number of entries an agent can earn. No payment is required to enter.
              </p>
            </li>
            <li>
              To be eligible for entry into the <b>MINOR PRIZE</b> draw, entrants must:
            </li>
            <ol type="a">
              <li>
                Register for <b>duo</b>
              </li>
              <li>
                complete all weekly quizzes. There are five (5) quizzes in total - Los Angeles, San Fransisco, Houston,
                Chicago, New York.
              </li>
              <br />
            </ol>
            <p>
              No logged sales are required to be eligible for the <b>MINOR PRIZE</b> draw.
            </p>
            <li>
              <b>Prizes:</b>
              <br />
              Prizes are as follows:
              <br />
              <b>MAJOR PRIZE:</b>
              <ul style="list-style-type:disc">
                <li>
                  Eight (8) winners will receive one (1) spot on an organised 5-night mystery famil to the USA,
                  including
                  accommodation, selected activities, and meals (which will be outlined in the itinerary prior to
                  departure) and return economy airfares on Air New Zealand, including airport taxes.
                </li>
                <li>
                  The mystery USA destination and exact travel dates in May 2024 will be advised prior to departure.
                </li>
                <li>
                  The eight (8) winners of the MAJOR PRIZE will be selected based on the participants who:
                  <ol type="a">
                    <li>
                      Have registered at least one (1) “Eligible Ticket” to the USA (as per clause 6b)
                    </li>
                    <li>
                      Completed all five (5) weeks of the USA Getaway quizzes in the fastest time, with the most correct
                      answers and answered the final question <i>“Tell us in 25 words or less why you want to experience
                        each
                        destination’.</i>
                    </li>
                  </ol>
                </li>
              </ul>
              <br />
              <p>
                Prize winners will be evaluated based on skill. The prize may have an impact on your tax and FBT, you
                are
                encouraged to seek your own tax advice.
              </p>
              <p>
                FBT is the responsibility of the winning consultant. The prize winner must be employed as a travel agent
                in Australia at the time of prize redemption.
              </p>


              <b>MINOR PRIZES:</b>
              <br />
              There are a total of fifty (55) minor prizes:
              <br />
              <ul style="list-style-type:disc">
                <li>
                  Five (5) double passes to Chicago the Musical in either Melbourne, Sydney or Adelaide. Dates to be
                  decided with the prize winner. One (1) double pass drawn weekly over 5 weeks during the Promotional
                  Period.
                </li>
                <li>
                  Fifty (50) destination themed prizes up to the value of AUD $50. Ten (10) prizes will be drawn weekly
                  over five (5) weeks during the Promotional Period.
                </li>
              </ul>
              <p>
                The prize value is not redeemable for cash, non-transferrable or exchangeable.
              </p>
            </li>
            <li>
              Prizes will be drawn the week commencing 11 March 2024. Attempts will be made to notify winners by email
              from the email address provided on the promotion entry. Subject to any written directions given under Reg.
              37 of the Lottery and Gaming Regulations (SA) or other applicable law, if the Promoter, having used
              reasonable efforts, is unable to contact the winner by 17:00 the following Business Day of the draw, then
              the prize is forfeited, and further draw(s) will be conducted until the prize is awarded (subject to any
              further regulatory directions).
            </li>
            <li>
              The Promoter’s decision is final, and no correspondence will be entered into.
            </li>
            <li>
              The total prize pool is valued at approximately AUD$45,000. This is the total value across the MAJOR and
              MINOR prizes.
            </li>
            <li>
              The prizes cannot be converted into cash. The prizes are not transferable to other persons or
              organisations.
              If a prize or receipt of it incurs a tax liability, the relevant prize winner is liable for payment of
              such
              tax.
            </li>
            <li>
              Subject to the State legislation, the Promoter reserves the right to substitute the prize in whole (or any
              of its components), with a substitute prize of equal or greater value and the prize winner will be
              notified
              accordingly.
            </li>
            <li>
              All prize travel will be subject to Air New Zealand’s conditions of carriage, to view visit
              airnewzealand.com.au. Airpoints Dollars™ cannot be accrued on prize air travel. Prize cannot be used in
              conjunction with any other special offer.
            </li>
            <li>
              Neither the Promoter nor any other person or party associated with the Promotion shall be liable for any
              delayed, lost, or misdirected mail, email, or other communication. Further, to the fullest extent
              permitted
              by law, the Promoter excludes liability for any error, omission, interruption, deletion, defect, delay in
              operation or transmission, communications line failure, technical problems or traffic congestion on the
              internet or any website, theft or destruction or unauthorised access to or alteration of entries. The
              Promoter assumes no responsibility for any injury or damage to participants or any other person’s computer
              related to or resulting from participation in or downloading any materials in this promotion. The use of
              any
              automated entry software or any other mechanical or electronic means that allows an entrant to
              automatically
              enter repeatedly is prohibited and will render all entries submitted by that entrant invalid.

            </li>
            <li>
              Neither the Promoter nor any other person or party associated with this promotion shall be liable for any
              loss or damage whatsoever suffered (including but not limited to direct, indirect, consequential, or
              economic loss) or personal injury suffered or sustained in connection with either participation in this
              promotion or with any prizes offered, except for any liability that cannot be excluded.
            </li>
            <li>
              The name of the prize winner(s) from any redraw will be published on the duo campaign landing page. In the
              event that the redraw winner does not claim their prize or is ineligible, the Promoter will conduct
              further redraws until the prize is awarded (subject to any further regulatory directions).
            </li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  text-align: left;
  font-family: "AirNZ Sans Semibold";
  font-size: 30px;
  margin: 0px 0px 20px 0px;
}

li {
  margin-top: 10px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>