<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1266" max-height="345" style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-tiles/LuxInLisbon.Landing.Hero.jpg'" />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; ">
          <h2 class="duo-purple">
            Heading to Global Gathering? Here’s your chance to WIN the ultimate experience!
          </h2>
          <p><b>It's your time to shine with a VIP upgrade, thanks to Air New Zealand.</b></p>

          <div style="padding: 1rem 2rem 1rem 2rem; background-color:#EAEAEA;">
            <p>
              Here’s what’s up for grabs for 5 lucky winners: <br />
            </p>
            <div>
              <v-img width="35px" :src="$bucket + '/image/dashboard-tiles/LuxInLisbon.Mic.png'"
                style="display: inline-block; vertical-align: middle;"></v-img>
              <span class="pl-4">
                <b>Exclusive meet and greet backstage with the after-party main act, including photo opportunity. A
                  money-can't-buy experience just for you!</b>
              </span>
            </div>

            <p><b>PLUS:</b></p>
            <ul class="pl-10">
              <li>Return Airport transfers with Blacklane limousines in Lisbon</li>
              <li>Upgraded accommodation to a single room (no sharing)</li>
              <li>Luxe gift pack – including champagne, face masks, personalised luggage tag, and snacks in a
                personalised tote bag</li>
              <li>€50 room service voucher</li>
              <li>Limousine transfer to the Gala Dinner for you and two friends</li>
            </ul>
          </div>
        </div>
      </v-row>

      <v-row class="pt-6" style="max-width: 1138px">
        <p>
          <b>How do you enter?</b>
          <br />
          <br />
          Simply, <u>register</u> for the incentive and then sell flights via your GDS to any destination on the Air New
          Zealand network (086 ticket stock).
          <br />
          <br />
          The prize will be awarded to the top 5 sellers during the incentive period - so the more you sell, the more
          chances to WIN.
        </p>
      </v-row>

      <v-row style="padding: 1rem 0 1rem 0;">
        <v-btn height="50" width="280"
          style="font-size: 16px; background-color: #440099 !important; color: white; text-transform: none !important;"
          @click="showPopup">
          Register Now
        </v-btn>
      </v-row>

      <v-row>
        <div>
          <p><b>Not heading to Global, don’t worry – we’ve also got you covered.</b></p>
          <p>Make sure you register, because there are more prizes up for grabs: </p>
          <ul>
            <li>5x prizes of a $200 Gift Card, one drawn per week.</li>
          </ul>
          <p>These prizes will be drawn at random from all eligible registered agents, so sign up and be in
            the running to WIN.</p>
        </div>
      </v-row>

      <v-row>
        <a style="font-size: 13px; color: black; font-style: italic;"
          :href="$window.location.origin + '/#/page/LuxeinLisbonTermsandConditions'" target="_blank">Terms and Conditions apply.</a>
      </v-row>
    </v-container>

    <v-dialog v-model="show" width="900px" persistent>
      <v-card style="border-radius: 1rem;">
        <div style="background-color: #1C1044; color: white; padding: 1rem;">
          <div style="display:flex; justify-content: end">
            <v-icon large color="white" @click="closePopup">mdi-close</v-icon>
          </div>
          <div style="display: flex; flex-direction: column; align-items: center; text-align: center; padding: 0 0 2rem
            0;">
            <h2
              style="font-family: 'AirNZ Black Italic'; font-size: 46px; font-weight: normal; line-height: 3rem; padding: 0 0 2rem 0;">
              Thanks, you've
              registered <br /> for the incentive!</h2>
            <p style="font-size: 20px; font-weight: normal; padding: 0 6rem;">To be in to WIN the major prize, sell flights to any international
              destination on the Air New Zealand network via your GDS before 30 June 2024.</p>
            <p class="mt-0" style="font-size: 20px; font-weight: normal;">The more you sell, the more chances to WIN</p>
            <p class="mt-0">For more information, including full prize inclusions - click below</p>
            <div style="padding-top: 1rem;">
              <v-btn style="font-size: 16px; height: 45px; width: 180px; color: black; text-transform: none !important;"
                @click="closePopup">Find out More</v-btn>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between; padding: 1rem 2rem;">
            <div width="250">
              <v-img max-width="auto" style="object-fit: contain !important;"
                :src="$bucket + '/image/dashboard-tiles/LuxInLisbon.FlightCentre.Logo.png'" />
            </div>
            <div>
              <img width="280" style="object-fit: contain !important;"
                :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 28px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    showPopup() {
      this.show = true
      this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: "Register LuxeInLisbon" });
    },
    closePopup() {
      this.show = false
    }
  }
};
</script>