<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1271px"
            max-height="376px"
            :src="$bucket + '/image/dashboard-pages/EconomyStretch_Banner.jpg'"
          />
        </v-col>
      </v-row>
      <v-row style="padding: 0px 100px 10px 20px">
        <h2>Introducing Economy Stretch</h2>
        <v-col cols="12" class="pa-0">
          <p>
            Need a little extra space for your legs? Introducing the new Economy
            Stretch, so you can stretch out, relax and make your journey even
            more comfortable.
          </p>

          <p><b>What is Economy Stretch? </b></p>

          <p>
            Economy Stretch is an Economy comfort option offered on flights 
            operating between Auckland and Los Angeles, San Francisco select services to Houston. You get up to 39% more
            legroom than our standard Economy seats, so you can stretch out in
            even more comfort. You'll arrive more refreshed and ready to make
            the most of your time away.
            <br />
            <i>*Note economy stretch is not available on Aircraft type 773</i>
          </p>
          

          <p>
            <b
              >Stretch out and relax to make your journey even more
              comfortable.</b
            >
          </p>

          <ul class="ml-12 pl-6">
            <li>
              More legroom means you have extra space to stretch your legs.
            </li>
            <li>
              The seats are in the first four rows of Economy, so you disembark
              sooner.
            </li>
            <li>
              The seats are further apart for you to enjoy a full recline during
              the flight.
            </li>
            <li>
              The extra space makes it easier for you to access the aisle from
              the window or middle seat.
            </li>
          </ul>
          <p><b>Enjoy two more complimentary extras:</b></p>
          <ul class="ml-12 pl-6">
            <li>
              Comfortable over-ear headphones, perfect for longer trips and for
              shutting out everything except your in-flight entertainment.
            </li>
            <li>
              A more generous plush pillow, so you can stretch out and relax in
              even more comfort.
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row class="pa-0 d-flex">
        <v-col cols="6" class="py-4">
          <v-img
            height="360"
            :src="$bucket + '/image/dashboard-pages/EconomyStretch_seat.jpg'"
          />
        </v-col>
        <v-col cols="6" class="py-4">
          <v-img
            height="360"
            :src="
              $bucket + '/image/dashboard-pages/EconomyStretch_experience.jpg'
            "
          />
        </v-col>
      </v-row>

      <v-row class="px-3 mb-8 d-flex">
        <v-col cols="12">
          <p>
            The seat is the same as Economy and all our other Economy services
            remain the same. You'll still enjoy our world-class inflight dining,
            a great selection of entertainment and our award-winning service.
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-0 d-flex justify-center text-center">
        <v-col cols="12">
          <iframe
            width="1250"
            height="706"
            src="https://www.youtube.com/embed/2SKZDajLyPw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col>
          <p>
            <b
              >To find our more and how to book your customers Economy Stretch,
              visit
              <a href="https://www.airnzagent.com.au/economy-stretch" target="0"
                >here</a
              ></b
            >
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>

  <economy-stretch-mobile v-else></economy-stretch-mobile>
</template>

<style scoped>
.video-title {
  font-size: 28px;
  font-family: "AirNZ Sans Semibold";
  float: left;
}

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";
import economyStretchMobile from './mobile/economyStretchMobile.vue';

export default {
  components: {
    subHeader,
    economyStretchMobile
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>