<template>
	<v-container fluid>
		<subHeaderMobile />
		<v-row class="mx-0">
			<v-col cols="12" class="mb-8 px-0">
				<v-row no-gutters class="my-4" style="font-size: 28px !important"> <b class="pr-2">Edit</b> company details </v-row>
				<v-card class="pa-9" rounded="lg">
					<v-row no-gutters>
						<p class="duo-description"><b>Agency</b></p>
					</v-row>
					<v-row no-gutters>
						<p class="duo-description text-justify">
								It's important to keep your agency details up to date to ensure your duo experience is personalised. If you've moved agencies, let us know by updating the fields below and we'll be in touch once your profile has been
								updated.
						</p>
					</v-row>
					<v-form v-model="valid">
						<v-row no-gutters align="center">
							<v-checkbox v-model="agencyDetailRequestChange" class="duo-description" label="My agency details have changed. I'd like to request an update." @change="agencyDetailChangeChecked()"></v-checkbox>
						</v-row>
						<!-- pcc, agency group, agency name -->
						<v-row no-gutters justify="left">
							<!-- Agency Pseudo Code (PCC) -->
							<v-col  class="mr-12" cols="12" md="3" style="min-width: 255px">
								<v-row no-gutters class="pb-1" style="font-size: 17px" :style="agencyDetailRequestChange ? 'color: #2d2926 !important' : 'color: rgba(0, 0, 0, .38)'"> Agency Account Code (PCC)* </v-row>
								<v-row no-gutters>
									<v-text-field
										v-model="entity.map.agency.code"
										label="Please enter your Agency Agency Code (PCC)*"
										:rules="[ruleAgencyCode]"
										@keyup="uppercase"
										maxlength="9"
										:loading="agencyCodeFetching"
										:class="{ 'line-blue': entity.map.agency.code != '' }"
										:filled="!agencyDetailRequestChange"
										:disabled="!agencyDetailRequestChange"
										class="duo-description rounded-0"
										flat
										solo
										dense
										full-width
									></v-text-field>
								</v-row>
							</v-col>
							<!-- Agency Pseudo Code (PCC) end -->
							<!-- Parent Account -->
							<!-- <v-col cols="12" md="3" style="min-width: 255px">
								<v-row no-gutters class="pb-1" style="font-size: 17px" :style="agencyDetailRequestChange ? 'color: #2d2926 !important' : 'color: rgba(0, 0, 0, .38)'"> Parent account </v-row>
								<v-row no-gutters style="width: 255px">
									<v-tooltip v-model="show" top>
										<template v-slot:activator="{ on }">
											<div v-on="on">
												<v-select
													v-model="entity.map.agency.agencyGroupId"
													:items="agencyGroupList"
													item-value="id"
													item-text="name"
													:subHeader-props="{ auto: true }"
													label="Enter your PCC for details"
													:rules="[ruleAgencyGroupId]"
													:class="{ 'line-blue': entity.map.agency.agencyGroupId != '' }"
													:filled="!agencyDetailRequestChange"
													disabled
													class="duo-description rounded-0"
													flat
													solo
													dense
													full-width
													append-icon="mdi-chevron-down"
												>
													<template v-slot:item="{ item }">
														<span class="duo-description">{{ item.name }}</span>
													</template>
												</v-select>
											</div>
										</template>
										<span>{{currentAgencyGroupName}}</span>
									</v-tooltip>
								</v-row>
							</v-col> -->
							<!-- Parent Account end -->
							<!-- Agency name -->
							<v-col cols="12" md="3">
								<v-row no-gutters class="pb-1" style="font-size: 17px" :style="agencyDetailRequestChange ? 'color: #2d2926 !important' : 'color: rgba(0, 0, 0, .38)'"> Account name </v-row>
								<v-row no-gutters>
									<v-tooltip v-model="show" top>
										<template v-slot:activator="{ on }">
											<v-col v-on="on" class="d-flex" :style="{height: agencyDetailRequestChange ? '':'60px'}">
												<template
													><v-text-field
														v-if="agencyDetailRequestChange"
														v-model="entity.map.agency.name"
														label="Enter your PCC for details"
														class="duo-description rounded-0"
														persistent-hint
														:rules="[ruleAgencyName]"
														multi-line
														filled
														disabled
														flat
														solo
													></v-text-field>
													<span v-else style="color: rgba(0, 0, 0, 0.38);" class="pl-3">{{entity.map.agency.name}}</span>
												</template>
											</v-col>
										</template>
										<span>{{entity.map.agency.name}}</span>
									</v-tooltip>
								</v-row>
							</v-col>
							<!-- Agency name end -->
						</v-row>
						<!-- pcc, agency group, agency name end -->

						<v-row no-gutters class="py-4">
							<v-divider class="line-space"></v-divider>
						</v-row>

						<!-- job title -->
						<v-row no-gutters align="center" justify="space-between" style="height: 70px">
							<v-col cols="12" md="3" style="min-width: 255px">
								<v-row no-gutters class="pb-1" style="font-size: 17px; color: #2d2926 !important"> Job title </v-row>
								<v-row no-gutters>
									<v-select
										v-model="entity.map.jobTitle"
										:items="['Area Leader', 'Assistant Team Leader', 'Business Manager', 'Consultant', 'Manager', 'Owner', 'Product Manager', 'Senior Consultant', 'Team Leader']"
										label="Select your Job title"
										persistent-hint
										:class="{ 'line-blue': entity.map.jobTitle != null }"
										class="duo-description rounded-0"
										flat
										solo
										dense
										append-icon="mdi-chevron-down"
									>
										<template v-slot:item="{ item }">
											<span class="duo-description">{{ item }}</span>
										</template>
									</v-select>
								</v-row>
							</v-col>
						</v-row>
						<!-- job title end -->

						<v-row no-gutters>
							<v-divider class="line-space my-4"></v-divider>
						</v-row>

						<!-- action -->
						<v-row no-gutter justify="space-between">
							<v-col cols="6">
								<v-btn
									:disabled="valid ? false : true"
									color="#00B0B9"
									block
									height="50px"
									outlined
									elevation="0"
									@click="!agencyDetailRequestChange ? savePersonCompany() : (sendRequest = true)"
									:loading="sendingEmail"
								>
									Save
								</v-btn>
							</v-col>
							<v-col cols="6">
								<v-btn color="#2d2926" block height="50px" outlined elevation="0" @click="$router.go(-1)">Cancel</v-btn>
							</v-col>
						</v-row>
						<!-- action end -->
					</v-form>
				</v-card>
			</v-col>
		</v-row>

		<!--Dialog to confirm send request-->
		<v-dialog v-model="sendRequest" width="505px">
			<v-card class="white" height="100%" :style="{ borderRadius: '8px'}">
				<v-card-title class="pa-0" height="66px" :style="{ backgroundColor: '#440099' }">
					<div :style="{ margin: '18px 17px 18px 24.5px' }">
						<v-img width="30px" height="30px" :src="$bucket + '/image/warning.svg' " contain style="color: white;"></v-img>
					</div>
					<div>
						<span class="notification-title-text-style" style="color: white;">Warning</span>
					</div>
				</v-card-title>
				<v-card-text class="pa-0" height="190.71px">
					<div class="notification-content-warning-style">
						<div v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
							<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
							<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
							<div :key="item" v-for="(item, index) in $store.state.notification.content.noticeList">
								<div v-if="index == 0 && item.includes('Session')" class="notification-content-text-style">{{ item }}</div>
								<div v-else class="regular-text-style" style="color: #2D2926 !important; padding-top: 20px;">{{ item }}</div>
							</div>
						</div>
						<div v-else>
							<div class="notification-content-text-style">
								Your account will be deactivated when request is in progress. Are you sure you want to send request company profile change?
							</div>
						</div>
					</div>
				</v-card-text>
				<v-card-actions class="px-0 pt-0 justify-center" >
					<v-col cols="6">
						<v-btn @click="(sendRequest = false), requestChange(), (sendingEmail = true)" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" block bottom dark>
							<span class="regular-text-style">Got it</span>
						</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn @click="sendRequest = false" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" block bottom outlined>
							<span class="regular-text-style" style="color: black !important;">Cancel</span>
						</v-btn>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
import subHeaderMobile from "./subHeaderMobile.vue";
import lodash from "lodash";

export default {
	components: {
		subHeaderMobile,
	},
	data() {
		return {
			entity: { map: { agency: { freeAgent: false, code: "" }, agencyGroup: {} }, personalAddress: false },
			agencyGroupList: [],
			agencyNameList: [],
			agencyCodeMatch: false,
			agencyNameSearching: false,
			doSearchAgencyName: null,
			attachment: null,
			valid: true,
			sendRequest: false,
			requestAgency: { map: { agency: {}, agencyGroup: {}, personalAddress: {} } },
			agencyCode: null,
			agencyCodeValid: false,
			agencyCodeFetching: false,
			agencyCodeHint: "",
			agencyCodeBannedList: [],
			sendingEmail: false,
			currentAgencyGroup: "",
			currentPCC: "",
			currentAgencyName: "",
			currentAgencyGroupName: "",
			freeAgentList: [],
			newAgency: false,
			agencyGroupSelectionMode: null,
			agencyDetailRequestChange: false
		};
	},
	mounted: function () {
		this.profile();
		this.listAgencyGroup();
		this.getFreeAgentAgency();
	},
	created: function () {
		this.debouncedSearchAgencyName = lodash.debounce(this.searchAgencyName, 500);
		this.debouncedFindAgency = lodash.debounce(this.findAgency, 500);
	},
	methods: {
		uppercase() {
			this.entity.map.agency.code = this.entity.map.agency.code.toUpperCase();
		},

		profile() {
			axios
				.get("/system/person/me")
				.then((response) => {
					this.entity = response.data;
					this.currentPCC = this.entity.map.agency.code;
					this.currentAgencyGroup = this.entity.map.agency.agencyGroupId;
					this.currentAgencyName = this.entity.map.agency.name;
					this.listAgencyByGroup();
				})
				.catch(() => {});
		},

		listAgencyGroup() {
			axios
				.get("/system/common/agency/group/list")
				.then((response) => {
					this.agencyGroupList = response.data;
				})
				.catch(() => {});
		},

		listAgencyByGroup() {
			this.agencyNameListFetching = true;
			axios
				.get("/system/common/agency/list?agencyGroupId=" + this.entity.map.agency.agencyGroupId)
				.then((response) => {
					this.agencyGroupSelectionMode = response.data.agencyGroup.selectionMode;
					this.agencyNameList = response.data.list;
					this.entity.map.agency.name = null;
					if (this.agencyGroupSelectionMode == "Locked") {
						// back-end ensures that at least there is 1 element
						this.entity.map.agency.name = this.agencyNameList[0];
					}
				})
				.catch(() => {})
				.finally(() => {
					this.agencyNameListFetching = false;
				});
		},

		searchAgencyName(v) {
			if (!v) {
				return;
			}
			if (v.length < 2) {
				return;
			}
			this.agencyNameSearching = true;
			axios
				.post("/system/common/agency/search", { name: v })
				.then((response) => {
					this.agencyNameList = response.data;
				})
				.catch(() => {})
				.finally(() => {
					this.agencyNameSearching = false;
				});
		},

		requestChange() {
			this.sendingEmail = true;
			this.requestAgency.personId = this.entity.id;
			this.requestAgency.agencyId = this.entity.agencyId;
			this.requestAgency.map.agency = this.entity.map.agency;
			this.requestAgency.map.mobileAgent = this.entity.mobileAgent;
			this.requestAgency.map.agencyGroup.id = this.entity.map.agency.agencyGroupId;
			this.requestAgency.map.jobTitle = this.entity.map.jobTitle;
			if (!this.agencyCodeMatch) {
				this.requestAgency.map.agency.id = null;
			}
			this.$root.saveActivityLog({type: 'Update_profile', value: "Company details"});
			axios
				.post("/system/agency/send/request", {
					entity: this.requestAgency,
				})
				.then(() => {
					this.sendingEmail = false;
					location.href = "#/login";
				})
				.catch(() => {});
		},

		savePersonCompany() {
			this.$root.saveActivityLog({type: 'Update_profile', value: "Company details"});
			axios
				.post("/system/person/edit", { entity: this.entity })
				.then(() => {
					this.sendingEmail = false;
					location.href = "#/profile";
				})
				.catch(() => {});
		},

		ruleAgencyCode(v) {
			this.agencyCodeValid = false;
			this.bannedAgencyCode = false;
			this.debouncedFindAgency(v);
			if (!!v) {
				if ((v.length == 3 || v.length == 4 || v.length == 6 || v.length == 9) && v.match(/^[a-z0-9]+$/i)) {
					this.agencyCodeValid = true;
					return true;
				} else {
					return "A valid PCC must be a combination of 3,4,6 or 9 numbers and characters";
				}
			} else {
				return "Enter a valid PCC";
			}
		},

		findAgency(v) {
			this.agencyCodeMatch = false;
			this.agencyCodeHint = "";
			// form is being validated, do not call back-end
			if (this.registering) {
				this.registering = false;
				return;
			}
			if (!v) {
				return;
			}
			if ((v.length == 3 || v.length == 4 || v.length == 6 || v.length == 9) && v.match(/^[a-z0-9]+$/i)) {
				//valid code
			} else {
				return;
			}
			this.agencyCodeFetching = true;
			axios
				.post("/system/common/agency/find", {
					code: v,
				})
				.then((response) => {
					// have we ever fetched ever?
					this.agencyCodeFetched = true;
					let data = response.data;

					if (data === "Invalid") {
					} else if (data === "Banned") {
					} else if (data && data.id) {
						this.entity.map.agency.agencyGroupId = data.agencyGroupId;

						this.currentAgencyGroupName = this.agencyGroupList.find(x => x.id === this.entity.map.agency.agencyGroupId).name;

						this.entity.map.agency.targetId = data.id;
						this.entity.map.agency.name = data.name;

						this.entity.map.agency.unit = data.unit;
						this.entity.map.agency.street = data.street;
						this.entity.map.agency.suburb = data.suburb;
						this.entity.map.agency.city = data.city;
						this.entity.map.agency.state = data.state;
						this.entity.map.agency.postCode = data.postCode;
						this.isPostalAddress = false;

						this.agencyCodeMatch = true;
						this.agencyCodeHint =
							"We have successfully matched your PCC in our system and updated your agency details below. Please check the details are correct. All fields highlighted grey cannot be amended, please contact duosupport@airnz.co.nz if they are incorrect.";
					} else {
						this.newAgency = true;
						this.entity.map.agency.agencyGroupId = "";
						this.entity.map.agency.name = "";

						this.entity.map.agency.unit = "";
						this.entity.map.agency.street = "";
						this.entity.map.agency.suburb = "";
						this.entity.map.agency.city = "";
						this.entity.map.agency.state = "";
						this.entity.map.agency.postCode = "";
					}
				})
				.catch(() => {})
				.finally(() => {
					this.agencyCodeFetching = false;
				});
		},

		getFreeAgentAgency() {
			axios
				.get("/system/common/agency/list/free/agent")
				.then((response) => {
					this.freeAgentList = response.data;
				})
				.catch(() => {});
		},

		checkFreeAgent() {
			this.freeAgentList.forEach((element) => {
				if (element.agencyGroupId == this.entity.map.agency.agencyGroupId && element.name == this.entity.map.agency.name) {
					this.entity.mobileAgent = true;
				} else if (element.agencyGroupId == this.entity.map.agency.agencyGroupId && element.name == "freeText") {
					this.entity.mobileAgent = true;
				}
			});
		},

		ruleAgencyGroupId(v) {
			this.checkFreeAgent();

			if (!!v) {
				return true;
			} else {
				return "Enter a valid PCC";
			}
		},

		ruleAgencyName(v) {
			this.checkFreeAgent();
			if (!!v) {
				return true;
			} else {
				return "Enter a valid PCC";
			}
		},

		agencyDetailChangeChecked() {
			if (this.agencyDetailRequestChange) {
				this.entity.map.agency.code = "";
				this.entity.map.agency.agencyGroupId = "";
				this.entity.map.agency.name = "";
			} else {
				this.entity.map.agency.code = this.currentPCC;
				this.entity.map.agency.agencyGroupId = this.currentAgencyGroup;
				this.entity.map.agency.name = this.currentAgencyName;

				this.newAgency = false;
			}
		}
	},
	watch: {
		doSearchAgencyName(v) {
			this.debouncedSearchAgencyName(v);
		},
	},
};
</script>

<style scoped>
.v-input.duo-description .v-label {
	font-size: 17px !important;
	color: #2d2926;
}
.v-application--is-ltr .v-input.duo-description .v-label {
	font-size: 17px !important;
	color: #2d2926;
}
.v-input.terms .v-label {
	font-size: 14px !important;
	color: #2d2926;
}
.duo-description > .v-input__control > .v-input__slot {
	margin-bottom: 0px !important;
}
div.notification-content-warning-style {
	margin: 20px 21.45px 10.00px 22.45px;
	text-align: center;
	height: 100%;
}
</style>
