<template>
	<v-main>
		<v-container fluid :style="{ backgroundSize: 'cover', backgroundAttachment: 'fixed', minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundImage: 'url(' + $bucket + '/image/background/Login.BG.jpg' + ')' }">
			<v-col class="d-flex flex-column">
				<v-spacer></v-spacer>

				<v-row no-gutters justify="center" style="margin-bottom: 18.44px; margin-top: 62px; min-height: 50px; max-height: 50px">
					<img :src="$bucket + '/image/logo-airnz.svg'" style="width: 300px; align-self: center" />
				</v-row>

				<v-row no-gutters justify="center" style="max-height: 506px !important">
					<v-card rounded="lg" min-width="300px" width="476px">
						<!-- card head -->
						<v-row
							no-gutters
							justify="center"
							align="center"
							:style="{ backgroundColor: '#2D2926', backgroundImage: 'url(' + $bucket + '/image/logo-duo.svg' + ')', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundSize: '216px 30px', height: '67px' }"
						></v-row>
						<!-- card head end -->

						<v-form ref="form" autocomplete="off" style="padding: 26px 40px 0 40px">
							<!-- login to duo -->
							<v-row no-gutters align="start">
								<h2 class="duo pt-0" style="font-size: 28px !important">
									Login to
									<b>duo</b>
								</h2>
							</v-row>
							<!-- login to duo end -->

							<!-- email -->
							<v-text-field
								id="email"
								v-model="email"
								type="email"
								label="Email"
								validate-on-blur
								:rules="[(v) => !!v || 'Please enter your registered email address', (v) => (v && v.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/) && true) || 'Please enter a valid email address']"
								v-on:keyup.enter="$refs.refPassword.focus()"
								style="font-size: 17px !important; margin: 0; padding-top: 6px"
								:class="{ 'line-blue': email != '' && email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) }"
							>
								<template v-slot:label>
									<span class="duo">Email</span>
								</template>
							</v-text-field>
							<!-- email end -->

							<!-- password -->
							<v-text-field
								id="password"
								v-model="password"
								label="Password"
								:rules="[(v) => !!v || 'Please enter your password']"
								:append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (passwordType = !passwordType)"
								:type="passwordType ? 'password' : 'text'"
								v-on:keyup.enter="login"
								ref="refPassword"
								style="font-size: 17px !important; margin-top: 7px"
								:class="{ 'line-blue': password != null && password != '' }"
								:hide-details="error.show"
							>
								<template v-slot:label>
									<span class="duo">Password</span>
								</template>
							</v-text-field>
							<!-- password end -->

							<div v-if="error.show" class="v-text-field__details mb-0">
								<div class="v-messages theme--light error--text" role="alert">
									<div class="v-messages__wrapper">
										<div class="v-messages__message pt-2 pb-1" style="font-size: 12px">{{ error.content }}</div>
									</div>
								</div>
							</div>

							<!-- remember me -->
							<v-checkbox id="rememberMe" v-model="rememberMe" class="mt-0 pt-0" :hide-details="true">
								<template v-slot:label>
									<span style="font-size: 14px; color: #2d2926 !important">Remember me</span>
								</template>
							</v-checkbox>
							<!-- remember me end -->

							<!-- form actions -->
							<v-col cols="12" class="d-flex flex-column pa-0 pt-2" style="margin-top: 8px" align-self="center">
								<v-row no-gutters class="mb-4" justify="center">
									<v-btn
										id="loginBtn"
										class="btn-login elevation-0"
										@click="
											error.show = false;
											login();
										"
									>
										Login
									</v-btn>
								</v-row>
								<v-row no-gutters class="mb-4" justify="center">
									<router-link id="forgotPasswordBtn" class="forgot-password" :to="'/password/forgot'">Forgot password?</router-link>
								</v-row>
								<v-btn id="registerBtn" color="accent" height="63px" outlined block @click="$router.push('/registration')" style="margin-bottom: 20px; font-size: 18px; color: #2d2926 !important">
									<span class="text-wrap" :style="{ width: $vuetify.breakpoint.xs ? '200px' : auto }" >
										Don't have an account?&nbsp;
										<strong>Register now</strong>
									</span>
								</v-btn>
							</v-col>
							<!-- form actions end -->
						</v-form>
					</v-card>
				</v-row>

				<!-- star alliance logo -->
				<v-row no-gutters align="center" justify="center" style="max-height: 30px; min-height: 30px; margin-top: 13px">
					<img src="/icon/star-alliance.png" height="20px" />
				</v-row>
				<!-- star alliance logo end -->
				<v-spacer></v-spacer>
			</v-col>
		</v-container>

		<v-dialog v-model="dialogConfirmation" width="500">
			<div style="padding: 7px 7px 50px 7px; background-color: white">
				<div style="text-align: right">
					<v-btn icon color="secondary" @click="dialogConfirmation = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<div v-if="phase != 'disabled'" style="text-align: center; margin-bottom: 15px; font-size: 24px; color: rgba(0, 0, 0, 0.75)">
					Email
					<b style="color: black">confirmation</b>
				</div>

				<div style="font-size: 14px; padding: 0 15%">
					<template v-if="phase == 'pending'">
						You have not confirmed your email address yet. To complete your <b>duo</b> account set up click the link provided within the confirmation email and login.
						<br />To resend the confirmation email, please click the button below.
						<div style="text-align: center; margin-top: 30px">
							<v-btn large class="duo-primary" @click="resend">Resend</v-btn>
						</div>
					</template>
					<template v-else-if="phase == 'resent'">Confirmation email has been resent. To complete your <b>duo</b> account set up click the link provided within the email and login.</template>
					<template v-else-if="phase == 'disabled'">
						Your profile change request is being reviewed. Once approved, you will be able to log in. If you require support, please email <a href="mailto:duosupport@airnz.co.nz">duosupport@airnz.co.nz</a>
						<div style="text-align: center; margin-top: 30px">
							<v-btn @click="closeDialog" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" width="192.7px" bottom dark>Got it</v-btn>
						</div>
					</template>
				</div>
			</div>
		</v-dialog>
	</v-main>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";

export default {
	data() {
		return {
			email: "",
			password: "",
			rememberMe: false,
			passwordType: true,
			dialogConfirmation: false,
			phase: null,
			error: { show: false, content: "" }
		};
	},
	mounted: function () {
		this.doRememberMe();
	},
	methods: {
		doRememberMe() {
			axios
				.get("/system/authentication/remember/me")
				.then((response) => {
					if (response.data && response.data.length > 0) {
						this.email = response.data;
						this.rememberMe = true;
					}
				})
				.catch(() => {});
		},
		resend() {
			axios
				.post("/system/authentication/registration/resend", {
					email: this.email
				})
				.then(() => {
					this.phase = "resent";
					this.dialogConfirmation = true;
				})
				.catch(() => {});
		},
		closeDialog(){
			this.phase = "";
			this.dialogConfirmation = false;
		},
		login() {
			this.error.show = false;
			if (this.$refs.form.validate()) {
				axios
					.post("/system/authentication/login", {
						email: this.email,
						password: this.password,
						rememberMe: this.rememberMe
					})
					.then((response) => {
						if (response.data === "Pending" || response.data === "Disabled") {
							this.phase = response.data.toLowerCase();
							this.dialogConfirmation = true;
						} else {
							let principal = response.data;
							if (principal && principal.token) {
								this.$store.commit("login", principal);
							}
							this.$router.push({
								path: "dashboard"
							});
						}
					})
					.catch((error) => {
						this.error.show = true;
						this.error.content = error.response.data.errorList[0];
					});
			}
		}
	}
};
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	--webkit-box-shadow: 0 0 0 30px none inset !important;
	background-color: none !important;
}
input:-internal-autofill-selected {
	background-color: none !important;
}
.v-input input {
	font-size: 27px !important;
}
.btn-login {
	width: 192px !important;
	height: 50px !important;
	font-size: 17px !important;
	color: #ffffff !important;
	background-color: #00b0b9 !important;
	border-radius: 4px;
	opacity: 1;
	text-transform: none !important;
	letter-spacing: 0;
}
.forgot-password {
	color: #2d2926 !important;
	color: inherit;
	font-size: 15px !important;
}
</style>