<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
    <subHeader />
    <v-container
      no-gutters
      class="main-container"
      style="width: 100%; padding: 0 0 0 10px"
      fluid
    >
      <!-- LEFT TAB -->
      <div class="d-flex flex-column left-tab">
        <!-- SVG MAP -->
        <div>
          <div
            style="
              position: relative;
              z-index: 1;
              font-size: 17px;
              height: 0;
              max-width: 680px;
            "
          >
            <div
              style="font-family: 'AirNZ Sans Semibold'; padding-bottom: 22px"
            >
              <b>Discover North America</b>
            </div>
            <p style="line-height: 110%">
              Air New Zealand operates flights from Australia via Auckland to 
              seven destinations in North America and to over 100 other North America 
              destinations with our domestic partner airlines from our gateways. 
              Find out more about each city by clicking on the tiles.
            </p>
          </div>
          <div
            :style="{
              width: 800 * scale + 'px',
              height: 504 * scale + 'px',
              zIndex: 0,
            }"
          >
            <div
              :style="{
                transformOrigin: 'top left',
                transform: 'scale(' + scale + ')',
                height: '504px',
                width: '801px',
                backgroundSize: 'cover',
                backgroundRepeat: 'none',
                background:
                  'url(' + $bucket + '/image/discover-north-america/Map.svg',
              }"
            >
              <v-container
                class="ma-0 pa-0"
                style="max-width: 680px; padding-top: 10px"
              >
                <v-btn
                  v-for="state in stateList"
                  :key="state.airportId"
                  @click="handleStateOnClick(state)"
                  class="rounded-pill"
                  :elevation="
                    selectedState.airportId === state.airportId ? 1 : 0
                  "
                  :style="{
                    fontSize: '12px',
                    padding: '0 10px 0 10px',
                    backgroundColor:
                      selectedState.airportId === state.airportId
                        ? 'white'
                        : 'transparent',
                    left: state.posX,
                    top: state.posY,
                    height: '25px',
                  }"
                  ><div v-if="state.inverted == true" class="dot" />
                  <b>{{ state.name }}</b> ({{ state.airportId }})
                  <div v-if="state.inverted == false" class="dot"
                /></v-btn>
              </v-container>
            </div>
          </div>
          <p style="line-height: 110%; padding-top: 5%;">
            <strong>Note:</strong> Non-stop services between Auckland & Chicago will pause from 31 Mar- 25 Oct 24
          </p>
        </div>
        <!-- END SVG MAP -->
      </div>
      <!-- END LEFT TAB -->

      <v-divider
        vertical
        class="center-tab"
        style="
          border-width: 1px;
          background-color: #303030;
          max-height: 510px;
          margin-top: 80px;
        "
      ></v-divider>

      <!-- RIGHT TAB -->
      <div
        ref="place"
        class="d-none d-md-block right-tab"
        style="min-width: 420px"
      >
        <h3 style="font-size: 18px; padding-bottom: 18px; padding-left: 10px">
          {{ selectedState.name }}
        </h3>
        <v-card
          class="mb-4"
          v-for="(item, j) in selectedState.placeList"
          :key="j"
          min-width="420px"
          elevation="1"
          style="border-radius: 0"
        >
          <v-row style="width: 420px" no-gutters>
            <v-img
              :src="$bucket + '/image/discover-north-america/' + item.image"
              height="180px"
              position="center center"
            >
              <v-card-title
                class="fill-height align-end"
                style="word-break: break-word"
              >
                <v-container
                  fluid
                  class="py-0 pb-1 white--text"
                  style="padding-right: 0"
                >
                  <v-row
                    no-gutters
                    style="max-height: 64px; font-size: 28px; letter-spacing: 0"
                  >
                    <strong>{{ item.name }}&nbsp;</strong>
                  </v-row>
                  <v-row no-gutters>
                    <span class="horizontal-line"></span>
                  </v-row>
                </v-container>
              </v-card-title>
            </v-img>
            <v-card-text class="place-text" style="padding: 17px 24px 8px 24px">
              <p style="font-size: 17px; line-height: 120%">
                {{ item.shortDescription }}
              </p>
            </v-card-text>
            <v-card-actions
              style="padding: 0 24px 29px 24px"
              class="place-action"
            >
              <v-btn
                style="width: 193px; height: 50px; font-size: 16px"
                color="#2d2926"
                class="get-in-touch"
                :elevation="0"
                bottom
                dark
                @click="handleDialogOnClick(item)"
                >Find out more</v-btn
              >
            </v-card-actions>
          </v-row>
        </v-card>
      </div>
    </v-container>
    <!-- END RIGHT TAB -->

    <!-- DIALOG -->
    <v-dialog v-model="dialog" max-width="920px">
      <v-card
        v-if="place != null"
        min-height="420px"
        max-height="500px"
        style="overflow: auto"
      >
        <div
          style="color: white; background-color: #2d2926"
          :style="{ maxHeight: '66px' }"
        >
          <div
            class="place-dialog-name"
            style="padding-left: 38px; padding-top: 16px; padding-bottom: 16px"
          >
            <strong style="font-size: 28px">{{ place.name }}</strong>
          </div>
          <v-spacer />
          <div>
            <v-btn
              absolute
              top
              right
              icon
              style="padding-top: 16px; padding-bottom: 16px"
              @click="dialog = false"
            >
              <v-icon large color="white">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row no-gutters>
          <v-col
            :style="{
              maxWidth: '420px',
              height: '400px',
              backgroundImage:
                'url(' +
                $bucket +
                '/image/discover-north-america/' +
                place.image +
                ')',
              backgroundSize: 'cover',
              position: 'relative',
              backgroundPosition: 'center center',
            }"
          ></v-col>
          <v-col>
            <v-card-text class="ma-0 pa-7" style="height: 217px">
              <div
                style="
                  font-size: 17px !important;
                  line-height: 120%;
                  padding: 0 2px 0 12px;
                "
                v-html="place.description"
              ></div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- END DIALOG -->
  </v-container>

  <discover-north-america-mobile v-else></discover-north-america-mobile>

</template>
<style scoped>
.horizontal-line {
  width: 50px;
  padding-bottom: 12px;
  border-bottom: 4px solid rgb(255, 255, 255);
}

.dot {
  width: 11px;
  height: 11px;
  /* UI Properties */
  background: var(--unnamed-color-440099) 0% 0% no-repeat padding-box;
  background: #440099 0% 0% no-repeat padding-box;
  border-radius: 50%;
  opacity: 1;
  margin-left: 3px;
}

@media (max-width: 1599px) {
  .main-container {
    display: inline-flex;
    justify-content: center;
  }

  .center-tab {
    margin-right: 22px;
  }

  .right-tab {
    padding-right: 20px;
  }
}

@media (min-width: 1600px) {
  .main-container {
    display: inline-flex;
  }

  .left-tab {
    margin-left: 2vw;
  }

  .center-tab {
    margin-left: 1vw;
    margin-right: 3vw;
  }

  .right-tab {
    /* padding-right: 2vw; */
  }
}
</style>


<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
// import axios from "axios";
import subHeader from "./subHeader.vue";
import discoverNorthAmericaMobile from "./mobile/discoverNorthAmericaMobile.vue"

export default {
  components: {
    subHeader,
    discoverNorthAmericaMobile
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
    window.scrollTo(0, 0);
    this.selectedState = this.stateList[2];
  },
  data() {
    return {
      scale: 1,
      dialog: false,
      selectedState: {
        airportId: null,
        placeList: [],
      },
      place: null,
      stateList: [
        {
          airportId: "YVR",
          name: "Vancouver",
          posX: "130px",
          posY: "213px",
          inverted: false,
          placeList: [
            {
              name: "Vancouver",
              shortDescription:
                "Sophisticated, sporty and spectacular in any season, Vancouver is a city, sea and mountains experience that makes every day of your holiday amazing.",
              description:
                "<p>A modern, glittering city that seems built into, and built up from, some of the most awe-inspiring natural landscapes on the planet. It is a gathering place for people of many backgrounds where the ocean, forest and mountains converge.</p><p>Every corner is touched by immaculate beauty, each meal elevated by a world-class culinary scene and everyone you meet eager to share their passion for a life made extraordinary just by being here.</p><p><b>Flight time from Auckland to Vancouver:</b> 13h 10m<br/><b>Flight time from Vancouver to Auckland:</b> 14h</p>",
              image: "YVR-City.jpg",
            },
            {
              name: "Vancouver International Airport (YVR)",
              shortDescription:
                "Air New Zealand operates from Terminal D at Vancouver International Airport (YVR)",
              description:
                "<p>Flying up to seven services per week, operated by the Dreamliner 787-9 or 777-300 aircraft, Air New Zealand operates from Terminal D at Vancouver International Airport.</p><p>Located only 12km from downtown Vancouver, transport is available via taxi, ride-share, car-hire or public transport. The Canada Line is Vancouver’s rapid transit rail connecting YVR to downtown Vancouver in under 30 minutes and to downtown and is accessible from both the International and Domestic Terminals.</p>",
              image: "YVR-Airport.jpg",
            },
            {
              name: "Capilano Suspension Bridge Park",
              shortDescription:
                "The park offers more than just the bridge. Its surrounding 27 acres celebrate nature, history and culture in unique and thrilling ways.",
              description:
                "<p>Only 15mins from downtown Vancouver, this famous crossing spans 137 metres, towering 70m high above a rocky canyon floor. Anyone who’s been there (including Mick Jagger and Marilyn Monroe) knows what a genuine thrill it is to make the long, rolling lurch across the Capilano River.</p><p>The newest attraction, Cliffwalk, hangs for 213m and reaches 91m high, hugging the granite cliffside and offering a panoramic view of the thriving rainforest and canyon far below.</p>",
              image: "YVR-Capilano.jpg",
            },
            {
              name: "Whistler Blackcomb",
              shortDescription:
                "Whistler Blackcomb is considered one of North America’s premier mountain resorts-for good reason.",
              description:
                "<p>A short, scenic 125-kilometre (78-mile) drive from Vancouver, this resort offers exceptional hospitality, a diverse cultural scene, and dramatic scenery.</p><p>Two mountains, connected by the PEAK 2 PEAK Gondola, offer more than 200 marked runs, 16 alpine bowls, and three glaciers. Epic skiing, combined with a glamorous après scene and endless off-mountain activities, make this an unforgettable ski vacation.</p>",
              image: "YVR-Blackcomb.jpg",
            },
            {
              name: "Ocean Adventures",
              shortDescription:
                "Being right on the water not only adds to Vancouver’s beauty, it also provides a multitude of experiences.",
              description:
                "<p>Explore the harbour on a paddlewheel or zodiac. Cast a line and try to catch your own dinner with the help of a fishing charter, or experience the thrill of seeing orcas, humpback whales and all kinds of marine life on a whale watching tour.</p><p>From March to October each year, thousands of whales migrate through the waters near Vancouver,  making it one of the world’s best locations for whale watching. You can spot pods of whales from a high-speed  zodiac, fully-equipped cruiser, kayak or seaplane.</p>",
              image: "YVR-Ocean_adventures.jpg",
            },
          ],
        },
        {
          airportId: "ORD",
          name: "Chicago",
          posX: "272px",
          posY: "298px",
          inverted: false,
          placeList: [
            {
              name: "Chicago",
              shortDescription:
                "Outstanding architecture, art, food and sport - these are the things that make Chicago magnificent.",
              description:
                "<p>Illinois is probably best known for Chicago, a world-class city regarded by many as the cultural heart of the USA. Renowned museums, stunning architecture, Michelin-starred restaurants – Chicago has it all. But what surprises visitors the most is the 40-plus kilometres of ocean-like beachfront just steps from downtown and shopping. Get your kicks along the USA’s most historic highway, Route 66.</p><p><b>Flight time from Auckland to Chicago:</b> 14h 55m<br/><b>Flight time from Chicago to Auckland:</b> 16h 30m</p>",
              image: "ORD-City.jpg",
            },
            {
              name: "Chicago O'Hare International Airport (ORD)",
              shortDescription:
                "Air New Zealand will operate from Terminal 5 at Chicago O'Hare International Airport (ORD).",
              description:
                "<p>Flying three return services per week from October 2022, operated by the Dreamliner 787-9 aircraft, Air New Zealand will operate from Terminal 5 at Chicago O'Hare International Airport (ORD).</p><p>Located on the far northwest side of Chicago, Illinois, Chicago O’Hare International Airport (ORD) is approx. 30km (17 miles) from the downtown Loop. Getting to and from O'Hare is simple, the 24-hour Blue Line train links the city centre to O'Hare Station (Terminal 2) in 45 minutes, and the domestic terminals (1, 2 and 3) are connected to the international terminal 5 by the free-to-use, 24-hour ATS train.</p><p>For more information on transferring to a Domestic or International flight at ORD, visit <a style='font-size: 17px' href='https://www.flychicago.com/ohare/myflight/connecting/pages/default.aspx' target='_blank'>here</a></p>",
              image: "ORD-Airport.jpg",
            },
            {
              name: "Millennium Park",
              shortDescription:
                "Millennium Park is the heart of Chicago, with a near-endless list of things to see and do, there’s something for everyone.",
              description:
                "<p>Known as the \"front lawn\" of downtown, Chicago's standout urban park packs many features and attractions into its 10 hectares, including nature and gardens, public art and architecture, an ice rink, free concerts, and more.</p></p>It’s hard not to fall in love with Cloud Gate, aka “The Bean”. Forming a glistening archway into Millennium Park, visitors stroll under the monumental structure and gaze inquisitively upon their distorted reflections. Don't leave without a selfie!</p>",
              image: "ORD-Millenium_Park.jpg",
            },
            {
              name: "Architecture Cruise",
              shortDescription:
                "Chicago is known around the world for its architecture, and it can be explored by boat, bus, train or on foot.",
              description:
                "<p>One of the best ways to wrap your eyes around the city's historic skyline (and downtown) is to take a cruise along the Chicago River, which puts its iconic buildings front and centre. Enjoy a narrated architecture tour from the open-air boat deck as knowledgeable guides reveal the colourful history and design behind the city's most emblematic structures.</p>",
              image: "ORD-Architechture.jpg",
            },
            {
              name: "Route 66",
              shortDescription:
                "Route 66 is the ultimate all American road trip, crossing vast amounts of dessert, dotted with small towns full of history and character.",
              description:
                "<p>Kilometre for kilometre you can discover more of Route 66 - the USA's iconic Mother Road - in Illinois than anywhere else. Start in Chicago, where the road begins, and chart a course that takes you past kitschy roadside exhibits, diners, drive-ins, statues, landmarks and monuments.</p>",
              image: "ORD-Route_66.jpg",
            },
          ],
        },
        {
          airportId: "SFO",
          name: "San Francisco",
          posX: "-155px",
          posY: "340px",
          inverted: false,
          placeList: [
            {
              name: "San Francisco",
              shortDescription:
                "For a city that’s only seven miles by seven miles, surrounded by iconic landmarks, San Francisco has so many amazing neighbourhoods ready to be explored.",
              description:
                "<p>Home to iconic landmarks like the Golden Gate Bridge and Alcatraz, California's fourth largest city has a fairly compact centre thanks to it's location on the tip of a peninsula.  Characterised by steep hills, cable cars, an amazing mix of architectural styles, and a tolerance for anything a little different and unique.</p><p>Visit the oldest Chinatown in America, stroll along Fisherman's Wharf and try the fresh cooked Dungeness Crab, hit the shops, take a boat trip or simply relax in Golden Gate Park.</p><p><b>Flight time from Auckland to San Francisco:</b> 12h 15m<br/><b>Flight time from San Francisco to Auckland:</b> 13h 05m</p>",
              image: "SFO-City.jpg",
            },
            {
              name: "San Francisco International Airport (SFO)",
              shortDescription:
                "Air New Zealand will operate from the International Terminal at San Francisco International Airport (SFO).",
              description:
                "<p>Flying three return services per week on the Dreamliner 787-9 or Boeing 777-300 aircraft, Air New Zealand will operate from the International Terminal at San Francisco International Airport (SFO).</p><p>The second busiest airport in California, SFO is a major hub for flights across the United States. The airport is located 23km (13 miles) south of downtown San Francisco and is connected to the city via BART (Bay Area Rapid Transit), a direct rail  link, and there are a number of public bus routes serving the airport terminals. Many hotels offer courtesy shuttles, and taxis, ride share and rental cars are available too.</p><p>For more information on connecting with another airline at SFO, where customers may need to change terminals, visit <a style='font-size: 17px' href='https://www.airnewzealand.com.au/connecting-at-san-francisco' target='_blank'>here</a>.</p>",
              image: "SFO-Airport.jpg",
            },
            {
              name: "Fisherman's Wharf and Alcatraz Island",
              shortDescription:
                "Explore Fisherman's Wharf, where the crisp, sea salt air and fabulous views are just the beginning of a memorable experience.",
              description:
                "<p>At Fisherman's Wharf, dine on the freshest seafood, take a bay cruise, visit a museum, do some shopping, or watch the amusing sea lions on Pier 39. With picture perfect views of the Golden Gate and Bay Bridges, Alcatraz and the city skyline at almost every turn!</p><p>Discover history with a cruise to Alcatraz Island, once a fort, a military prison, and a maximum security federal penitentiary and now a popular tourist attraction. You'll sense the frustration of being locked up in a fortress with the lights of the city twinkling across the waters.</p>",
              image: "SFO-Fishermans_Wharf.jpg",
            },
            {
              name: "Golden Gate Bridge",
              shortDescription:
                "No trip to San Francisco is complete without a drive, walk or cycle across the  world-renowned Golden Gate Bridge.",
              description:
                "<p>Stretching 4,200 feet and towering as high as a 65-story building,, snap your own photo of San Francisco's most iconic attraction from vista points in Golden Gate National Recreation Area, then drive, walk or bicycle across the world-famous span.</p><p>If visiting on Sundays between March & September, stop by the Presidio where you'll find Food trucks and vendors from all over the city lined up serving everything from lobster rolls to shawarma to ice cream sandwiches.</p><p>The views are amazing, especially at sunset.</p>",
              image: "SFO-Golden_Gate.jpg",
            },
            {
              name: "Chinatown",
              shortDescription:
                "San Francisco Chinatown is the largest Chinatown outside of Asia as well as the oldest Chinatown in North America.",
              description:
                "<p>San Francisco's Chinatown is the oldest one in North America and covers 24 city blocks, and is one the most densely populated neighbourhood west of Manhattan. Jump on an iconic cable car for a trip to explore the highlights of Chinatown. From the picturesque Chinatown Gate, wander the length of shops on Grant Avenue, or venture into the many interesting side streets and little alleys where the more authentic life of the community goes on.</p><p>While in Chinatown, tour the Golden Gate Fortune Cookie Company (the treats were invented here, after all!).</p>",
              image: "SFO-Chinatown.jpg",
            },
          ],
        },
        {
          airportId: "JFK",
          name: "New York",
          posX: "178px",
          posY: "335px",
          inverted: true,
          placeList: [
            {
              name: "New York",
              shortDescription:
                "Watch Broadway shows, visit world-renowned art museums and enjoy breath-taking views of the Manhattan skyline.",
              description:
                "<p>With a globally recognizable skyline and 8.4 million people who call it home, New York City is bursting with diversity and excitement – from the iconic attractions you've seen on TV and in the movies to hidden gems waiting to be uncovered. Each of the five boroughs – The Bronx, Brooklyn, Manhattan, Queens and Staten Island – has a distinctive flavour, while individual neighbourhoods maintain identities all their own. Walkable streets and access to the subway, buses and cabs make it easy to get from neighbourhood to neighbourhood.</p><p><b>Flight time from Auckland to New York:</b> 15h 55m<br/><b>Flight time from New York to Auckland:</b> 17h 50m</p>",
              image: "NYC-City.jpg",
            },
            {
              name: "John F. Kennedy International Airport (JFK)",
              shortDescription:
                "Air New Zealand will operate from Terminal 1 at John F. Kennedy International Airport (JFK).",
              description:
                "<p>Flying three return services per week from September 2022, operated by the Dreamliner 787-9 aircraft, Air New Zealand will operate from Terminal 1 at John F. Kennedy International Airport (JFK). This ultra-long-haul service will be Air New Zealand’s longest route, and one of the longest in the world.</p><p>JFK is located in the borough of Queens, 23km (15 miles) from downtown Manhattan, and is among the busiest airports in the USA. Transport to and from JFK is available via taxi, car service, airport shuttles or the AirTrain.</p>",
              image: "NYC-Airport.jpg",
            },
            {
              name: "Eat like a New Yorker",
              shortDescription:
                "Almost every kind of food can be found in NYC's food scene, eat your way through this vibrant city!",
              description:
                "<p>NY is home to more than 1000 different cultures, so every mealtime is an opportunity to explore the world.</p><p>Discover the neighbourhoods of NYC and their ethnic specialties. The Bronx is the place for Mexican, Puerto Rican and Korean; Belmont is 'Little Italy' with a touch of Albanian; Norwood is for Filipino cuisine; Astoria can help you with Eastern European and Arabic food; Crown Heights can introduce you to Jamaican and West Indian food; Borough Park is the place for kosher eateries; Yonkers is a top spot for Cuban food.</p>",
              image: "NYC-Food.jpg",
            },
            {
              name: "Central Park",
              shortDescription:
                "Arguably one of the most famous parks in the world, Central Park is a manmade wonder, ready to be explored.",
              description:
                "<p>Central Park – the city’s urban park centrepiece – is a 341-hectare oasis of meadows, arches and sculptures, as well as running paths and playgrounds. Other green spaces dot the city, including Brooklyn’s Prospect Park, the Bronx’s Pelham Bay Park, Staten Island's Greenbelt and Flushing Meadows Corona Park, Queens’ largest, with a huge recreation centre, plenty of room for sports and a botanical garden.</p>",
              image: "NYC-Central_Park.jpg",
            },
            {
              name: "New York Icons",
              shortDescription:
                "From the Statue of Liberty to the bright lights of Broadway, New York is one of the world's most exciting cities.",
              description:
                "<p>You’ve seen them a million times in the movies, but there’s nothing like taking in these quintessential New York City landmarks in person.</p><p>Immerse yourself in the bustle and bright lights of Times Square followed by the incomparable spectacle of a Broadway show – both experiences that you can’t find anywhere else but NYC. Explore Rockefeller Centre, and take a trip to the top of the Empire State Building. Experience Grand Central Terminal, a unique architectural gem and the city’s major transportation hub.</p>",
              image: "NYC-NY_Icons.jpg",
            },
          ],
        },
        {
          airportId: "LAX",
          name: "Los Angeles",
          posX: "-399px",
          posY: "377px",
          inverted: false,
          placeList: [
            {
              name: "Los Angeles",
              shortDescription:
                "Welcome to California's liveliest city, the entertainment capital of America, and one of the best restaurant scenes in North America.",
              description:
                "<p>See a movie star. Sip a cocktail on a rooftop bar at sunset. Slip on designer shoes in a Beverly Hills boutique. Play at Universal Studios Hollywood theme park, explore world-class museums, or see your favourite band in West Hollywood.</p><p><b>Flight time from Auckland to Los Angeles:</b> 12h 05m<br/><b>Flight time from Los Angeles to Auckland:</b> 12h 55m</p>",
              image: "LAX-City.jpg",
            },
            {
              name: "Los Angeles International Airport (LAX)",
              shortDescription:
                "Air New Zealand will operate from the Tom Bradley International Terminal (TBIT or Terminal B) at Los Angeles International Airport (LAX).",
              description:
                "<p>Flying daily services on the Dreamliner 787-9 or Boeing 777-300 aircraft, Air New Zealand will operate from the Tom Bradley International Terminal (TBIT or Terminal B) at Los Angeles International Airport (LAX). Eligible guests can relax before their departing flight in the Air New Zealand developed and managed Star Alliance lounge, located in the TBIT central atrium.</p><p>LAX is the second busiest Airport in the United States, located 30km (17 miles) south-west of Downtown Los Angeles. There are many options for transport to/from LAX from right across Los Angeles - by bus, light rail, car, ride-sharing services, taxi, shuttle van, black car services or even helicopter.</p><p>For more information on connecting with another airline at LAX, where customers may need to change terminals, visit <a style='font-size: 17px' href='https://www.airnewzealand.com.au/connecting-at-los-angeles' target='_blank'>here</a>.</p>",
              image: "LAX-Airport.jpg",
            },
            {
              name: "Beach Towns",
              shortDescription:
                "Los Angeles is an ideal destination for experiencing the quintessential endless Californian summer.",
              description:
                "<p>Everyone in Los Angeles has a favourite beach, and each beach has its own vibe, from the famed Malibu to lively Manhattan Beach.</p><p>Santa Monica’s 5.6-kilometer stretch of sand is marked by the world-famous pier - cycle, walk or rollerblade your way along the boulevard from Santa Monica to neighbouring Venice Beach.</p> ",
              image: "LAX-Beach_Towns.jpg",
            },
            {
              name: "Hollywood",
              shortDescription:
                "Hollywood, also called Tinseltown, within the city of Los Angeles, whose name is synonymous with the American film industry.",
              description:
                "<p>Hollywood, the home of movie studios, many of LA's most popular and historic tourist destinations, and its world-famous namesake boulevard.  Explore the Walk of Fame and TCL Chinese Theatre, before strolling the section of Sunset Boulevard known as The Strip with its vibrant billboards, restaurants and famous nightclubs.</p><p>Explore the high-end celebrity hangouts and hotel bars, feel right at home with tucked-away cocktail bars and bespoke coffee shops. If you only do one thing in Hollywood, have a picnic at the Hollywood Bowl, a gorgeous outdoor amphitheatre nestled in an aesthetically blessed fold in the Hollywood Hills.</p>",
              image: "LAX-Hollywood.jfif",
            },
            {
              name: "Theme Parks",
              shortDescription:
                "The exciting rides, the behind the scenes tours, and the entertainment - there's thrills for everyone when visiting LA's theme parks!",
              description:
                "<p>From Disneyland Resort classics to behind-the-scenes tram tour experiences at Universal Studios Hollywood, Los Angele's best-in-class theme parks deliver something for everyone.</p><p>Disneyland consists of the original Disneyland Park and the adjacent Disney California Adventure Park, the sprawling property is divided up into themed “lands” with related rides, shows, and attractions.</p><p>Universal Studios Hollywood is your chance to step into that fantasy world. On a sprawling complex in the Hollywood Hills, this movie-studio-meets-theme-park blends a studio tour with thrilling action rides and walk-through attractions.</p>",
              image: "LAX-Theme_Parks.jpg",
            },
          ],
        },
        {
          airportId: "IAH",
          name: "Houston",
          posX: "347px",
          posY: "410px",
          inverted: false,
          placeList: [
            {
              name: "Houston",
              shortDescription:
                "Houston is considered to have one of the best culinary scenes in America, add to that the Southern charm and hospitality, capturing the imagination of travellers around the world.",
              description:
                '<p>As America\'s fourth largest city, Houston has always been a big deal. Experience Texan hospitality in the home of space travel. With more than 2.2 million residents, the city attracts visitors and transplants with a wonderful mix of world-class arts, booming businesses, professional sports and award-winning cuisine. So much of Houston daily life happens outdoors, thanks to mild, year-round temperatures.</p><p>Take time to explore the eclectic, culture-filled neighbourhoods, gallery spaces and attractions, which offer diverse flavours that can only be found in the city recently called by GQ, <i>"the new Capital of Southern Cool"</i>.</p><p><b>Flight time from Auckland to Houston:</b> 13h 35m<br/><b>Flight time from Houston to Auckland:</b> 15h</p>',
              image: "IAH-City.jpg",
            },
            {
              name: "Houston George Bush Intercontinental Airport (IAH)",
              shortDescription:
                "Air New Zealand will operate from Terminal D at Houston George Bush Intercontinental Airport (IAH).",
              description:
                "<p>Flying three return services per week from July 2022, operated by the Dreamliner 787-9 aircraft, Air New Zealand will operate from Terminal D at Houston George Bush Intercontinental Airport (IAH).</p><p>IAH is located approx. 37km (23 miles) north of Downtown Houston, and is the first airport in North American to offer flights to every inhabited continent. There are many options for transport to/from IAH, including  express metro bus, ride-sharing services, taxi, shuttles.</p><p>For more information on connecting with another airline at LAX, where customers may need to change terminals, visit <a style='font-size: 17px' href='https://www.airnewzealand.com.au/connecting-at-houston' target='_blank'>here</a>.</p>",
              image: "IAH-Airport.jpg",
            },
            {
              name: "Space Center Houston",
              shortDescription:
                "Visit Houston's #1 attraction, the Official Visitor Center of NASA Johnson Space Center. Explore. Experience. Discover.",
              description:
                "<p>Known around the world as the home of NASA Mission Control, International Space Station Mission Control and astronaut training, guests are taken behind the scenes to see NASA Johnson Space Center.</p><p>The out-of-this-world educational complex explores the space flight program through ever-changing exhibits, 400 artefacts (including the worlds largest collection of lunar rockets) and exceptional tours, such as Level 9, which takes you behind-the-scenes of NASA.</p>",
              image: "IAH-NASA.jpeg",
            },
            {
              name: "Cuisine",
              shortDescription:
                "A sprawling metropolis, Houston’s culinary scene is truly unparalleled.",
              description:
                "<p>Countless cutting-edge chefs have made a home in Houston, where diners eat out more than residents of any other city. Experience a world of flavours and epicurean influences – from Central American to Asian and Mediterranean. Houston boasts some 11,000 restaurants serving foods from 70 different cultures.</p>",
              image: "IAH-Cuisine.jpg",
            },
            {
              name: "Arts & Culture",
              shortDescription:
                "Houston is now home to seven cultural districts and has the largest number of cultural districts in one city. ",
              description:
                "<p>On any given night, it's a safe bet that there's a show somewhere in Houston's Theater District. The 17-block area is home to Houston's resident performance companies. Tucked between the bustling Midtown and Medical Center areas, rests Houston's leafy, park-like Museum District. Nineteen world-class institutions are clustered in this area, drawing a reported seven million visitors to the district each year.</p><p>And in nearly every neighbourhood in between, visitors will find public art at the turn of a corner! The City of Houston Art Collection includes almost 650 works that can be found in over 100 of the City of Houston's public facilities and parks. These artworks are enjoyed by more than 64 million people per year.</p>",
              image: "IAH-Art.jpg",
            },
          ],
        },
        {
          airportId: "HNL",
          name: "Honolulu",
          posX: "-80px",
          posY: "420px",
          inverted: false,
          placeList: [
            {
              name: "Honolulu",
              shortDescription:
                "Hawaii is where the culture of Polynesia meets the energy of the USA, where sun-kissed beaches meet world-class shopping.",
              description:
                "<p>Honolulu is a modern city with a unique blend of cultures that serves as state capital and a gateway to the Hawaiian Islands. You can tour the historic downtown area, surf world-famous Waikiki Beach, shop in world-class stores, hike breath-taking coastal cliffs and dine at five-star restaurants. Best of all, you can do all of this in the same day.</p><p><b>Flight time from Auckland to Honolulu:</b> 8h 35m<br/><b>Flight time from Honolulu to Auckland:</b> 9h</p>",
              image: "HNL-City.jpg",
            },
            {
              name: "Daniel K. Inouye International Airport (HNL)",
              shortDescription:
                "Air New Zealand operates from Lobby 6 at the Overseas Terminal at Daniel K. Inouye International Airport (HNL).",
              description:
                "<p>Flying up to seven services per week, operated by the Dreamliner 787-9 or 777-300 aircraft, Air New Zealand operates from Lobby 6 at the Overseas Terminal at Daniel K. Inouye International Airport (HNL).</p><p>The largest airport in the State of Hawaii and is located in Honolulu on the island of Oahu. The distance from Daniel K. Inouye International Airport to Waikiki is approx. 16kms (10 miles). Transport is available via taxi, ride-share, car-hire or bus.</p>",
              image: "HNL-Airport_Reef_Runway.jpg",
            },
            {
              name: "Pearl Harbor",
              shortDescription:
                "At the Pearl Harbor National Memorial, learn about one of the most pivotal moments in US history.",
              description:
                "<p>The Dec 7, 1941 attack of Pearl Harbor on Oahu triggered the USA's entry into World War II. The monument at the attack site honours the thousands who lost their lives during the raid.</p><p>Visitors can pay their respects at the memorials and learn about military history at two museums. The expanded and renovated Pearl Harbor Visitor Center offers excellent opportunities to learn about the Pacific War and the December 7, 1941, attack on Oah'u. The USS Arizona memorial hull is both a tomb for more than 900 sailors who remain within and also serves as an artificial reef providing habitat for marine life.</p>",
              image: "HNL-Pearl_Harbor.jpg",
            },
            {
              name: "Diamond Head State Monument",
              shortDescription:
                "Soaring high above Waikiki, Diamond Head is the most iconic volcanic formation in all of Hawai‘i.",
              description:
                "<p>The ridge of the Leahi tuff crater dominates the Waikiki Beach skyline, where a challenging history-filled hike awaits at Diamond Head State Monument. Climb 175 stairs, shuffle through dark tunnels and encounter old military bunkers on your way to one of the most spectacular views of Honolulu and the southern shoreline.</p>",
              image: "HNL-Diamond_Head.jpg",
            },
            {
              name: "Waikiki Beach",
              shortDescription:
                "Waikiki is famous for its beaches and every hotel room is just two or three blocks away from the ocean!",
              description:
                "<p>Famous since the early 1900's, bustline Waikiki Beach is the ultimate place for people-watching, shopping, beach amenities and surfing lessons. The area buzzes after dark with waterside cocktail bars, fine dining and Kuhio Beach hula shows.</p><p>You'll have your pick of beachfront accommodation, as well as attractions such as the Honolulu Aquarium and Honolulu Zoo - there really is something for everyone in Waikiki.</p>",
              image: "HNL-Waikiki_Beach.jpg",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleStateOnClick(state) {
      this.selectedState = state;
    },
    handleDialogOnClick(place) {
      this.place = place;
      this.dialog = true;
    },
    scrollToTop() {
      this.$refs.place.scrollTop = 0;
    },
    handleResize() {
      this.scale = (window.innerWidth * 0.58565) / 800;
    },
  },
};
</script>