<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row style="padding: 0px 100px 10px 20px">
        <div>
          <h2 style="text-align:center">Tourism New Zealand ‘New Ski-Land Incentive 2024 Qualification’ Terms and Conditions (“Conditions”)</h2>
        </div>
        <v-col cols="12" class="pa-0">
          <ol>

            <li><b>The Promoter</b></li>
            <ol style="margin-left:1em;">
              <li>
                The Promotion is organised by the New Zealand Tourism Board, trading as Tourism New Zealand 
                (ABN 98 058 786 404) (<b>“TNZ”</b>), and registered at Level 12, 61 York Street, Sydney, 2000, NSW, Australia
                (contact email: tnztradeaustralia@tnz.govt.nz).
              </li>
            </ol>

            <li><b>Promotion Entry Period:</b></li>
            <ol style="margin-left:1em;">
              <li>
                The ‘New Ski-Land Incentive 2024 Qualification’ promotion (<b>“the Promotion”</b>) will run from 00:01 AEST on
15 April 2024 (<b>(the “Opening Date”</b>) to 17:00 AEST on 19 May 2024 inclusive (<b>“the Closing Date”</b>) (<b>“the Promotion Period”</b>).
              </li>
              <li>
                All competition entries received after the Closing Date are automatically disqualified.
              </li>
            </ol>

            <li><b>Eligibility</b></li>
            <ol style="margin-left:1em;">
              <li>
                This Promotion is only open to legal residents in Australia who are both (i) aged 18 years or older at the
time of entry and (ii) actively employed full time at a travel agency or tour operator as a travel consultant
selling leisure travel to New Zealand (excluding employees of airline ticketing agencies), at the time the
prize is awarded and up to and including the dates of the Prize (<b>“Participants”</b>, <b>“You”</b>).
              </li>
              <li>
                Employees of TNZ or Air New Zealand (including employees of any of their subsidiaries or affiliated
companies) and their immediate families (parents, siblings, spouses, children) and members of their
households, and anyone otherwise connected with operation or fulfilment of the Promotion and the
immediate family members of, and/or those living in the same household of such persons, are not eligible
to enter or win the Promotion.
              </li>
              <li>
                The Promotion is subject to all applicable Australian laws and regulations.
              </li>
              <li>
                To be eligible to win a Prize, participants must meet at a minimum the Tier 1 of the Entry Requirements as
detailed in Clause 4.4 below.
              </li>
              <li>
                In entering the Promotion, you confirm that you are eligible to do so and eligible and available to claim the
Prize. TNZ may require you to provide proof that you are eligible to enter the competition.
              </li>
            </ol>

            <li><b>title</b></li>
            <ol style="margin-left:1em;">
              <li>
                one
              </li>
              <li>
                two
              </li>
              <li>
                three
              </li>
            </ol>

          </ol>


              


          <ol>
            <li>
              The promotion commences at 00:01 15 April 2024 and closes at 23:59 31 May 2024 (the “Promotional Period”). 
              All times recorded throughout these terms and conditions are in Australian Daylight Standard Time. 
              Entries received outside the Promotional Period will be ineligible for entry.
            </li>

            <li>
              The promoter is Air New Zealand Limited, Level 12, 7 Macquarie Place, Sydney, NSW, 2000, (ABN 70 000 312 685) (the “Promoter”).
            </li>
            <ol type="a">
              <li>
                Entry is only open to Helloworld members (excludes Travellers Choice and MTA members) Agents must be 18 years or over, who comply with the entry criteria set out in these terms and conditions (“Entrants”). 
                Entrants must be 18 years or over at the time of departure to be eligible for the major prize draw.
              </li>
            </ol>

            <li>
              Employees of the Promoter and their immediate families, participating agents and their agencies associated with this Promotion are ineligible to enter. 
              The Promoter reserves the right to verify the validity of each entry. 
              The Promoter reserves the right to disqualify any person for tampering with the entry process.
            </li>

            <li>
              <b>Conditions of Entry:</b> How to Enter:
              <ol type="a">
                <li>
                  To be entered into the random prize draw, entrants must sell a minimum one (1) ticket on eligible Air New Zealand (stock 086). 
                  Every ticket sold will earn a point into the draw.
                </li>
                <li>
                  To be entered into the major prize draw, participants must sell a minimum one (1) ticket on eligible Air New Zealand (stock 086). 
                  Every ticket sold will earn a point into the draw.
                  Tickets sold to New Zaland on 086 stock will qualify the entrant a bonus 2 x points into the competition draw.
                  The 8 entrants with the highest number of points throughout to competition will win the major prize.
                  <br/><br/>
                  “Eligible tickets” are a child or adult one way or return airfare sold and issued on 086 ticket stock from an Australian gateway to New Zealand, the Pacific Islands, Asia or North America during the Promotional Period. 
                  Cancelled bookings made during the Promotional Period will be ineligible for entry into the promotion.
                </li>
                <li>
                  There is no limit to the number of entries an agent can earn. No payment is required to enter.
                </li>
              </ol>
            </li>

            <li>
              <b>Prizes:</b>
              <br />
              Prizes consist of a major prize draw and random spot prizes, as follows:
              <br />
              <br />
              <p>
              <b>MAJOR PRIZE</b> consists of one (1) return Economy class ticket per winner valid from BNE/SYD/MEL/PER/ADL to Christchurch capped at <b>11</b> tickets, including taxes.<br/>
              Specifically booked for the winner to attend the Helloworld Christchurch Famil in August 2024.<br/>
              Flights operated by Air New Zealand only.<br/>
              Flights are non transferable, non exchangeable and upgrades strictly not permitted.<br/>
              Extension of travel pre or post Famil is not permitted.<br/>
              Ground arrangements will be covered by Christchurch NZ tourism board and will include transport, accommodation, daily activities and some meals.<br/>
              Insurance is not included and is the responsibility of each attending Famil participant.<br/>
              <b>2</b> spots on the Famil will be rewarded to agents who complete the CHRISTCHURCH module in DUO.<br/>
              This will be drawn at random and to be eligible agents must be registered in DUO, have completed and passed the Destination Christchurch module.<br/>
              <b>1</b> Famil spot will be drawn at random using a business card draw during the upcoming OMC event, held in Sydney on the weekend of the 2nd – 5th May.<br/>
              Only eligible Owner Managers or Frontline staff in attendance may enter the draw, and agents must be based in the AU region to participate.<br/>
              The one (1) winner will be drawn from the business card draw at the conclusion of the Conference, on Sunday the 5th May.<br/>
              </p>

              <b>Random Spot prizes</b> will include:
              <br />
              <br />
              <p>
                <b>5 x $200 Gift cards per week.</b><br/>
                Every week during the competition Air New Zealand will draw 5 random agents from the prize draw.<br/>
                Winners per week will each receive one (1) Gift card valued at $200.<br/>
                To qualify, agents must have sold a minimum one (1) ticket on Air New Zealand.<br/>
                <br/>
                a. The prize may have an impact on your tax and FBT, you are encouraged to seek your own tax advice.<br/>
                b. FBT is the responsibility of the winning consultant.<br/>
                c. The prize winner must be employed as a travel agent for the Helloworld Group in Australia at the time of prize redemption.<br/>
              </p>
            </li>

            <li>
              Attempts will be made to notify winners by email from the email address provided on the promotion entry. 
              Subject to any written directions given under Reg. 37 of the Lottery and Gaming Regulations (SA) or other applicable law, if the Promoter, having used reasonable efforts, is unable to contact the winner by 17:00 the following Business Day of the draw, then the prize is forfeited, and further draw(s) will be conducted until the prize is awarded (subject to any further regulatory directions).
              The winner, if unable to attend the Famil trip, may elect another member from their team or immediate office to attend on their behalf.
            </li>

            <li>
              The Promoter’s decision is final, and no correspondence will be entered into.
            </li>

            <li>
              The total prize pool is valued at a maximum of $16,000. 
              The random spot prizes have a combined value of $6000.00AUD. 
              The flight and tax value of the return flights from Australia to Christchurch have an approximate value of $10,000.00AUD.
            </li>

            <li>
              The prizes cannot be converted into cash. 
              The prizes are not transferable to other persons or organisations. 
              If a prize or receipt of it incurs a tax liability, the relevant prize winner is liable for payment of such tax.
            </li>

            <li>
              Subject to the State legislation, the Promoter reserves the right to substitute the prize in whole (or any of its components), with a substitute prize of equal or greater value and the prize winner will be notified accordingly.
            </li>

            <li>
              All prize travel will be subject to Air New Zealand’s conditions of carriage, to view visit airnewzealand.com.au. 
              Airpoints Dollars™ cannot be accrued on prize air travel. Prize cannot be used in conjunction with any other special offer.
            </li>

            <li>
              Neither the Promoter nor any other person or party associated with the Promotion shall be liable for any delayed, lost, or misdirected mail, email or other communication. 
              Further, to the fullest extent permitted by law, the Promoter excludes liability for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, technical problems or traffic congestion on the internet or any website, theft or destruction or unauthorised access to or alteration of entries. 
              The Promoter assumes no responsibility for any injury or damage to participants or any other person’s computer related to or resulting from participation in or downloading any materials in this promotion. 
              The use of any automated entry software or any other mechanical or electronic means that allows an entrant to automatically enter repeatedly is prohibited and will render all entries submitted by that entrant invalid.
            </li>

            <li>
              Neither the Promoter nor any other person or party associated with this promotion shall be liable for any loss or damage whatsoever suffered (including but not limited to direct, indirect, consequential or economic loss) or personal injury suffered or sustained in connection with either participation in this promotion or with any prizes offered, except for any liability that cannot be excluded.
            </li>

          </ol>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  text-align: left;
  font-family: "AirNZ Sans Semibold";
  font-size: 30px;
  margin: 0px 0px 20px 0px;
}

li {
  margin-top: 10px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>