<template>
  <v-main style="margin: 10px">
    <v-card>
      <v-col cols="12">
        <h3>Profile</h3>
      </v-col>
      <v-simple-table dense>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{{ person.firstName + " " + person.lastName }}</td>
          </tr>
          <tr>
            <td>Account Name</td>
            <td>{{ person.agencyName ? person.agencyName : ' - ' }}</td>
          </tr>
          <tr>
            <td>State</td>
            <td>{{ person.agencyState }}</td>
          </tr>
          <tr>
            <td>Email</td>
            <!-- Role also guarded in backend, contact backend programmer for role change -->
            <td>{{ person.email }} <v-icon v-if="$store.state.principal.role == 'super_user'" class="ml-2"
                @click="showChangeEmailDialog">mdi-pencil</v-icon></td>
          </tr>
          <tr>
            <td>Mobile Number</td>
            <!-- Role also guarded in backend, contact backend programmer for role change -->
            <td>{{ person.mobileNumber }} <v-icon v-if="$store.state.principal.role == 'super_user'" class="ml-2"
                @click="showChangeMobileDialog">mdi-pencil</v-icon></td>
          </tr>
          <!-- <tr>
            <td>Store</td>
            <td>{{ person.storeBranch }}</td>
          </tr> -->
          <tr>
            <td>Agency Code</td>
            <td>{{ person.agencyCode }} <v-icon class="ml-2" @click="showChangeAgencyDialog">mdi-pencil</v-icon></td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ person.status }} <v-icon
                v-if="$store.state.principal.role == 'super_user' && person.status != 'Pending'" class="ml-2"
                @click="showChangeStatusDialog">mdi-pencil</v-icon></td>
          </tr>
          <tr>
            <td>Last Login</td>
            <td>{{ person.transitMap.lastLogin }}</td>
          </tr>
          <tr>
            <td>Tag</td>
            <td>
              <div v-if="person.transitMap.tag" class="d-inline-flex text-center my-1">
                <div class="
                      state-badge
                      py-1
                      px-3
                      secondary
                      text-no-wrap
                      rounded-pill
                    " v-for="(tag, idxTag) in person.transitMap.tag" :key="idxTag">
                  {{ tag }}
                </div>
                <v-icon @click="showTagDialog">mdi-plus-circle-multiple-outline</v-icon>
              </div>
              <div v-else class="d-inline-flex text-center my-1">
                <v-icon @click="showTagDialog">mdi-plus-circle-multiple-outline</v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Sales Logged</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <div class="d-flex px-2">
                  <h4>Sales Log Count</h4>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th class="text-left">Status</th>
                      <th class="text-left">Logged</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Validated</td>
                      <td>{{ salesLogCount.matched }}</td>
                    </tr>
                    <tr>
                      <td>Pending</td>
                      <td>{{ salesLogCount.pending }}</td>
                    </tr>
                    <tr>
                      <td>Error</td>
                      <td>{{ salesLogCount.error }}</td>
                    </tr>
                    <tr v-if="person.email &&
                      (person.email.includes('@mtatravel.com.au') ||
                        person.email.includes('@travelmanagers.com.au'))
                      ">
                      <td>NA Campaign (2-29 May 2022)</td>
                      <td>{{ salesLogCount.campaignNA }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="salesLoggedHeaders" :items="salesLogged" :items-per-page="15"
                  :search="salesLoggedKeyword">
                  <template v-slot:top>
                    <v-row no-gutters style="padding: 20px 10px 15px">
                      <v-col cols="12" sm="8">
                        <div class="d-flex px-2">
                          <h4>Sales Log Details</h4>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <div class="d-flex px-2">
                          <v-text-field v-model="salesLoggedKeyword" append-icon="mdi-magnify" label="Search" hide-details
                            style="margin-right: 10px"></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Modules Completed</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :headers="trainingModuleHeaders" :items="completedModules" :items-per-page="15"
              :search="trainingModulesKeyword">
              <template v-slot:top>
                <v-row no-gutters style="padding: 20px 10px 15px">
                  <v-col cols="12" sm="8">
                    <div class="d-flex px-2"></div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="d-flex px-2">
                      <v-text-field v-model="trainingModulesKeyword" append-icon="mdi-magnify" label="Search" hide-details
                        style="margin-right: 10px"></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Tiles Visited</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :headers="tilesVisitedHeaders" :items="tilesVisited" :hide-default-footer="true">
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Toolkit Downloads</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :headers="toolkitDownloadsHeaders" :items="toolkitDownloads" :hide-default-footer="true">
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="$store.state.principal.role != 'bdm'">
          <v-expansion-panel-header>
            <h3>Profile Updates</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :headers="profileUpdatesHeaders" :items="profileUpdates" :hide-default-footer="true">
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-dialog v-model="tagDialog.show" max-width="600px">
      <v-card class="pa-8">
        <v-card-title>Update Tag</v-card-title>
        <div class="d-inline-flex" style="width: 100%" v-for="(inputTag, indexTag) in tagDialog.value" :key="indexTag">
          <v-text-field v-model="tagDialog.value[indexTag]"
            :rules="[(v) => !!v || 'This field is required', (v) => v == person.agencyId || 'Please select new agency']"
            label="Tag" style="margin-right: 10px" outlined></v-text-field>
          <v-btn @click="removeTag(indexTag)" color="red" icon dark>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn @click="addTag()" color="primary darken-1" small
            :disabled="!tagDialog.value[tagDialog.value.length - 1] != ''">Add Tag</v-btn>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeTagDialog">Cancel</v-btn>
          <v-btn @click="saveTag" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="changeEmailDialog.show" max-width="600px">
      <v-card class="pa-8">
        <v-card-title>Change Email</v-card-title>
        <div class="my-2">
          Current email: <b>{{ person.email }}</b>
        </div>
        <v-form v-model="changeEmailFormValid">
          <v-text-field v-model="changeEmailDialog.value" :rules="[ruleEmail]" @input="isEmailLostFocus = false"
            :class="{ 'line-blue': changeEmailDialog.value !== undefined && changeEmailDialog.value.length > 0 }"
            label="New email" style="margin-right: 10px" outlined dense></v-text-field>
          <span style="font-size: 12px;" v-if="emailHint != ''" class="red--text pt-0">{{ emailHint }}</span>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeChangeEmailDialog">Cancel</v-btn>
          <v-btn @click="changeEmail" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="changeMobileDialog.show" max-width="600px">
      <v-card class="pa-8">
        <v-card-title>Change Mobile Number</v-card-title>
        <div class="my-2">
          Current mobile number: <b>{{ person.mobileNumber }}</b>
        </div>
        <v-form v-model="changeMobileFormValid">
          <v-text-field v-model="changeMobileDialog.value" :rules="[ruleMobile]" @input="isMobileLostFocus = false"
            :class="{ 'line-blue': changeMobileDialog.value !== undefined && changeMobileDialog.value.length > 0 }"
            label="New mobile number" style="margin-right: 10px" outlined dense></v-text-field>
          <span style="font-size: 12px;" v-if="mobileNumberHint != ''" class="red--text pt-0">{{ mobileNumberHint
          }}</span>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeChangeMobileDialog">Cancel</v-btn>
          <v-btn @click="changeMobile" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="changeAgencyDialog.show" max-width="800px">
      <v-card class="pa-8">
        <v-card-title>Change Agency</v-card-title>
        <div class="my-2">
          Current agency: <b>{{ person.agencyName ? person.agencyCode + ' - ' + person.agencyName : "N/A" }}</b>
        </div>
        <v-autocomplete v-model="changeAgencyDialog.value" :items="agencyList" item-value="id"
          :item-text="getAgencyConcat" :subHeader-props="{ auto: true }" class="duo-description rounded-0" flat solo dense
          full-width append-icon="mdi-chevron-down" no-data-text="(empty)">
          <template slot="selection" slot-scope="data">
            {{ data.item.code + ' - ' + data.item.name }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.code + ' - ' + data.item.name }}
          </template>
        </v-autocomplete>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeChangeAgencyDialog">Cancel</v-btn>
          <v-btn @click="changeAgency" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="changeStatusDialog.show" max-width="800px">
      <v-card class="pa-8">
        <v-card-title>Change Status</v-card-title>
        <div class="my-2">
          Current status: <b>{{ person.status }}</b>
        </div>
        <v-autocomplete v-model="changeStatusDialog.value" :items="['Active', 'Inactive']"
          class="duo-description rounded-0" flat solo dense full-width append-icon="mdi-chevron-down"
          no-data-text="(empty)" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeChangeStatusDialog">Cancel</v-btn>
          <v-btn @click="changeStatus" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";

export default {
  data: function () {
    return {
      personId: 0,
      person: { transitMap: {} },
      completedModules: [],
      salesLogCount: {},
      tilesVisited: [],
      profileUpdates: [],
      trainingModulesKeyword: "",
      trainingModuleHeaders: [
        { text: "Date", value: "transitMap.created", width: "120px" },
        { text: "Module", value: "moduleName" },
        { text: "Description", value: "moduleDescription" },
        { text: "Attempt", value: "attempt" },
        { text: "Score", value: "finalScore" },
        { text: "Campaign Answer", value: "transitMap.freeAnswer" },
      ],
      tilesVisitedKeyword: "",
      tilesVisitedHeaders: [
        { text: "Tiles Clicked", value: "value" },
        { text: "Count", value: "count" },
        { text: "Last Clicked", value: "transitMap.created", width: "120px" },
      ],
      toolkitDownloadsHeaders: [
        { text: "Files Downloaded", value: "value" },
        { text: "Count", value: "count" },
        { text: "Last Clicked", value: "transitMap.created", width: "120px" },
      ],
      profileUpdatesKeyword: "",
      profileUpdatesHeaders: [
        { text: "Type", value: "value" },
        { text: "Count", value: "count" },
        { text: "Last Update", value: "transitMap.created", width: "120px" },
      ],
      salesLogged: [],
      toolkitDownloads: [],
      salesLoggedHeaders: [
        {
          text: "Status",
          align: "start",
          value: "statusDisplay",
        },
        { text: "PNR", value: "bookingCode", align: "center" },
        { text: "Date Created", value: "transitMap.creationDate", align: "center", width: "120px" },
        {
          text: "Departure",
          value: "itineraryOriginAirportCode",
          align: "center",
        },
        {
          text: "Arrival",
          value: "itineraryDestinationAirportCode",
          align: "center",
        },
        { text: "Total Passengers", value: "transitMap.passengerNum", align: "center" },
        { text: "Econ.", value: "economySectors", align: "center" },
        { text: "Prem.", value: "premiumEconomySectors", align: "center" },
        { text: "Bus.", value: "businessPremierSectors", align: "center" },
        { text: "Campaign", value: "campaign", align: "center" },
      ],
      salesLoggedKeyword: "",
      tagDialog: {
        show: false,
        value: [""],
      },
      changeEmailDialog: {
        show: false,
        value: "",
      },
      changeMobileDialog: {
        show: false,
        value: "",
      },
      changeAgencyDialog: {
        show: false,
        value: null,
      },
      changeStatusDialog: {
        show: false,
        value: "",
      },
      changeEmailFormValid: false,
      changeMobileFormValid: false,
      emailFetching: false,
      emailHint: "",
      mobileNumberHint: "",
      emailBannedList: [],
      isEmailLostFocus: false,
      isMobileLostFocus: false,
      agencyList: [],
    };
  },
  created() {
    /* eslint-disable no-console */
    this.personId = this.$route.params.personId;
  },
  mounted() {
    this.getDetails();
    this.listBannedEmail();
    this.getAgencyList();
  },
  watch: {
    isEmailLostFocus: async function (val) {
      if (this.isEmailLostFocus == true) {
        await this.checkEmail(
          this.changeEmailDialog.value
        );
      }
    },
    isMobileLostFocus: async function (val) {
      if (this.isMobileLostFocus == true) {
        await this.checkMobileNumber(
          this.changeMobileDialog.value
        );
      }
    },
  },
  methods: {
    getDetails() {
      axios
        .get("/system/person/info/details?personId=" + this.personId)
        .then((response) => {
          if (response.data.person) {
            this.person = response.data.person;
            this.tilesVisited = response.data.tilesVisited;
            this.completedModules = response.data.completedModules;
            this.salesLogCount = response.data.salesLogCount;
            this.profileUpdates = response.data.profileUpdates;
            this.salesLogged = response.data.salesLogged;
            this.toolkitDownloads = response.data.toolkitDownloads;
          } else {
            this.$router.push(`/administration/agent/info`);
          }
        })
        .catch(() => { });
    },
    showTagDialog() {
      this.tagDialog.value =
        this.person.transitMap.tag && this.person.transitMap.tag.length > 0
          ? this.person.transitMap.tag
          : [""];
      this.tagDialog.show = true;
    },
    showChangeEmailDialog() {
      this.changeEmailDialog.show = true;
    },
    showChangeMobileDialog() {
      this.changeMobileDialog.show = true;
    },
    showChangeStatusDialog() {
      this.changeStatusDialog.value = this.person.status;
      this.changeStatusDialog.show = true;
    },
    showChangeAgencyDialog() {
      this.changeAgencyDialog.value = null;
      this.changeAgencyDialog.show = true;
    },
    addTag() {
      this.tagDialog.value.push("");
    },
    removeTag(index) {
      this.tagDialog.value.splice(index, 1);
    },
    saveTag() {
      for (let idx in this.tagDialog.value) {
        if (this.tagDialog.value[idx] == "") {
          this.tagDialog.value.splice(idx, 1);
        }
      }
      axios
        .post("/system/person/tag/save", {
          id: this.personId,
          tag: this.tagDialog.value,
        })
        .then(() => {
          this.tagDialog.show = false;
          this.getDetails();
        });
    },
    changeEmail() {
      if (this.changeEmailFormValid && this.emailHint == "") {
        axios
          .post("/system/person/email/change", { entity: { id: this.person.id, email: this.changeEmailDialog.value } })
          .then((response) => {
            if (response.status == 200) {
              this.person.email = response.data.email;
              this.closeChangeEmailDialog();
            }
          });
      }
    },
    changeMobile() {
      if (this.changeMobileFormValid && this.emailHint == "") {
        axios
          .post("/system/person/mobile/change", { entity: { id: this.person.id, mobileNumber: this.changeMobileDialog.value } })
          .then((response) => {
            if (response.status == 200) {
              this.person.mobileNumber = response.data.mobileNumber;
              this.closeChangeMobileDialog();
            }
          });
      }
    },
    getAgencyConcat(item) {
      return `${item.code} - ${item.name}`;
    },
    changeAgency() {
      if (this.changeAgencyDialog.value != null || confirm("Changing agency to empty will mark the user as 'Inactive', are you sure?")) {
        axios
          .post("/system/person/agency/change", { entity: { id: this.person.id, agencyId: this.changeAgencyDialog.value } })
          .then((response) => {
            if (response.status == 200) {
              this.person.agencyId = response.data.agencyId;
              this.person.agencyName = response.data.name;
              this.person.agencyState = response.data.state;
              this.getDetails();
              this.closeChangeAgencyDialog();
            }
          });
      }
    },
    changeStatus() {
      axios
        .post("/system/person/status/change", { entity: { id: this.person.id, status: this.changeStatusDialog.value } })
        .then((response) => {
          if (response.status == 200) {
            this.person.status = response.data.status;
            this.getDetails();
            this.closeChangeStatusDialog();
          }
        });
    },
    closeTagDialog() {
      this.tagDialog.show = false;
      this.tagDialog.value = [""];
    },
    closeChangeEmailDialog() {
      this.changeEmailDialog.show = false;
      this.changeEmailDialog.value = "";
    },
    closeChangeMobileDialog() {
      this.changeMobileDialog.show = false;
      this.changeMobileDialog.value = "";
    },
    closeChangeAgencyDialog() {
      this.changeAgencyDialog.show = false;
      this.changeAgencyDialog.value = null;
    },
    closeChangeStatusDialog() {
      this.changeStatusDialog.value = null;
      this.changeStatusDialog.show = false;
    },
    ruleEmail(v) {
      this.emailHint = "";
      if (v != undefined && v.length > 0) {
        if (this.$root.isAllowedEmail(v)) {
          return true;
        }
        let emailValidation = this.$root.validateEmailPattern(v);

        if (emailValidation == true) {
          let type = null;
          let prefix = v.slice(v.indexOf("@"));
          for (let o of this.emailBannedList) {
            if (prefix.indexOf(o.prefix) == 0) {
              type = o.type;
              break;
            }
          }
          if (type) {
            if (type === "Personal") { // Allowed for admin
              return (this.isEmailLostFocus = true);
            }
            return "This email domain cannot be accepted. The duo program is available to Australian travel agents only.";
          } else {
            return (this.isEmailLostFocus = true);
          }
        } else {
          return "Please enter a valid email address";
        }
      } else {
        return "Please enter a valid email address";
      }
    },

    async checkEmail(v) {
      this.emailHint = "";

      await axios
        .post("/system/authentication/email/check", { email: v })
        .then((response) => {
          if (response.status == 200 && response.data == true) {
            console.log(response);
            this.emailHint = "This email address is already registered to duo!";
          }
        });
    },

    ruleMobile(v) {
      console.log(v);
      if (v != undefined && v.length > 6) {
        return (this.isMobileLostFocus = true);
      } else {
        return "Please enter a valid mobile number";
      }
    },

    checkMobileNumber(v) {
      this.mobileNumberHint = "";
      axios
        .post("/system/authentication/mobile/check", {
          mobileNumber: v,
        })
        .then((response) => {
          if (response.data == true) {
            this.mobileNumberHint =
              'This contact number is already registered to duo';
          }
        })
    },

    listBannedEmail() {
      axios
        .get("/system/common/email/banned/list")
        .then((response) => {
          this.emailBannedList = response.data;
        })
        .catch(() => { });
    },

    getAgencyList() {
      axios
        .get("/system/agency/list/active")
        .then((response) => {
          this.agencyList = response.data;
        })
        .catch(() => { });
    },
  },
};
</script>