<template>
	<v-container v-if="$vuetify.breakpoint.mdAndUp" fluid style="max-width: 1296px">
		<v-row style="padding-top: 22px">
			<v-col cols="12">
				<v-card class="px-4 d-flex align-content-center" min-height="155">
					<v-row no-gutters align="center">
						<v-col cols="12" md="6" lg="4" xl="4" class="px-12 pb-8" style="padding-top: 20px">
							<v-row no-gutters align="center">
								<v-col cols="12">
									<v-row no-gutters justify="center" justify-md="start" justify-lg="start"
										justify-xl="start">
										<h1 style="font-size: 28px !important; font-family: AirNZ Sans Semibold !important">
											<b>{{ entity.firstName + " " + entity.lastName }}</b>
										</h1>
									</v-row>
									<v-row no-gutters justify="center" justify-md="start" justify-lg="start"
										justify-xl="start">
										<!-- <a style="font-size: 14px !important" href="#/profile" v-if="entity.percentagePersonal != 100">Complete or update your profile</a> -->
										<a style="font-size: 14px !important" href="#/profile">Complete or update your
											profile</a>
									</v-row>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" md="6" lg="8" xl="8" class="pt-4">
							<v-row align="start" justify="center" justify-lg="end" justify-xl="end">
								<v-col style="max-width: 120px; min-width: 98px">
									<v-row no-gutters align="start" justify="center" style="cursor: pointer;" @click="$router.push('/sales')">
										<div class="rounded-circle d-flex justify-center align-center mb-2" style="width: 70px; height: 70px; border: 3px solid #04a0a8">
											<v-icon class="icon-anz-airplane" color="duoSecondary" size="30"></v-icon>
										</div>
									</v-row>
									<v-row no-gutters align="center" justify="center">
										<p class="text-center" style="font-size: 14px; line-height: 17px">{{dashboard.salesLogged}} sales logged this month</p>
									</v-row>
								</v-col>
								<v-col style="max-width: 120px; min-width: 98px">
									<v-row no-gutters align="start" justify="center" style="cursor: pointer;" @click="$router.push('/training')">
										<div class="rounded-circle d-flex justify-center align-center mb-2" style="width: 70px; height: 70px; border: 3px solid #04a0a8">
											<v-icon class="duo-icon-training" color="duoSecondary" size="30"></v-icon>
										</div> 
									</v-row>
									<v-row no-gutters align="center" justify="center">
										<!-- <p class="text-center" style="font-size: 14px; line-height: 17px;">{{dashboard.completedModules + ' / ' + dashboard.allModules}} training modules completed</p> -->
										<p class="text-center" style="font-size: 14px; line-height: 17px;">{{dashboard.completedModules}} training modules completed</p>
									</v-row>
								</v-col>
								<v-col style="max-width: 120px; min-width: 98px">
									<v-row no-gutters align="start" justify="center" @click="takeTheTour">
										<v-btn elevation="0"
											class="rounded-circle d-flex justify-center align-center duoSecondary ml-2 mb-2"
											style="width: 70px; height: 70px">
											<v-icon color="white" size="43">mdi-play-circle-outline</v-icon>
										</v-btn>
									</v-row>
									<v-row no-gutters align="center" justify="center">
										<p class="text-center" style="font-size: 14px; line-height: 17px">Take the tour</p>
									</v-row>
								</v-col>
								<v-col style="max-width: 120px; min-width: 98px">
									<v-menu offset-y :close-on-content-click="false">
										<template v-slot:activator="{ on, attrs }">
											<v-row no-gutters align="start" justify="center">
												<v-btn @blur="markAsRead()" elevation="0" fab
													class="d-flex justify-center align-center ml-2 mb-2"
													style="width: 70px; height: 70px" v-bind="attrs" v-on="on">
													<img v-if="newMessage == true" width="70" height="70"
														src="/icon/icon-notification-bell.svg"
														style="position: absolute; z-index: 1;" />
													<img v-else width="70" height="70"
														src="/icon/icon-notification-bell-read.svg"
														style="position: absolute; z-index: 1;" />
												</v-btn>
											</v-row>
											<v-row no-gutters align="center" justify="center">
												<p class="text-center" style="font-size: 14px; line-height: 17px">
													Notifications</p>
											</v-row>
										</template>
										<v-list v-if="notifications.length > 0"
											style="max-width: 600px; max-height:600px; overflow-y: auto;">
											<v-list-item v-for="item in notifications" :key="item.id" inactive dense>
												<v-list-item-avatar
													:style="containsNotificationLink(item) == true ? 'cursor: pointer' : 'cursor: auto'"
													@click="onNotificationClick(item)" inactive>
													<v-icon color="#440099" v-text="'mdi-bell-outline'"></v-icon>
												</v-list-item-avatar>
												<v-list-item-content
													:style="containsNotificationLink(item) == true ? 'cursor: pointer' : 'cursor: auto'"
													@click="onNotificationClick(item)">
													<v-list-item-title class="subtitle-2"
														:style="{ fontWeight: (item.readFlag == true ? 'normal' : 'bold') }">{{ item.title }}<v-spacer />
													</v-list-item-title>
													<v-list-item-subtitle class="text-wrap">
														<p>{{ item.message }}</p>
													</v-list-item-subtitle>
												</v-list-item-content>
												<v-btn icon color="white" @click="dismiss(item)">
													<v-icon color="grey">mdi-close</v-icon>
												</v-btn>
											</v-list-item>
										</v-list>
										<v-list v-else style="max-width: 600px; max-height:600px; overflow-y: auto;">
											<v-list-item inactive dense>
												<v-list-item-avatar inactive>
													<v-icon color="#440099" v-text="'mdi-block-helper'"></v-icon>
												</v-list-item-avatar>
												<v-list-item-title>There are no notifications to display
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card>
			</v-col>

			<duo-tile @onClickEdit="showEditDialog(item)" @onClickHide="showDialogConfirmation('unpublish', item.id)"
				v-for="item in tileList" :map="item.map" :img="item.image" :img-only="item.imageOnly" :title="item.title"
				:content="item.content" :size="item.size" :key="item.id" :btn-label="item.buttonLabel" :type="item.linkType"
				:link="item.link" @onClickDialog="showDashboardDialog(item)" @onClickCustomPopup="showPopup(item)" />

			<v-dialog v-model="tourDialog" content-class="v-dialog--custom" persistent>
				<v-card height="100%">
					<v-card-text class="ma-0 pa-0" style="position: absolute; height: 100%">
						<div style="position: relative">
							<v-icon large color="white" style="position: absolute; right: 30px; top: 20px; z-index: 1;"
								@click="
									tourDialog = false;
								stopVideo();
								">mdi-close</v-icon>
						</div>
						<video width="100%" class="iframe-size" controls controlsList="nodownload">
							<source :src="$bucket + '/video/airnz-take-tour.mp4'" type="video/mp4">
						</video>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-row>
		<v-dialog v-model="dialogPopup.show" content-class="v-dialog--winners" width="850px" persistent>
            <v-card height="416px" style="background-color: white !important">
                <v-card-title class="pa-0 d-flex justify-space-between" :style="{ backgroundColor: '#2D2926', height: '66px',  }">
                    <div class="pl-12">
                        <span class="time-square" style="color: white; width: 100% !important; max-width: 100% !important;"><b>{{dialogPopup.title}}</b></span>
                    </div>
                    <div class="d-flex justify-end">
                        <v-icon large color="white" style="right: 15px;" @click="dialogPopup.show = false;">mdi-close</v-icon>
                    </div>
                </v-card-title>
                <v-card-text class="pa-0" height="100%">
                    <v-row no-gutters>
                        <v-col cols="5" class="ma-0 pa-0">
                            <v-img :src="dialogPopup.image" height="350px" width="100%"></v-img>
                        </v-col>
                        <v-col cols="7" class="ma-0 pa-0 d-flex align-center pl-10">
                            <div v-html="dialogPopup.content" class="text-wrap" style="color: black;"></div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
		<v-dialog v-model="pickAPerkDialog.show" max-width="1000px" persistent >
			<v-card height=100% style="border-radius: 1rem;">
				<div style="background-color: #1C1044; color: white; padding: 1rem;">
					<div style="display:flex; justify-content: end">
						<v-icon large color="white" @click="closePopup()">mdi-close</v-icon>
					</div>
					<div style="display: flex; flex-direction: column; align-items: center; text-align: center; padding: 0.5rem
						0;">
						<h2 style="font-family: 'AirNZ Black Italic'; font-size: 30px; font-weight: normal; line-height: 3rem; padding: 0 0 2rem 0;">
							We’re giving you the chance to WIN <br/>from our epic prize pool with Pick-A-Perk</h2>
						<div style="width: 80%;">
							<p>Every Air New Zealand booking made to New Zealand or North America via your GDS from 05 August - 06 September 2024 is an entry into the draw. The more you sell, the more changes to WIN.</p>
						</div>
						<div style="padding: 1rem 0;">
							<p>So, what’s up for the grabs ?</p>
						</div>
						<div style="display: flex; justify-content: space-between; width: 100%;">
							<div style="width: 130px;">
								<v-img max-width="auto" style="object-fit: contain !important;"
								:src="$bucket + '/image/dashboard-tiles/Travel.png'"/>
								<p style="padding-top: 1rem;">Travel Fund</p>
							</div>
							<div style="width: 130px;">
								<v-img max-width="auto" style="object-fit: contain !important;"
								:src="$bucket + '/image/dashboard-tiles/Fuel.png'" />
								<p style="padding-top: 1rem;">Fuel Voucher</p>
							</div>
							<div style="width: 130px;">
								<v-img max-width="auto" style="object-fit: contain !important;"
								:src="$bucket + '/image/dashboard-tiles/Shopping.png'" />
								<p style="padding-top: 1rem;">Shopping Spree</p>
							</div>
							<div style="width: 130px;">
								<v-img max-width="auto" style="object-fit: contain !important;"
								:src="$bucket + '/image/dashboard-tiles/Luggage.png'" />
								<p style="padding-top: 1rem;">July Luggage</p>
							</div>
							<div style="width: 130px;">
								<v-img max-width="auto" style="object-fit: contain !important;"
								:src="$bucket + '/image/dashboard-tiles/Phone.png'" />
								<p style="padding-top: 1rem;">Laptop or Phone</p>
							</div>
							<div style="width: 130px;">
								<v-img max-width="auto" style="object-fit: contain !important;"
								:src="$bucket + '/image/dashboard-tiles/Dinner.png'" />
								<p style="padding-top: 1rem;">Team Dinner</p>
							</div>
						</div>
						<div style="padding-top: 1rem;">
								<p style="font-size: 24px;"><b>Tell us in 25 words or less what you'd pick and why?</b></p>
						</div>
						<div class="col-12">
							<v-textarea 
							v-model="pickAPerkDialog.response" 
							label="Write here..." 
							solo 
							rows="2"
							:rules="[v => !!v || 'Response is required', v => v.split(' ').length <= 25 || 'Max 25 words']"
							@keydown="limitWords"
							></v-textarea>
						</div>
					</div>
					<div style="display: flex; justify-content: space-between; padding: 1rem 2rem;">
						<div>
							<p style="font-style: italic"><a :href="$window.location.origin + '/#/page/Pick-A-PerkTermsandConditions'" target="_blank" style="color: white" >Terms and Conditions</a> apply</p>
						</div>
						<div>
							<v-btn style="font-size: 16px; height: 45px; width: 180px; color: black; text-transform: none !important;" @click="enterPickAPerk()">Enter Now</v-btn>
						</div>
					</div>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="winSpotQueenstownDialog.show" max-width="920px" persistent >
			<v-card height=100% style="border-radius: 1rem;">
				<div style="background-color: #1C1044; color: white; padding: 1rem;">
					<div style="display:flex; justify-content: end">
						<v-icon large color="white" @click="closePopup()">mdi-close</v-icon>
					</div>
					<div style="display: flex; flex-direction: column; align-items: center; text-align: center; padding: 0.5rem 1rem;">
						<h2 style="font-family: 'AirNZ Black Italic'; font-size: 36px; font-weight: normal; line-height: 3rem; padding: 0 0 2rem 0;">
							Win your way to Queenstown</h2>
						<div style="width: 80%;">
							<p>Air New Zealand is offering TravelManagers an exclusive opportunity to WIN 1 of 4 spots<br />on a famil to Queenstown! Sell Air New Zealand on 086 ticket stock to any destination in<br />New Zealand or North America from 01 Oct - 30 Nov 2024 to be in to WIN.</p>
						</div>
						<span style="padding-top: 1rem;">To register</span>
						<div style="padding-top: 0.5rem;">
							<p style="font-size: 22px;"><b>Tell us in 50 words or less, what can Air New Zealand do to work better with you?</b></p>
						</div>
						<div v-if="winSpotQueenstownDialog.submitted == false" class="col-12 my-0 py-0">
							<v-form v-model="winSpotQueenstownDialog.validForm" ref="winSpotQueenstownDialogValidForm" lazy-validation>
								<v-textarea 
									v-model="winSpotQueenstownDialog.response" 
									label="Write here..." 
									solo 
									rows="2"
									:rules="[(v) => !!v || 'Please enter your answer', (v) => (v.split(' ').length <= 50) || 'Max 50 words']"
									@keydown="event => limitWords(event, 50)"
								></v-textarea>
							</v-form>
						</div>
						<v-col v-else cols="12">
							<v-row no-gutters class="correct-answer d-flex justify-center">
								<v-alert dense type="success">Submitted</v-alert>
							</v-row>
						</v-col>
					</div>
					<v-row class="d-flex justify-space-between align-center" style="padding: 0.5rem 1.0rem; padding-left: 1.8rem; padding-bottom: 1.6rem;">
						<v-col class="d-flex" :cols="winSpotQueenstownDialog.submitted == false ? 6:12" :class="winSpotQueenstownDialog.submitted == false ? 'justify-start':'justify-center'">
							<div>
								<p style="font-style: italic; font-size: 14px;"><a :href="$window.location.origin + '/#/page/WinyourwaytoQueenstownonanexclusivefamil'" target="_blank" style="color: white" >Terms and Conditions</a> apply</p>
							</div>
						</v-col>
						<v-col v-if="winSpotQueenstownDialog.submitted == false" class="d-flex justify-end">
							<div>
								<v-btn style="font-size: 16px; height: 45px; width: 180px; color: black; text-transform: none !important;" @click="submitWinSpotQueenstownDialog()">Submit</v-btn>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-card>
		</v-dialog>
		<dashboard-dialog :title="dashboardDialog.title" :image="dashboardDialog.image" :show="dashboardDialog.show"
			:content="dashboardDialog.content" @onCloseDialog="closeDashboardDialog" />
		<dialog-video :show="videoDialog.show" :title="videoDialog.title" :link="videoDialog.link" @onCloseDialog="closeVideoDialog"/>
	</v-container>

	<dashboard-mobile v-else></dashboard-mobile>
</template>


<style scoped>
.v-dialog--custom {
	width: 902px;
	height: 508px;
}

.iframe-size {
	/* height: 450px; */
	display: block;
	border: none;
	height: 100%;
	width: 100%;
	z-index: 2;
}
</style>

<script>
/* eslint-disable no-console */
import axios from "axios";
import dashboardDialog from "./dialog/dashboardDialog.vue"
import duoTile from "./creator/duoTile.vue"
import dialogVideo from "./dialog/dialogVideo.vue";
import dashboardMobile from "./mobile/dashboardMobile.vue";

export default {
	components: {
		dashboardDialog,
		duoTile,
		dialogVideo,
		dashboardMobile
	},
	data() {
		return {
			entity: {
				firstName: "",
				lastName: "",
				email: "",
				loginCount: null,
				map: {
					tourVideoFlag: false
				}
			},
			dashboard: {
			},
			tileList: [],
			dashboardDialog: {
				show: false,
				image: "/image/dashboard-dialogs/WinnersNYC.JPG",
				title: "Bound for NYC Incentive winners",
				content: "<p><b>Congratulations to all our winners from our recent <br/> Bound for NYC incentive, with eight lucky agents off <br/> to experience the best of New York city for five epic <br/> nights in May! </b></p><p>The lucky agents are Donna, Lisa, Brook, Sophie, Liam, Evan, Marissa and Melissa, and are all so excited to be headed to New York City with Air New Zealand, flying via Auckland.</p><p>All winners have now been contacted, and congratulations to the 40 spot prize winners throughout the campaign, taking home Apple AirTags and $100 eGift cards.</p><p>Keep an eye out on the New York highlights from the famil, and thank you again to everyone who entered. </p>"
			},
			description: "",
			title: "",
			percentage: 0,
			videoDialog: {
				show: false,
				link: "",
				title: ""
			},
			dialogPopup: {
                show: false,
                title: "",
                content: "",
                image: "",
            },
			tourDialog: false,
			birthdayDialog: false,
			notifications: [],
			newMessage: false,
			pickAPerkDialog: {
				show: false,
				data: {},
				response: ""
			},
			winSpotQueenstownDialog: {
				show: false,
				data: {},
				response: "",
				submitted: false,
				validForm: true
			}
		};
	},
	mounted: function () {
		this.getList();
		this.profile();
		this.personDashboard();
		this.bookingPercentage();
		this.getNotification();
	},
	computed: {
		wordCount: function() {
			if (this.scoreSpotDialog.responseText.trim() === '') {
				return 0; // No words if input is empty or only spaces
			}
			// Split the input into words by spaces and count them
			return this.scoreSpotDialog.responseText.trim().split(/\s+/).length;
		},
	},
	methods: {
		getList() {
			axios
				.get("/system/tile/dashboard")
				.then((response) => {
					this.tileList = response.data.filter(function (el) {
						return el.sequence;
					})
				})
				.catch(() => { });
		},
		profile() {
			axios
				.get("/system/person/me")
				.then((response) => {
					this.entity = response.data;
					this.tourVideo();
				})
				.catch(() => { });
		},
		personDashboard() {
			axios
				.get("/system/person/dashboard?excludedModuleList=" + this.getExcludedModules())
				.then((response) => {
					this.dashboard = response.data;
				})
				.catch(() => { });
		},
		getExcludedModules() {
			// Include excluded modules from duoTraining.vue, some training does not available for all
			let result = null;

			if (!this.$store.state.principal.email.endsWith('@airnz.co.nz') &&
				!this.$store.state.principal.email.endsWith('@tag-group.com') &&
				!this.$store.state.principal.email.endsWith('@travelbeyond.com.au')) {
				result = "91507";
			}

			return result;
		},
		bookingPercentage() {
			axios
				.get("/system/person/me/booking/percentage")
				.then((response) => {
					this.percentage = response.data;
				})
				.catch(() => { });
		},
		boundForNYCWinners() {
			this.dashboardDialog.show = true,
				this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: "Bound for NYC winners" });
		},
		closeDashboardDialog() {
			this.dashboardDialog.show = false
		},
		showDashboardDialog(item) {
			if (item.linkType == "Popup") {
				this.dialogPopup.show = true;
				this.dialogPopup.title = item.map.popup.title;
				this.dialogPopup.content = item.map.popup.content;
				this.dialogPopup.image = item.map.popup.image;
			} else {
				this.videoDialog.link = item.link;
				this.videoDialog.title = item.reportTitle;
				this.videoDialog.show = true;
				this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: item.reportTitle });
			}
		},
		showPopup(item) {
			if (item.link == "pick-a-perk") { 
				this.pickAPerkPopup(item);
			} else if (item.link == "win-spot-queenstown") {
				axios
				.post("/system/activitylog/person/value/list", { value: 'Win your way to Queenstown Popup' })
				.then(response => {
					if (response.status === 200) {
						if (response.data.length > 0) {
							this.winSpotQueenstownDialog.submitted = true;
						}
					}
					this.winSpotQueenstownPopup(item);
				})
				.catch(() => { });
			}
		},
		closePopup() {
			this.pickAPerkDialog.show = false;
			this.winSpotQueenstownDialog.show = false;
		},
		pickAPerkPopup(item) {
			this.pickAPerkDialog.show = true;
			this.pickAPerkDialog.data = item;
		},
		enterPickAPerk() {
			if (this.pickAPerkDialog.response == "" || this.pickAPerkDialog.response.trim() === '') {
				this.$store.commit("showNotification", 
					{ 	
						content: "Response is required", 
						color: "#00AFB9",
						buttonText: "OK"
					}
				);
			} else {
				this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: this.pickAPerkDialog.data.reportTitle + " Popup", response: this.pickAPerkDialog.response });
				this.pickAPerkDialog.show = false;
				this.pickAPerkDialog.response = ""
				this.$store.commit("showNotification", 
					{ 	
						title: "Awesome", 
						content: "Thanks you’ve now registered.", 
						color: "#00AFB9",
						buttonText: "OK"
					}
				);
			}
		},
		winSpotQueenstownPopup(item) {
			this.winSpotQueenstownDialog.show = true;
			this.winSpotQueenstownDialog.data = item;
		},
		submitWinSpotQueenstownDialog() {
			if (this.winSpotQueenstownDialog.response == "" || this.winSpotQueenstownDialog.response.trim() === '') {
				this.$store.commit("showNotification", 
					{ 	
						content: "Response is required", 
						color: "#00AFB9",
						buttonText: "OK"
					}
				);
			} else if (this.$refs.winSpotQueenstownDialogValidForm.validate()) {
				this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: 'Win your way to Queenstown Popup', response: this.winSpotQueenstownDialog.response });
				this.winSpotQueenstownDialog.show = false;
				this.winSpotQueenstownDialog.response = ""
				this.$store.commit("showNotification", 
					{ 	
						title: "Awesome", 
						content: "Thanks for registering for the incentive. Sell Air New Zealand to be in the draw to WIN.", 
						color: "#00AFB9",
						buttonText: "OK"
					}
				);
			}
		},
		limitWords(event, wordLimit) {
			const inputField = event.target;
			const currentValue = inputField.value;
			const words = currentValue.split(' ');

			// Allow backspace, delete, arrow keys, etc.
			if (event.key === 'Backspace' || event.key === 'Delete' || 
				event.key === 'ArrowLeft' || event.key === 'ArrowRight' || 
				event.key === 'ArrowUp' || event.key === 'ArrowDown' || 
				event.key === 'Tab') {
				return;
			}

			// Prevent input if word count is at or over the limit
			if (words.length >= (wordLimit+1)) {
				event.preventDefault();
			}
		},
		takeTheTourTiles() {
			this.tourDialog = true;
			this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: "Take the tour" });
		},
		takeTheTour() {
			this.videoDialog.link = "https://www.youtube.com/embed/ImcjZrkm-GM?si=PmNs39_bg8s7cFSb";
			this.videoDialog.title = "duo - we're better together";
			this.videoDialog.show = true;
		},
		tourVideo() {
			if ((this.entity.loginCount === 1 && !this.entity.map.tourVideoFlag) || !this.entity.map.toolkitVideoFlag) {
				this.takeTheTour();
				axios
					.get("/system/person/flagging/tour")
					.then((response) => {
						this.map = response.data.map;
					})
					.catch(() => { });
			}
		},
		closeVideoDialog() {
			this.videoDialog.show = false;
			this.stopVideo();
			this.videoDialog.link = ""
			this.videoDialog.title = "";
		},
		stopVideo() {
			// youtube
			var iframes = document.querySelectorAll("iframe");
			Array.prototype.forEach.call(iframes, (iframe) => {
				iframe.contentWindow.postMessage(JSON.stringify({ event: "command", func: "stopVideo" }), "*");
			});

			// mp4
			var videos = document.querySelectorAll('iframe, video');
			Array.prototype.forEach.call(videos, (video) => {
				if (video.tagName.toLowerCase() === 'video') {
					video.pause();
					video.currentTime = 0;
				} else {
					var src = video.src;
					video.src = src;
				}
			});
		},
		dismiss(item) {
			axios
				.post("/system/person/notification/dismiss", { id: item.id })
				.then(response => {
					if (response.status === 200) {
						this.notifications.splice(this.notifications.indexOf(item), 1);
					}
				})
				.catch(() => { });
		},
		async markAsRead() {
			if (this.newMessage == true && !this.$store.state.principal.impersonate) {
				this.notifications.forEach(item => {
					if (!item.readFlag || item.readFlag == false) {
						item.readFlag = true;
					}
				});

				axios
					.post("/system/person/notification/read", {})
					.then(() => {
						this.newMessage = false;
					})
					.catch(() => { });
			}
		},
		getNotification() {
			axios
				.get("/system/person/notification/list")
				.then(response => {
					this.notifications = response.data;
					if (this.notifications.length > 0) {
						// Sorted by read flag in BE, so only check first row
						if (!this.notifications[0].readFlag || this.notifications[0].readFlag == false) {
							this.newMessage = true;
						}
					}
				})
				.catch(() => { });
		},
		sendBannerAnalytics(url) {
			try {
				this.$gtm.trackEvent({
					event: 'click_banner_home',
					category: 'banner_home',
					action: 'click',
					label: url,
				});
			} catch (error) {
				// console.log(error);
			}
		},
		onNotificationClick(notification) {
			if (this.containsNotificationLink(notification)) {
				window.open(notification.transitMap.link, "_self");
			}
		},
		containsNotificationLink(notification) {
			return notification.transitMap.link && notification.transitMap.link.length > 0;
		},
		submitScoreSpot() {
			if (this.$refs.form.validate() && this.wordCount > 0) {
				var scoreSpot = {}
				scoreSpot.value = this.scoreSpotDialog.responseText;

				axios
				.post("/system/scorespot/save", {
					entity: scoreSpot,
				})
				.then(() => {
					// reset
					this.scoreSpotDialog.show = false;
					this.scoreSpotDialog.validForm = true;
					this.scoreSpotDialog.responseText = '';
					this.scoreSpotDialog.showDetails = false;
					// show notif
					this.$store.commit("showNotification", 
						{ 	title: "Awesome", 
							content: "Thanks you’ve now registered for the incentive. No further actions required in duo, simply sell Air New Zealand via your GDS to be in to WIN", 
							color: "#00AFB9",
							buttonText: "OK"
						}
					);
				});
			} else {
				this.scoreSpotDialog.showDetails = true;
			}
		},
	}
};
</script>