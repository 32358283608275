<template>
  <div
    v-if="$vuetify.breakpoint.mdAndUp"
    :style="{ padding: '0 24px 0 24px', minHeight: '100vh', backgroundImage: 'url(' + (entity.backgroundImage ? entity.backgroundImage : 'https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg') + ')', backgroundSize: 'cover', margin: 0 }">
    <duo-landing-page @onReset="getData()" :show="loaded" :entity="entity" :question-map="entity.transitMap.questionMap" :submitted-map="entity.transitMap.submittedMap" />
  </div>

  <landing-page-view-mobile v-else></landing-page-view-mobile>
</template>
  
<script>
/* eslint-disable no-console */
import axios from "axios";
import duoLandingPage from "./creator/duoLandingPage.vue";
import landingPageViewMobile from './mobile/landingPageViewMobile.vue';

export default {
  components: {
    duoLandingPage,
    landingPageViewMobile
  },
  data() {
    return {
      entity: {
        backgroundImage: "https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg",
        map: {
          contentList: []
        },
        transitMap: {}
      },
    }
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getData();
  },
  methods: {
    getData() {
      this.loaded = false;
      axios.get("/system/dashboard/page?slug=" + this.$route.params.slug).then(
        response => {
          if (!response.data.map.contentList) {
            response.data.map.contentList = [];
          }
          this.entity = response.data;
          this.loaded = true;
          console.log(this.entity.transitMap.submittedMap);
        }
      );
    },
  }
};
</script>