<template>
	<div>
		<!-- BDM PROFILE MAP -->
		<template v-if="isResources == false">
			<!-- BDMS TILES MOBILE -->
			<v-col :cols="12" class="d-block d-md-none">
				<v-col class="pa-sm-2 pa-0" v-for="(item, i) in states" :key="i">
					<v-container class="pa-sm-2 pa-0 mt-0 bdm-profile" style="height: 90vh; width: 100%">
						<v-col v-for="(bdm, j) in item.bdmList" :key="j" cols="12">
							<v-row class="row my-2 ml-1" style="
								color: #2d2926;
								letter-spacing: 2px;
								font-size: 18px;
								letter-spacing: 0;
								" v-if="selectedIdList.length == 1 && j === 0">
								<strong style="
									font-family: AirNZ Sans Semibold !important;
									font-weight: bold;
									">{{ selectedIdList[0].toUpperCase() }}
								</strong>&nbsp;BDMs
							</v-row>
							<v-row class="row my-2 ml-1" style="
								color: #2d2926;
								letter-spacing: 2px;
								font-size: 18px;
								letter-spacing: 0;
								" v-else-if="j === 0">
								<strong style="
									font-family: AirNZ Sans Semibold !important;
									font-weight: bold;
									">{{ selectedIdList[0].toUpperCase() }}</strong>
								&nbsp;and&nbsp;
								<strong style="
									font-family: AirNZ Sans Semibold !important;
									font-weight: bold;
									">{{ selectedIdList[1].toUpperCase() }}</strong>
								&nbsp;BDM
							</v-row>
							<v-card class="mx-auto" max-width="100%">
								<v-img :src="$bucket + '/image/bdm/' + bdm.image" height="180px" position="right top">
									<v-card-title class="fill-height align-end px-6 py-2">
										<div class="bdm-sm-name" style="font-size: 28px; letter-spacing: 0">
											<strong class="bdm-firstname">{{ bdm.firstName }}</strong>
											&nbsp;{{ bdm.lastName }}
										</div>
									</v-card-title>
								</v-img>

								<v-card-text class="bdm-card-text" style="line-height: 17px">
									<v-row v-if="bdm.territory" no-gutters class="pb-6 px-3">
										<v-icon class="pr-2" color="#2D2926">mdi-map-marker</v-icon>
										{{ bdm.territory }}
									</v-row>
									<v-row v-if="bdm.funFact" no-gutters justify="start" class="px-3">
										<v-col> <strong>Fun fact:</strong> {{ bdm.funFact }}</v-col>
									</v-row>
								</v-card-text>

								<v-card-actions v-if="!bdm.hideButton" class="bdm-action">
									<v-btn color="#2D2926" class="get-in-touch" dark style="width: fit-content"
										@click="handleOnClick(bdm)">Get in touch</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-container>
				</v-col>
			</v-col>
			<!-- BDMS TILES MOBILE END -->

			<!-- BDMS TILES DESKTOP -->
			<div ref="bdm" class="'d-none d-md-block bdm bdm-profile'">
				<v-row no-gutters justify="end" v-for="(item, i) in states" :key="i">
					<v-card class="mb-4" v-for="(bdm, j) in item.bdmList" :key="j" max-width="420px"  elevation="0"
						style="background-color: transparent !important">
						
						<!-- BDM Title -->
						<v-row class="row mt-0 ml-1" style="
							margin-bottom: 30px;
							color: #2d2926;
							letter-spacing: 2px;
							font-size: 18px;
							letter-spacing: 0;
							" v-if="selectedIdList.length == 1 && j === 0">
							<strong style="
								font-family: AirNZ Sans Semibold !important;
								font-weight: bold;
							">{{ selectedIdList[0].toUpperCase() }}</strong>
							&nbsp;BDMs
						</v-row>
						<v-row class="row mt-0 ml-1" style="
							margin-bottom: 30px;
							color: #2d2926;
							letter-spacing: 2px;
							font-size: 18px;
							letter-spacing: 0;
							" v-else-if="j === 0">
							<strong style="
								font-family: AirNZ Sans Semibold !important;
								font-weight: bold;
							">{{ selectedIdList[0].toUpperCase() }}</strong>
							&nbsp;and&nbsp;
							<strong style="
								font-family: AirNZ Sans Semibold !important;
								font-weight: bold;
							">{{ selectedIdList[1].toUpperCase() }}</strong>
							&nbsp;BDM
						</v-row>

						<!-- BDM List -->
						<v-row no-gutters>
							<v-img :src="$bucket + '/image/bdm/' + bdm.image" height="180px" position="center top">
								<v-card-title class="fill-height align-end">
									<v-container fluid class="py-0 pb-1">
										<v-row no-gutters style="
											max-height: 64px;
											max-width: 60%;
											font-size: 28px;
											letter-spacing: 0;
											">
											<strong class="bdm-firstname">{{ bdm.firstName }}&nbsp;</strong>
											{{ bdm.lastName }}
										</v-row>
										<v-row no-gutters>
											<span class="horizontal-line"></span>
										</v-row>
									</v-container>
								</v-card-title>
							</v-img>
							<v-card-text class="bdm-text" style="line-height: 17px">
								<v-row v-if="bdm.territory" no-gutters class="pb-6" align="center">
									<v-col cols="1"><v-icon class="pr-2" color="#2D2926">mdi-map-marker</v-icon>
									</v-col>
									<v-col cols="11">{{ bdm.territory }}</v-col>
								</v-row>
								<v-row v-if="bdm.funFact" no-gutters justify="start">
									<v-col> <strong>Fun fact:</strong> {{ bdm.funFact }}</v-col>
								</v-row>
								<v-row v-if="!$root.isBlank(bdm.notes)" :class="bdm.funFact ? 'pt-6' : ''" no-gutters
									justify="start">
									<v-col v-html="bdm.notes"></v-col>
								</v-row>
							</v-card-text>
							<v-card-actions class="bdm-action">
								<v-btn v-if="!bdm.hideButton" color="#2d2926" class="get-in-touch" :elevation="0" bottom dark
									style="width: fit-content" @click="handleOnClick(bdm)">Get in touch</v-btn>
								<div v-else style="height: 80px" />

								<v-btn v-if="bdm.videoIntroduction" outlined elevation="0" class="bdm-introduction"
									style="width: fit-content" @click="showBdmIntroduction(bdm)">BDM Introduction</v-btn>
							</v-card-actions>
						</v-row>
					</v-card>
				</v-row>
			</div>
		</template>



		<!-- BDM RESOURCES -->
		<template v-if="isResources == true">
			<!-- BDMS TILES DESKTOP -->
			<div ref="bdm">
				<v-row class="d-flex">
					<v-col cols="4" v-for="(bdm, j) in bdmResourcesList" :key="j" class="d-flex">
						<v-card class="mb-4" v-if="bdm.videoIntroduction" min-width="410px" elevation="0"
							style="background-color: transparent !important">

							<!-- BDM List -->
							<v-row no-gutters>
								<v-img :src="$bucket + '/image/bdm/' + bdm.image" height="180px" position="center top">
									<v-card-title class="fill-height align-end">
										<v-container fluid class="py-0 pb-1">
											<v-row no-gutters style="
												max-height: 64px;
												max-width: 60%;
												font-size: 28px;
												letter-spacing: 0;
												">
												<strong class="bdm-firstname">{{ bdm.firstName }}&nbsp;</strong>
												{{ bdm.lastName }}
											</v-row>
											<v-row no-gutters>
												<span class="horizontal-line"></span>
											</v-row>
										</v-container>
									</v-card-title>
								</v-img>
								<v-card-text class="bdm-text" style="line-height: 17px; height: 160px;">
									<v-row v-if="bdm.territory" no-gutters class="pb-6" align="center">
										<v-col cols="1"><v-icon class="pr-2" color="#2D2926">mdi-map-marker</v-icon>
										</v-col>
										<v-col cols="11">{{ bdm.territory }}</v-col>
									</v-row>
									<v-row v-if="bdm.funFact" no-gutters justify="start">
										<v-col> <strong>Fun fact:</strong> {{ bdm.funFact }}</v-col>
									</v-row>
									<v-row v-if="!$root.isBlank(bdm.notes)" :class="bdm.funFact ? 'pt-6' : ''" no-gutters
										justify="start">
										<v-col v-html="bdm.notes"></v-col>
									</v-row>
								</v-card-text>
								<v-card-actions class="bdm-action" style="height: 80px;">
									<v-btn v-if="!bdm.hideButton" color="#2d2926" class="get-in-touch" :elevation="0" bottom dark
										style="width: fit-content" @click="handleOnClick(bdm)">Get in touch</v-btn>
									<div v-else style="height: 80px" />

									<v-btn v-if="bdm.videoIntroduction" outlined elevation="0" class="bdm-introduction-resources"
										style="width: fit-content" @click="showBdmIntroduction(bdm)">BDM Introduction</v-btn>
								</v-card-actions>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</template>
	

		<!-- BDM Profile DIALOG -->
		<v-dialog v-model="dialog" max-width="477px">
			<v-card v-if="bdm != null" min-height="337px" max-height="500px">
				<div style="color: white; background-color: #2d2926" :style="{ minHeight: '120px', maxHeight: '250px' }">
					<div style="position: relative">
						<v-btn absolute top right icon style="right: 0px; top: 5px" @click="dialog = false">
							<v-icon width="12" color="white">mdi-close</v-icon>
						</v-btn>
					</div>
					<div style="padding: 29px 29px 19px 39px">
						<v-card-title class="ma-0 pa-0">
							<div class="bdm-dialog-name" style="max-height: 34px; padding-top: 7px">
								<strong>{{ bdm.firstName }}</strong>&nbsp;{{ bdm.lastName }}
							</div>
						</v-card-title>
						<v-card-subtitle class="ma-0 pa-0">
							<div class="bdm-territory" style="min-height: 31px; max-height: 100px; padding-top: 7px">
								{{ bdm.territory }}
							</div>
						</v-card-subtitle>
					</div>
				</div>

				<v-card-text class="ma-0 pa-0" style="height: 217px">
					<div style="padding-top: 34.89px"></div>
					<div style="padding: 0px 19px 0px 39px; height: 63px">
						<v-container fluid>
							<v-row class="d-flex">
								<v-col class="ma-0 pa-0">
									<div>
										<img src="/icon/icon-phone.svg" style="margin-right: 16.66px; margin-bottom: -2px"
											width="17.31px" height="17.34px" color="#05A3C7" />
										<span class="bdm-contact">{{ bdm.phoneNumber }}</span>
									</div>
									<div>
										<img src="/icon/icon-email.svg" style="margin-right: 13.66px; margin-bottom: -2.1px"
											width="21.6px" height="16.09px" color="#05A3C7" />
										<span>
											<a :href="'mailto:' + bdm.email" class="bdm-contact" style="font-size: 18px">{{
												bdm.email }}</a>
										</span>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</div>
					<div style="padding-top: 45.6px"></div>
					<div style="padding: 0px 19px 0px 39px">
						<v-container v-if="!$root.isBlank(bdm.bookings)" fluid>
							<v-row class="d-flex">
								<v-col class="ma-0 pa-0">
									<v-btn v-if="!bdm.hideButton" color="#04A0A8" width="161.4px" height="41.89px"
										@click="handleBookings(bdm)">
										<span class="bdm-booking">Book a meeting</span>
									</v-btn>
									<div v-else style="height:80px" />
								</v-col>
							</v-row>
						</v-container>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Bdm Introduction Dialog -->
		<v-dialog v-model="dialogBdmIntroduction" :content-class="isResources ? 'v-dialog-bdm-resources':'v-dialog-bdm-profile'" persistent>
			<!-- BDM Resources -->
			<v-card v-if="isResources"  height="100%" class="d-flex flex-column">
				<v-card-title v-if="bdm" class="duoPrimary pl-9" style="min-height: 55;">
					<v-row class="d-flex justify-between align-start">
						<v-col cols="10">
							<span style="font-size: 28px; font-family: 'AirNZ Sans Semibold'; letter-spacing: 0;">
								<div class="bdm-dialog-name" style="max-height: 44px; padding-top: 4px">
									<strong>{{ bdm.firstName }}</strong>&nbsp;{{ bdm.lastName }}
								</div>
							</span>
						</v-col>
						<v-col cols="2">
							<v-row class="d-flex align-start">
								<v-col cols="12" class="d-flex justify-end align-start">
									<v-icon large right color="white" @click="dialogBdmIntroduction = false; stopVideo(); bdm = null;">mdi-close</v-icon>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-title>
				
				<v-card-text class="ma-0 pa-0">
					<video v-if="bdm" width="100%" class="iframe-size" controls controlsList="nodownload">
						<source :src="$bucket + bdm.videoIntroduction" type="video/mp4">
					</video>
					<div v-if="bdm && !bdm.hideButton" style="display:flex; justify-content:space-between">
						<div></div>
						<div class="pr-3">
							<v-btn color="#2D2926" class="get-in-touch" dark style="width: fit-content" 
								@click="handleOnClick(bdm)">Get in touch
							</v-btn>
						</div>
					</div>
				</v-card-text>
			</v-card>

			<!-- BDM Profile -->
			<v-card v-else height="100%">
				<v-card-text class="ma-0 pa-0" style="position: absolute; height: 100%">
				<div style="position: relative">
					<v-icon
					large
					color="white"
					style="position: absolute; right: 30px; top: 20px; z-index: 1;"
					@click="
						dialogBdmIntroduction = false;
						stopVideo();
						bdm = null;
					"
					>mdi-close</v-icon
					>
				</div>
				<video v-if="bdm" width="100%" class="iframe-size" controls controlsList="nodownload">
					<source :src="$bucket + bdm.videoIntroduction" type="video/mp4">
				</video>
				</v-card-text>
			</v-card>			
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		states: Array,
		selectedIdList: Array,
		isResources: Boolean,
	},
	data() {
		return {
			bdm: null,
			dialog: false,
			dialogBdmIntroduction: false,
			bdmResourcesList: []
		}
	},
	mounted() {
		if(this.isResources == true) {
			this.filterByBdmVideoIntroduction();
		}
	},
	methods: {
		handleOnClick(bdm) {
			this.bdm = bdm;
			this.dialog = true;
		},
		showBdmIntroduction(bdm) {
			this.bdm = bdm;
			this.dialogBdmIntroduction = true;
			this.$root.saveActivityLog({ type: 'Watch_video', value: "BDM Introduction - " + bdm.email });
		},
		handleBookings(bdm) {
			if (!this.$root.isBlank(bdm.bookings)) {
				window.open(bdm.bookings, "_blank");
			}
		},
		stopVideo() {
			// youtube
			var iframes = document.querySelectorAll("iframe");
			Array.prototype.forEach.call(iframes, (iframe) => {
				iframe.contentWindow.postMessage(JSON.stringify({ event: "command", func: "stopVideo" }), "*");
			});

			// mp4
			var videos = document.querySelectorAll('iframe, video');
			Array.prototype.forEach.call(videos, (video) => {
				if (video.tagName.toLowerCase() === 'video') {
					video.pause();
					video.currentTime = 0;
				} else {
					var src = video.src;
					video.src = src;
				}
			});
		},
		filterByBdmVideoIntroduction() {
			this.states.forEach((state) => {
				state.bdmList.forEach((bdm) => {
					if (bdm.videoIntroduction && bdm.videoIntroductionIndex) {
						this.bdmResourcesList.push(bdm);
					}
				});
			});

			this.bdmResourcesList.sort((a, b) => {
				return a.videoIntroductionIndex - b.videoIntroductionIndex;
			});
		},
	},
};
</script>

<style scoped>
div.bdm {
  border-left: 2px solid #2d2926;
  width: 445px;
}
.profile {
  width: 3em !important;
  height: 3em !important;
}
.v-card {
  background: hsla(0, 0%, 100%) !important;
  border-radius: 4px !important;
}
.v-system-bar {
  background: none;
}
.v-list {
  background: none;
}
.v-list-item {
  background: white;
  padding-left: 20px;
  margin-bottom: 2px;
}
.bdm-text {
  font-size: 14px;
  background-color: white;
  padding: 20px 25px;
}
.bdm-action {
  width: 100%;
  background-color: white;
  padding: 0;
  padding-bottom: 10px;
  border-color: white;
}
.bdm-profile {
  height: 78vh;
  overflow-y: scroll; /* Add the ability to scroll */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.bdm-profile::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.bdm-profile {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.bdm-sm-name {
  max-height: 50px;
  border-radius: 4px;
  padding-bottom: 10px;
  padding: 5px 20px 15px 0;
}
/* Small devices (desktops, 600px > < 960px*) */
@media (max-width: 600px) {
  .bdm-sm-name {
    max-height: 70px;
    border-radius: 4px;
    padding-left: 10px;
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px 20px 15px 20px;
  }
}
/* Medium devices (desktops, 960px > < 1264px*) */
@media (min-width: 960px) {
  div.map {
    width: 48%;
  }
}
/* Large devices (desktops, 1264px > < 1600px*) */
@media (min-width: 1264px) {
  div.map {
    width: 50%;
  }
}
/* Extra large devices (> 1600px*) */
@media (min-width: 1600px) {
  .bdm-profile {
    height: 84vh;
    overflow-y: scroll; /* Add the ability to scroll */
  }
}
/* .bdm-firstname {
	padding-bottom: 12px;
	border-bottom: 4px solid rgb(0, 0, 0);
} */
.horizontal-line {
  width: 50px;
  padding-bottom: 12px;
  border-bottom: 4px solid rgb(0, 0, 0);
}
button.get-in-touch {
  letter-spacing: 0px;
  color: white;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 21px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
  text-transform: none !important;
  min-width: 163px !important;
  max-width: 163px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  border-radius: 4px !important;
  margin: 10px 25px;
  /* width: 150px; */
}

button.bdm-introduction {
  letter-spacing: 0px;
  color: black;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 21px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
  text-transform: none !important;
  min-width: 190px !important;
  max-width: 190px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  border-radius: 4px !important;
  margin: 10px 25px;
  /* width: 150px; */
}

button.bdm-introduction-resources {
  letter-spacing: 0px;
  color: black;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 21px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
  text-transform: none !important;
  min-width: 180px !important;
  max-width: 180px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  border-radius: 4px !important;
  margin: 10px 25px;
  /* width: 150px; */
}
/* button.get-in-touch:hover {
	font-size: 17px !important;
	background-color: #ffffff !important;
	color: #2d2926 !important;
	text-transform: none !important;
	min-width: 193px !important;
	max-width: 193px !important;
	min-height: 50px !important;
	max-height: 50px !important;
	border-radius: 4px !important;
} */
</style>

<!-- Do not set as scoped -->
<style>
.v-btn {
  margin-right: 10px !important;
}

/* Small devices (desktops, 600px > < 960px*) */
@media (max-width: 600px) {
  .bdm-sm-name {
    max-height: 70px;
    border-radius: 4px;
    padding-left: 10px;
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px 20px 15px 20px;
  }
}

/* Extra large devices (> 1600px*) */
@media (min-width: 1600px) {
  .bdm-profile {
    height: 84vh;
    overflow-y: scroll; /* Add the ability to scroll */
  }
}

.bdm-profile-text-header {
  letter-spacing: 0px;
  color: #2d2926;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 18px;
  font-family: AirNZ Sans Semibold !important;
  opacity: 1;
}
.bdm-profile-text-subtitle {
  letter-spacing: 0px;
  color: #2d2926;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 18px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
}
.bdm-dialog-name {
  letter-spacing: 0px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 28px !important;
  line-height: 27px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
}
.bdm-territory {
  letter-spacing: 0px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 27px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
}
.bdm-contact {
  letter-spacing: 0px;
  color: #2d2926;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 33px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  text-decoration: underline;
  /* border-bottom: 1px solid #2D2926; */
  padding-bottom: 0px;
  white-space: pre-wrap;
}
.bdm-booking {
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 21px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
}
.bdm-text-bold {
  font-family: "AirNZ Sans Semibold";
  font-size: 17px;
  line-height: 21px;
}
.v-dialog-bdm-resources {
	width: 902px;
	height: 650px !important;
	overflow-y: hidden;
}
.v-dialog-bdm-profile {
	width: 902px;
	height: 508px !important;
	overflow-y: hidden;
}
</style>