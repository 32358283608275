<template>
  <div v-if="$vuetify.breakpoint.mdAndUp" style="padding-top: 12px">
    <subHeader />

    <v-container fluid style="max-width: 1296px; padding-top: 0px">
      <!-- row 2 -->
      <v-row no-gutters>
        <duo-training-tile @onClickEdit="showEditDialog(item)" :map="item.map"
                        @onClickHide="showDialogConfirmation('unpublish', item.id)"
                        v-for="item in tileList" :img="item.image" :title="item.title" :content="item.content"
                        :size="item.size" :key="item.id" :btn-label="item.buttonLabel" :link="item.link"
                        :status="getModuleStatus(item.link)" @onClickButton="onStartTraining(item.link, item.title)" />
      </v-row>
      <!-- row 4 end -->


      <v-dialog v-model="dialogRetake" max-width="400">
        <v-card class="mx-auto" max-width="400">
          <v-toolbar flat color="#221551" dark>
            <v-toolbar-title>{{moduleStatus == 'Resume' ? 'Resume' : 'Finish'}}</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <h2 class="text-h6 my-4">{{ moduleStatus == 'Resume' ? 'You haven\'t finished this training yet' : 'You have finished this training' }}</h2>
            <h2 class="text-h6 my-4" v-if="moduleStatus != 'Resume'">Your Score: {{ finalScore }}</h2>
          </v-card-text>

          <v-card-actions class="justify-center pb-6">
            <v-btn @click="handleRetakeModule()" class="duo-primary">Retake</v-btn>
            <v-btn @click="handleReviewModule()" class="duo-secondary">{{moduleStatus == 'Resume' ? 'Resume' : 'Review'}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Training Module is disabled -->
      <v-dialog v-model="dialogModuleDisable" max-width="400">
        <v-card class="mx-auto" max-width="400">
          <v-toolbar flat color="#221551" dark>
            <v-toolbar-title>Info</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <h2 class="text-h6 my-4">The training module is disabled</h2>
          </v-card-text>

          <v-card-actions class="justify-center pb-6">
            <v-btn @click="closeDialogModule" class="duo-primary">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
  <duo-training-mobile v-else></duo-training-mobile>
</template>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";
import duoTrainingTile from "./creator/duoTrainingTile.vue";
import duoTrainingMobile from "./mobile/duoTrainingMobile"
import axios from "axios";


export default {
  components: {
    subHeader,
    duoTrainingTile,
    duoTrainingMobile
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getList();
    if (this.$store.state.principal.administrator == false) {
      this.listCompletedModule();
    }
  },
  data() {
    return {
      retakeModule: false,
      dialogRetake: false,
      moduleId: 0,
      moduleStatus: "",
      finalScore: 0,
      dialogModuleDisable: false,
      tileList: [],
      completedModuleList: [],
      choosenTitle: "",
    };
  },
  methods: {
    getList() {
            axios
                .get("/system/tile/training")
                .then((response) => {
                    this.tileList = response.data.filter(function (el) {
                        return el.sequence;
                    })
                })
                .catch(() => { });
    },
    listCompletedModule() {
      axios
        .get("/system/score/completed")
        .then((response) => {
          this.completedModuleList = response.data;
        }).catch(() => { });
    },
    onStartTraining(id, title) {
      this.choosenTitle = title;
      this.moduleId = id;
      // axios.get('/system/trainingModule/list').then((response) => {
      axios.get('/system/trainingModule/list/all').then((response) => {
        const res = response.data.find((v) => Number(v.id) === Number(id))
        if (res.active) {
          this.checkPersonScore(this.choosenTitle);
        } else {
          this.dialogModuleDisable = true;
        }
      });
    },
    handleRetakeModule() {
      this.$store.state.retakeTrainingModule = true;
      this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
    },
    handleReviewModule() {
      this.$store.state.retakeTrainingModule = false;
      this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
    },
    checkPersonScore() {
      axios
        .get("/system/score/answer?moduleId=" + this.moduleId)
        .then((response) => {
          if (response.data != null) {
            this.finalScore = response.data.finalScore;
            this.moduleStatus = this.getModuleStatus(response.data.moduleId);
            this.dialogRetake = true;
          } else {
            this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
          }
        })
        .catch(() => { });
    },
    getModuleStatus(moduleId) {
      let result = this.completedModuleList.find(score => score.moduleId == moduleId);
      if (result != null) {
        return result.transitMap.scoreStatus;
      } else {
        return null;
      }
    },
    closeDialogModule() {
      this.dialogModuleDisable = false;
    }
  },
};
</script>

<style scoped>
.btn-dashboard {
  width: 192px !important;
  height: 50px !important;
  border: 1px solid #2d2926 !important;
  background-color: #2d2926 !important;
  color: white !important;
  border-radius: 4px;
  opacity: 1;
  letter-spacing: 0px;
}

.btn-dashboard-inverse {
  width: 192px !important;
  height: 50px !important;
  border: 1px solid white !important;
  background-color: white !important;
  color: #2d2926 !important;
  border-radius: 4px;
  opacity: 1;
  letter-spacing: 0px;
}
</style>
