<template>
    <v-main style="margin: 10px;">
        <div v-if="showEditor" :style="{ maxHeight: editorHeight, overflowY: 'auto' }">
            <div style="{
                position: absolute;
                z-index: 9;
                padding: 5px 10px;
            }">
        <v-card color="#00b0b9" v-if="isImpersonate == true" class="mt-2 px-2 py-1 d-flex align-center">
                Impersonated login with &nbsp;
                <b>{{ $route.query.email  }}</b> &nbsp;|&nbsp;
                {{  ($route.query.agency ? 'Account : ' + $route.query.agency : '') }} &nbsp;|&nbsp;
                {{ ($route.query.agencyGroup ? 'Parent account: ' + $route.query.agencyGroup : '') }} &nbsp;|&nbsp;
                {{ ($route.query.date ? 'Log in date: ' + $route.query.date : '')  }}
            </v-card>
        </div>
            <v-container fluid style="max-width: 1296px;">
                <draggable class="row" v-model="previewList" @start="drag = true" @end="drag = false; onChangeDrag()">
                    <duo-training-tile @onClickEdit="showEditDialog(item)" :map="item.map"
                        @onClickHide="showDialogConfirmation('unpublish', item.id)" :is-preview="isImpersonate == true ? 1 : 2"
                        v-for="item in previewList" :img="item.image" :title="item.title" :content="item.content"
                        :size="item.size" :key="item.id" :btn-label="item.buttonLabel" />
                </draggable>
            </v-container>
        </div>
        <v-card color="transparent" class="mt-2 py-1 d-flex align-center"><v-btn v-if="isImpersonate == false" class="mx-2" @click="dialogLibrary = true"
                color="duo-primary">Tile Library</v-btn>
            <v-btn v-if="isImpersonate == false" @click="dialogImpersonate = true" color="duo-primary">Impersonate</v-btn>
            <v-btn v-if="isImpersonate == true" @click="$router.push('/administration/creator/tile/training')" color="duo-secondary">Exit Impersonate</v-btn>
            <v-btn v-if="isImpersonate == false" class="ml-2" @click="$router.push( '/administration/creator/tile/training?date=' + new Date().toISOString().slice(0, 10))" color="duo-primary">Visible to All</v-btn>
            <v-spacer />
            <v-btn @click="resetLayout" color="duo-secondary">Reset Layout</v-btn>
            <v-btn class="mx-2" :disabled="previewListOld == previewListCurrent" @click="arrangeTiles"
                color="duo-primary">Publish
                Layout</v-btn></v-card>


        <v-dialog v-model="dialogLibrary">
            <v-card>
                <v-card-title>
                    <h2>Training Creator</h2>
                </v-card-title>
                <v-card-text style="max-height: 70vh; overflow-y: auto;">
                    <v-data-table :headers="headers" :items="creatorTileList" :items-per-page="15">
                        <template v-slot:item.title="{ item }">
                            <div v-html="$root.ellipsifyHtml(item.title, 100)"/>
                        </template>
                        <template v-slot:item.content="{ item }">
                            <div v-html="$root.ellipsifyHtml(item.content, 100)"/>
                        </template>
                        <template v-slot:item.link="{ item }">
                            <div>{{ $root.ellipsify(item.link, 100) }}</div>
                        </template>
                        <template v-slot:item.image="{ item }">
                            <div>{{ $root.ellipsify(item.image, 100) }}</div>
                        </template>
                        <template v-slot:item.active="{ item }">
                            <div class="d-flex flex-row">
                                <v-btn @click="showDialogConfirmation('publish', item.id)" prepend-icon="mdi-plus"
                                    v-if="!item.sequence" small dark color="secondary" title="Add">
                                    Publish
                                </v-btn>
                                <v-icon v-else>mdi-check</v-icon>
                            </div>
                        </template>
                        <template v-slot:item.domainList="{ item }">
                            <div class="d-flex flex-row">
                                {{ (item.map.allFilter && item.map.allFilter == true) || (!item.map.domainFilter || item.map.domainFilter == false) ? '' : item.map.domainList }}
                            </div>
                        </template>
                        <template v-slot:item.agencyGroupList="{ item }">
                            <div class="d-flex flex-row">
                                {{ (item.map.allFilter && item.map.allFilter == true) || (!item.map.agencyGroupFilter || item.map.agencyGroupFilter == false) ? '' : item.map.agencyGroupList }}
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <div class="d-flex flex-row">
                                <v-icon class="ml-2" @click="showEditDialog(item)">mdi-pencil</v-icon>
                                <v-icon class="ml-2"
                                    @click="showDialogConfirmation('delete', item.id)">mdi-trash-can</v-icon>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-end">
                    <div class="d-flex px-2">
                        <v-btn @click="showAddDialog" color="duo-primary" class="mx-2">New Tile</v-btn>
                        <v-btn @click="dialogLibrary = false" color="duo-secondary">Cancel</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog fullscreen persistent v-model="dialogUpdate">
            <v-card>
                <v-card-title><v-row><v-col></v-col><v-col cols="auto"><v-icon
                                @click="closeDialog">mdi-close</v-icon></v-col></v-row></v-card-title>
                <div style="max-width: 1296px; margin: 20px auto 20px auto;">
                    <v-row>
                        <v-col cols="12" style="margin-top: 10px">
                            <v-card class="py-8 px-12">
                                <v-form ref="form">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-autocomplete v-model="entity.link" :items="trainingModuleList"
                                                :rules="[(v) => !!v || 'Enter Target Module']" item-text="name"
                                                item-value="id" label="Target Module*"
                                                :class="{ 'line-blue': entity.link != null }"
                                                class="duo-description rounded-0" append-icon="mdi-chevron-down">
                                                <template v-slot:item="{ item }">
                                                    <span class="duo-description">{{ item.name }}</span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select v-model="entity.size" :items="[1, 2, 3]" label="Size" @change="refresh"
                                                class="duo-description rounded-0" append-icon="mdi-chevron-down">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu ref="startDateEdit" v-model="isShowEditStartDate"
                                                :close-on-content-click="false" :return-value.sync="entity.transitMap.startDate
                                                    " transition="scale-transition" offset-y max-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="entity.transitMap.startDate
                                                        " label="Start Date" :rules="[
        (v) =>
            !!v ||
            'Enter Start Date',
    ]" prepend-icon="mdi-calendar-clock" v-bind="attrs" v-on="on" dense autocomplete="off" @click="resetTime" />
                                                </template>
                                                <v-date-picker :min="new Date()
                                                    .toISOString()
                                                    .substr(0, 10)
                                                    " v-model="entity.transitMap.startDate
        " @input="
        entity.transitMap.startDate = changeDatetime(
            entity.transitMap.startDate
        )
        " color="duo-primary" no-title>
                                                    <div class="d-flex flex-column" style="width: 100%">
                                                        <div class="d-flex flex-row align-space-arround">
                                                            <span>Time</span>
                                                            <v-select v-model="hour" :items="Array(24)
                                                                .fill()
                                                                .map((x, i) =>
                                                                    (
                                                                        '' + i
                                                                    ).padStart(
                                                                        2,
                                                                        '0'
                                                                    )
                                                                )
                                                                " class="mx-2" style="width: 10px" dense
                                                                append-icon="false" @input="
                                                                    entity.transitMap.startDate = changeDatetime(
                                                                        entity
                                                                            .transitMap
                                                                            .startDate
                                                                    )
                                                                    ">
                                                                <template v-slot:selection="{
                                                                    item,
                                                                }">
                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                        style="width: 100%">{{ item }}</span>
                                                                </template> </v-select>:
                                                            <v-select v-model="minute" :items="Array(60)
                                                                .fill()
                                                                .map((x, i) =>
                                                                    (
                                                                        '' + i
                                                                    ).padStart(
                                                                        2,
                                                                        '0'
                                                                    )
                                                                )
                                                                " class="mx-2" style="width: 10px" dense
                                                                append-icon="false" @input="
                                                                    entity.transitMap.startDate = changeDatetime(
                                                                        entity
                                                                            .transitMap
                                                                            .startDate
                                                                    )
                                                                    ">
                                                                <template v-slot:selection="{
                                                                    item,
                                                                }">
                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                        style="width: 100%">{{ item }}</span>
                                                                </template> </v-select>:
                                                            <v-select v-model="second" :items="Array(60)
                                                                .fill()
                                                                .map((x, i) =>
                                                                    (
                                                                        '' + i
                                                                    ).padStart(
                                                                        2,
                                                                        '0'
                                                                    )
                                                                )
                                                                " class="mx-2" style="width: 10px" dense
                                                                append-icon="false" @input="
                                                                    entity.transitMap.startDate = changeDatetime(
                                                                        entity
                                                                            .transitMap
                                                                            .startDate
                                                                    )
                                                                    ">
                                                                <template v-slot:selection="{
                                                                    item,
                                                                }">
                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                        style="width: 100%">{{ item }}</span>
                                                                </template>
                                                            </v-select>
                                                        </div>
                                                        <v-divider></v-divider>
                                                        <div class="d-flex justify-end pa-1">
                                                            <v-btn small width="20px" class="mr-2" color="duo-secondary"
                                                                @click="
                                                                    isShowEditStartDate = false
                                                                    ">Cancel</v-btn>
                                                            <v-btn small width="20px" color="duo-primary" @click="
                                                                $refs.startDateEdit.save(
                                                                    (entity.transitMap.startDate = changeDatetime(
                                                                        entity
                                                                            .transitMap
                                                                            .startDate
                                                                    ))
                                                                )
                                                                ">OK</v-btn>
                                                        </div>
                                                    </div>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu ref="endDateEdit" v-model="isShowEditEndDate"
                                                :close-on-content-click="false" :return-value.sync="entity.transitMap.endDate
                                                    " transition="scale-transition" offset-y max-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="entity.transitMap.endDate
                                                        " label="End Date" prepend-icon="mdi-calendar-clock"
                                                        v-bind="attrs" v-on="on" dense autocomplete="off"
                                                        @click="resetTime" />
                                                </template>
                                                <v-date-picker :min="entity.transitMap.startDate && new Date().toISOString().substr(0, 10) < entity.transitMap.startDate ? entity.transitMap.startDate :
                                                    new Date().toISOString().substr(0, 10)
                                                    " v-model="entity.transitMap.endDate" @input="
        entity.transitMap.endDate = changeDatetime(
            entity.transitMap.endDate
        )
        " color="duo-primary" no-title>
                                                    <div class="d-flex flex-column" style="width: 100%">
                                                        <div class="d-flex flex-row align-space-arround">
                                                            <span>Time</span>
                                                            <v-select v-model="hour" :items="Array(24)
                                                                .fill()
                                                                .map((x, i) =>
                                                                    (
                                                                        '' + i
                                                                    ).padStart(
                                                                        2,
                                                                        '0'
                                                                    )
                                                                )
                                                                " class="mx-2" style="width: 10px" dense
                                                                append-icon="false" @input="
                                                                    entity.transitMap.endDate = changeDatetime(
                                                                        entity
                                                                            .transitMap
                                                                            .endDate
                                                                    )
                                                                    ">
                                                                <template v-slot:selection="{
                                                                    item,
                                                                }">
                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                        style="width: 100%">{{ item }}</span>
                                                                </template> </v-select>:
                                                            <v-select v-model="minute" :items="Array(60)
                                                                .fill()
                                                                .map((x, i) =>
                                                                    (
                                                                        '' + i
                                                                    ).padStart(
                                                                        2,
                                                                        '0'
                                                                    )
                                                                )
                                                                " class="mx-2" style="width: 10px" dense
                                                                append-icon="false" @input="
                                                                    entity.transitMap.endDate = changeDatetime(
                                                                        entity
                                                                            .transitMap
                                                                            .endDate
                                                                    )
                                                                    ">
                                                                <template v-slot:selection="{
                                                                    item,
                                                                }">
                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                        style="width: 100%">{{ item }}</span>
                                                                </template> </v-select>:
                                                            <v-select v-model="second" :items="Array(60)
                                                                .fill()
                                                                .map((x, i) =>
                                                                    (
                                                                        '' + i
                                                                    ).padStart(
                                                                        2,
                                                                        '0'
                                                                    )
                                                                )
                                                                " class="mx-2" style="width: 10px" dense
                                                                append-icon="false" @input="
                                                                    entity.transitMap.endDate = changeDatetime(
                                                                        entity
                                                                            .transitMap
                                                                            .endDate
                                                                    )
                                                                    ">
                                                                <template v-slot:selection="{
                                                                    item,
                                                                }">
                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                        style="width: 100%">{{ item }}</span>
                                                                </template>
                                                            </v-select>
                                                        </div>
                                                        <v-divider></v-divider>
                                                        <div class="d-flex justify-end pa-1">
                                                            <v-btn small width="20px" class="mr-2" color="duo-secondary"
                                                                @click="
                                                                    isShowEditEndDate = false
                                                                    ">Cancel</v-btn>
                                                            <v-btn small width="20px" color="duo-primary" @click="
                                                                $refs.endDateEdit.save(
                                                                    (entity.transitMap.endDate = changeDatetime(
                                                                        entity
                                                                            .transitMap
                                                                            .endDate
                                                                    ))
                                                                )
                                                                ">OK</v-btn>
                                                        </div>
                                                    </div>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-checkbox id="allFilter" v-model="entity.map.allFilter" class="mt-0 pt-0">
                                                <template v-slot:label>
                                                    <span @change="onVisibleChange()"
                                                        style="font-size: 14px; color: #2d2926 !important">Visible to
                                                        all</span>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-checkbox :disabled="entity.map.allFilter == true" id="domainFilter"
                                                v-model="entity.map.domainFilter" class="mt-0">
                                                <template v-slot:label>
                                                    <span style="font-size: 14px; color: #2d2926 !important"><b>Only</b>
                                                        visible
                                                        to
                                                        users with domain ending</span>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field :disabled="entity.map.domainFilter == false" class="mt-0 pt-0"
                                                v-model="entity.map.domainList" label="Domain List" />
                                            <sub>Tip: use comma to separate between lists, example: @airnz.co.nz, @mytravel.com</sub>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-checkbox :disabled="entity.map.allFilter == true" id="agencyGroupFilter"
                                                v-model="entity.map.agencyGroupFilter" class="mt-0 pt-0">
                                                <template v-slot:label>
                                                    <span style="font-size: 14px; color: #2d2926 !important"><b>Only</b>
                                                        visible
                                                        to
                                                        account / parent account that starts with</span>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field :disabled="entity.map.agencyGroupFilter == false"
                                                class="mt-0 pt-0" v-model="entity.map.agencyGroupList"
                                                label="Account / Parent Account List" />
                                            <sub>Tip: use comma to separate between lists, example: MTA, Travel Managers, Express</sub>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row v-if="entity.size > 1" class="d-flex align-center" no-gutters>
                                                <v-col cols="auto"> <v-btn color="duoQuaternary" class="mb-0 white--text"
                                                        @click="openImageLibraryDialog">Images Library</v-btn></v-col>
                                                <v-col>
                                                    <v-text-field v-if="attachmentLabel.length > 0" v-model="attachmentLabel" placeholder="Upload File"
                                                        prepend-icon="mdi-file" @click="openFileDialog()"
                                                        readonly></v-text-field>
                                                    <v-text-field v-else v-model="entity.image" placeholder="Upload File"
                                                        prepend-icon="mdi-file" @click="openFileDialog()"
                                                        readonly></v-text-field>
                                                    <input type="file" ref="attachment"
                                                        accept="image/png, image/jpeg, image/jpg" style="display: none;"
                                                        @change="showImage" required />
                                                </v-col>
                                                <v-col cols="auto"> <v-icon color="duoQuaternary" class="mb-0 white--text"
                                                        @click="delete entity.image; attachment = null; attachmentLabel = ''; refresh();">mdi-trash-can</v-icon></v-col>
                                            </v-row>
                                            <sub v-if="size > 1">Reccomended image size: <b>{{ entity.size == 2 ? '420 x 376 px' :
                                                '648 x 376 px' }}</b></sub>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <duo-training-tile :map="entity.map" :title="entity.title" :content="entity.content"
                            :img="entity.image" :size="entity.size" :btn-label="entity.buttonLabel" :is-preview="1" />
                        <v-col v-if="!entity.imageOnly" cols="12" :md="entity.size >= 2 ? 12 : 8">
                            <v-card class="d-flex flex-column align-content-center" min-height="155">
                                <v-container class="align-center pa-0 justify-center" style="margin-top: 30px">
                                    <v-row class="d-flex flex-wrap">
                                        <v-col cols="12">
                                            <v-tabs v-model="tab" color="white" height="50" background-color="#424242"
                                                slider-color="#90CAF9" slider-size="4" dark>
                                                <v-tab v-for="(tabItem, tabItemIndex) in tabItemList" :key="tabItemIndex"
                                                    class="px-12" style="background-color: #424242">
                                                    <div class="text-h6 text-capitalize">
                                                        {{ tabItem }}
                                                    </div>
                                                </v-tab>
                                            </v-tabs>

                                            <v-tabs-items v-model="tab">
                                                <v-tab-item class="my-3 mx-12">
                                                    <!-- <v-col cols="4"><v-select v-if="entity.size == 1" solo
                                                            v-model="entity.map.headingTheme" :items="['light', 'dark']"
                                                            label="Title Color" class="duo-description rounded-0"
                                                            append-icon="mdi-chevron-down" /></v-col> -->
                                                    <editor v-if="loaded == true" v-model="entity.title"
                                                        :api-key="$tinyMceKey"
                                                        :init="initEditorHead" />
                                                </v-tab-item>
                                                <v-tab-item class="my-3 mx-12">
                                                    <v-col cols="4"><v-select outlined dense
                                                            v-model="entity.map.contentTheme" :items="['light', 'dark']"
                                                            label="Tile Theme" class="duo-description rounded-0"
                                                            append-icon="mdi-chevron-down" /></v-col>
                                                    <editor v-if="loaded == true" v-model="entity.content"
                                                        :api-key="$tinyMceKey"
                                                        :init="initEditor" />
                                                </v-tab-item>
                                                <v-tab-item class="my-3 mx-12">
                                                    <v-textarea solo v-model="entity.buttonLabel" />
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <v-card-actions class="d-flex align-center justify-end">
                    <v-btn @click="save" color="duo-primary">Save</v-btn>
                    <v-btn @click="showPreviewTileDialog(entity)" color="duo-secondary">Preview</v-btn>
                    <v-btn @click="closeDialog" color="duo-secondary">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <confirmation-dialog :show="dialogConfirmation.show" @onClickConfirm="execDialog"
            @onCloseDialog="closeDialogConfirmation" :title="'Confirm ' + dialogConfirmation.action"
            :content="'Are you sure you want to <b>' + dialogConfirmation.action + '</b> selected tile'" />
        <dialog-images-picker :show="dialogImageLibrary.show" :list="dialogImageLibrary.list" @onClickImage="onSelectImage"
            @onCloseDialog="dialogImageLibrary.show = false" />
        <dialog-impersonate-creator :show="dialogImpersonate" @onCloseDialog="dialogImpersonate = false" />
    </v-main>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";
import editor from "@tinymce/tinymce-vue";
import duoTrainingTile from "./creator/duoTrainingTile.vue";
import confirmationDialog from "./dialog/confirmationDialog.vue";
import draggable from 'vuedraggable';
import dialogImagesPicker from "./dialog/dialogImagesPicker.vue"
import dialogImpersonateCreator from "./dialog/dialogImpersonateCreator.vue";

export default {
    components: {
        editor,
        duoTrainingTile,
        confirmationDialog,
        draggable,
        dialogImagesPicker,
        dialogImpersonateCreator,
    },
    data() {
        return {
            isImpersonate: false,
            dialogImpersonate: false,
            loaded: false,
            dialogImageLibrary: {
                show: false,
                target: '',
                list: [],
            },
            showEditor: false,
            editorHeight: '840px',
            headers: [
                { text: "", value: "action", sortable: false },
                { text: "Title", value: "title" },
                { text: "Content", value: "content" },
                { text: "Image", value: "image" },
                { text: "Published", value: "active" },
                { text: "Start Date", value: "transitMap.startDate" },
                { text: "End Date", value: "transitMap.endDate" },
                { text: "Domain Filter", value: "domainList" },
                { text: "Parent Account Filter", value: "agencyGroupList" },
                { text: "Link", value: "link", width: "100px" },
            ],
            creatorTileList: [],
            isShowEditStartDate: false,
            isShowEditEndDate: false,
            hour: "00",
            minute: "00",
            second: "00",
            tab: 0,
            tabItemList: ["header", "body", "button"],
            dialogLibrary: false,
            dialogUpdate: false,
            previewList: [],
            trainingModuleList: [],
            previewListOld: "",
            previewListCurrent: "",
            selectedId: null,
            entity: {
                type: 'Training',
                linkType: 'Training_module',
                size: 1,
                map: {
                    allFilter: false,
                    domainFilter: false,
                    agencyGroupFilter: false,
                    contentTheme: "dark"
                },
                transitMap: {
                    dateStart: null,
                    dateEnd: null,
                },
                buttonLabel: "Find out more",
            },
            attachment: null,
            attachmentLabel: "",
            imgError: "",
            editor: {
                0: "",
                1: "",
                2: ""
            },
            initEditorHead: {
                height: 400,
                selector: "",
                menubar: false,
                plugins: ["paste"],
                paste_as_text: true,
                toolbar:
                    "undo redo | bold italic underline | help",
            },
            initEditor: {
                height: 400,
                selector: "",
                menubar: false,
                plugins: ["paste"],
                paste_as_text: true,
                fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px",
                toolbar:
                    "undo redo | formatselect fontsizeselect | bold italic underline | help",
                content_style: 'body{ font-size:17px; }',
            },
            dialogConfirmation: {
                action: 'publish',
                show: false,
                id: null
            }
        }
    },
    mounted() {
        this.editorHeight = window.innerHeight > 1439 ? '92vh' : window.innerHeight > 930 ? '88vh' : '85vh';
        this.showEditor = true;
        this.getList();
        this.listTrainingModule();
        this.getImageLib();
    },
    methods: {
        getImageLib() {
            axios.get("/system/creator/tile/library/list?type=training")
                .then((response) => {
                    this.dialogImageLibrary.list = response.data;
                });
        },
        openImageLibraryDialog() {
            this.dialogImageLibrary.show = true;
        },
        onSelectImage(item) {
            this.entity.image = this.$bucket + '/' + item;
            this.dialogImageLibrary.show = false;
        },
        getList() {
            let url = "/system/creator/tile/list";

            let requestObj = {type: 'Training'}

            if (Object.keys(this.$route.query).length > 0) {
                this.isImpersonate = true;
                for (const key in this.$route.query) {
                    if(key.length > 1) {
                        requestObj[key] = this.$route.query[key];
                    }
                }
            }

            axios
                .post(url, requestObj)
                .then((response) => {
                    this.creatorTileList = response.data;
                    this.previewList = response.data.filter(function (el) {
                        return el.sequence;
                    }).sort((a, b) => {
                        return a.sequence - b.sequence;
                    });

                    this.previewListOld = this.previewList.map(function (item) {
                        return item['id'];
                    }).toString();
                    this.previewListCurrent = this.previewListOld;
                })
                .catch(() => { });
        },
        impoersonate() {
            axios
                .get("/system/creator/tile/list?type=Training")
                .then((response) => {
                    this.creatorTileList = response.data;
                    this.previewList = response.data.filter(function (el) {
                        return el.sequence;
                    })

                    this.previewListOld = this.previewList.map(function (item) {
                        return item['id'];
                    }).toString();
                    this.previewListCurrent = this.previewListOld;
                })
                .catch(() => { });
        },
        listTrainingModule() {
            axios
                .get("/api/trainingModule/list/all")
                .then(response => {
                    this.trainingModuleList = response.data.filter(v => v.active);
                })
                .catch(() => { });
        },
        previewLink(linkType, link) {
            if (linkType == 'Url') {
                window.open(link, "_blank");
            }
        },
        resetTime() {
            this.hour = "00";
            this.minute = "00";
            this.second = "00";
        },
        changeDatetime(dateTime) {
            if (dateTime === undefined || dateTime === "") {
                return new Date().toISOString().substr(0, 10) + " " + this.hour + ":" + this.minute + ":" + this.second;
            } else {
                return dateTime.substr(0, 10) + " " + this.hour + ":" + this.minute + ":" + this.second;
            }
        },
        onChangeDrag() {
            this.previewListCurrent = this.previewList.map(function (item) {
                return item['id'];
            }).toString();
        },
        arrangeTiles() {
            let requestObj = { tileList: this.previewListCurrent };
            if (this.isImpersonate == true) {
                requestObj.type = "Training";
            }
            
            axios.post("/system/creator/tile/arrange", requestObj).then((response) => {
                if (response.status == 200) {
                    this.getList();
                }
            });
        },
        resetLayout() {
            this.previewList = this.creatorTileList.filter(function (el) {
                return el.sequence;
            });
            this.previewListCurrent = this.previewListOld;
        },
        publishLayout() {
            this.previewList = this.creatorTileList.filter(function (el) {
                return el.sequence;
            })
        },
        addPreview(item) {
            this.previewList.push(item);
        },
        onVisibleChange() {
            if (this.entity.map.allFilter == true) {
                this.entity.map.domainFilter = false;
                this.entity.map.agencyGroupFilter = false;
            }
        },
        showAddDialog() {
            this.loaded = false;
            this.entity = {
                type: 'Training',
                linkType: 'Training_module',
                size: 1,
                map: {
                    allFilter: false,
                    domainFilter: false,
                    agencyGroupFilter: false,
                    contentTheme: "dark"
                },
                transitMap: {
                    dateStart: null,
                    dateEnd: null,
                },
                buttonLabel: "Find out more",
            };

            this.dialogUpdate = true;
            this.$nextTick(() => {
                this.loaded = true;
            });
        },
        showEditDialog(item) {
            this.loaded = false;
            item.link = Number(item.link);
            this.entity = { ...item };
            this.dialogUpdate = true;
            this.$nextTick(() => {
                this.loaded = true;
            });
        },
        openFileDialog() {
            this.$refs.attachment.click();
        },
        refresh() {
            this.$forceUpdate();
        },
        showImage(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {
                if (files[0].size > 2097152) {
                    this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                    return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                    return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                    this.entity.image = fr.result;
                    this.attachment = files[0]; // this is an image file that can be sent to server...
                    this.attachmentLabel= files[0].name,
                    this.imgError = false;
                });
            } else {
                this.attachment = null;
                this.attachmentLabel = '';
            }
        },
        save() {
            if (this.$refs.form.validate()) {
                let url = '/system/creator/tile/add';

                this.entity.link = String(this.entity.link);

                if (this.entity.id) {
                    url = '/system/creator/tile/edit';
                    this.entity.sequence = this.previewList.length + 1;
                }

                // clean preference
                if (this.entity.map.agencyGroupFilter == false &&
                    this.entity.map.domainFilter == false) {
                        this.entity.map.allFilter = true;
                } else if (this.entity.map.allFilter == true) {
                    this.entity.map.agencyGroupFilter = false;
                    this.entity.map.domainFilter = false;
                } else {
                    this.entity.map.agencyGroupList = this.$root.trimSpaces(this.entity.map.agencyGroupList);
                    this.entity.map.domainList = this.$root.trimSpaces(this.entity.map.domainList);
                }
                // end clean preference

                let formData = new FormData();
                formData.append("entity", JSON.stringify(this.entity));
                if (this.attachment) {
                    formData.append("attachment", this.attachment);
                }

                axios.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then(
                    (response) => {
                        if (response.status == 200) {
                            this.getList();
                            this.closeDialog();
                        }
                    }
                )
            }
        },
        closeDialog() {
            this.attachment = null;
            this.attachmentLabel = "";

            this.loaded = false;
            this.dialogUpdate = false;
            this.entity = {
                type: 'Training',
                linkType: 'Training_module',
                size: 1,
                map: {
                    allFilter: false,
                    domainFilter: false,
                    agencyGroupFilter: false,
                },
                transitMap: {
                    dateStart: null,
                    dateEnd: null,
                },
                buttonLabel: "Find out more",
            }
            this.$nextTick(() => {
                this.loaded = true;
            });
        },
        showDialogConfirmation(action, id) {
            this.dialogConfirmation.action = action;
            this.dialogConfirmation.id = id;
            this.dialogConfirmation.show = true;
        },
        closeDialogConfirmation() {
            this.dialogConfirmation.show = false;
            this.dialogConfirmation.id = null;
        },
        execDialog() {
            let url = "";

            let payload = { id: this.dialogConfirmation.id }

            if (this.dialogConfirmation.action == 'unpublish') {
                url = "/system/creator/tile/unpublish"
            } else if (this.dialogConfirmation.action == 'publish') {
                url = "/system/creator/tile/publish"
                payload.sequence = (this.previewList.length > 0 ? Math.max(...this.previewList.map(o => o.sequence)) : 0) + 1;
            } else if (this.dialogConfirmation.action == 'delete') {
                url = "/system/creator/tile/delete"
            }



            if (url.length > 0) {
                axios.post(url, payload).then(
                    () => {
                        this.getList();

                        this.closeDialogConfirmation();
                    }
                )
            }
        },
        removePreview(item) {
            var index = this.previewList.indexOf(item);
            if (index !== -1) {
                this.previewList.splice(index, 1);
            }
        },
        showPreviewTileDialog(entity) {
			this.closeDialog();
			this.$router.push({ path: `/administration/creator/tile/training/${entity.id}`, query: entity })
        },
    }
}
</script>