<template>
	<div style="margin: 11.69px 0px 25px 0px" :style="dynamicStyle">
		<!-- Profile -->
		<div v-if="this.$router.currentRoute.path.startsWith('/profile/personal')" class="subHeader-profile d-flex flex-row flex-wrap justify-start">
			<div>
				<v-btn to="/profile/personal" text tile>
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; text-transform: none"> <b>Personal</b>details </v-row>
				</v-btn>
			</div>
		</div>
		<div v-else-if="this.$router.currentRoute.path.startsWith('/profile/company')" class="subHeader-profile d-flex flex-row flex-wrap justify-start">
			<div>
				<v-btn to="/profile/company" text tile>
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; text-transform: none"> <b>Company</b>details </v-row>
				</v-btn>
			</div>
		</div>
		<div v-else-if="this.$router.currentRoute.path.startsWith('/profile/accountsetting')" class="subHeader-profile d-flex flex-row flex-wrap justify-start">
			<div>
				<v-btn to="/profile/accountsetting" text tile>
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; text-transform: none"> <b>Account</b>settings </v-row>
				</v-btn>
			</div>
		</div>
		<!--  -->
		<div v-else-if="this.$router.currentRoute.path == '/profile' || $router.currentRoute.path == '/agency'" class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn to="/profile" text tile style="margin-right: 10px">
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; text-transform: none"> <b>duo</b>profile </v-row>
				</v-btn>
				<v-btn href="https://www.airnzagent.com.au/agency-sales-support/" target="_blank" text tile>
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; text-transform: none"> <b>Agency</b>&nbsp;Sales&nbsp;Support </v-row>
				</v-btn>
			</div>
		</div>
		<div v-else-if="$router.currentRoute.path == '/bdm'" class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn to="/bdm" text tile>
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; text-transform: none"> <b>BDM</b>&nbsp;profiles </v-row>
				</v-btn>
			</div>
		</div>
		<!--  -->
		<div v-else-if="$router.currentRoute.path == '/sales'" class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn to="/sales" text tile>
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; text-transform: none"> <b style="">duo</b>sales </v-row>
				</v-btn>
			</div>
		</div>
		<!--  -->
		<div v-else-if="$router.currentRoute.path.startsWith('/training')" class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn to="/training" text tile>
					<v-row no-gutters justify="center" class="top-navigation-logged-in px-4" style="letter-spacing: 0; text-transform: none"> <b>duo</b>training </v-row>
				</v-btn>
			</div>
		</div>
		<div v-else-if="$router.currentRoute.path == '/discoverNorthAmerica'" class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn to="/discoverNorthAmerica" text tile>
					<span class="top-navigation-logged-in px-4">
						<b style="letter-spacing: 0; text-transform: none;">Discover</b> North America
					</span>
				</v-btn>
			</div>
		</div>
		<div v-else-if="$router.currentRoute.path == '/seeMoreNZ'" class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn to="/seeMoreNZ" text tile>
					<span class="top-navigation-logged-in px-4">
						<b style="letter-spacing: 0; text-transform: none;">See more</b> of NZ
					</span>
				</v-btn>
			</div>
		</div>
		<div v-else-if="$router.currentRoute.path == '/destinationDash'" class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn to="/destinationDash" text tile>
					<span class="top-navigation-logged-in px-4">
						<b style="letter-spacing: 0; text-transform: none;">NZ</b> Destination Dash
					</span>
				</v-btn>
			</div>
		</div>
		<!--  -->
		<div v-else-if="$router.currentRoute.path == '/faqs'" class="subHeader-profile d-flex flex-row flex-wrap justify-start text-left">
			<div>
				<v-btn to="/faqs" text tile>
					<span class="top-navigation-logged-in" style="color: white"> Agency support<b style=""> FAQs</b> </span>
				</v-btn>
			</div>
		</div>
		<v-divider :class="$router.currentRoute.path == '/faqs' ? 'duo-subheader-invert' : 'duo-subheader'"></v-divider>
	</div>
</template>
<style scoped>
.top-navigation-logged-in {
	font-size: 12px;
}
.v-btn:not(.v-btn--round).v-size--default {
	height: 25px;
	min-width: 64px;
	padding: 0 0px !important;
}
.duo-subheader.v-divider {
	margin-bottom: 20px;
	border: var(--border);
	border-color: #2d2926;
}
.duo-subheader-invert.v-divider {
	margin-bottom: 20px;
	border: var(--border);
	border-color: #ffffff;
}
.v-btn {
	text-transform: capitalize;
	padding: 0 0 16px 0 !important;
	margin-right: 80px;
	display: inline-block !important;
	justify-content: left !important;
}
.v-btn:before {
	background-color: transparent !important;
}
.v-btn--active {
	border-bottom: 5px solid #2d2926;
	background-color: none !important;
}
a:hover {
	color: #2d2926 !important;
	background-color: none !important;
	text-decoration: none;
}
.v-btn--active {
	border-bottom: var(--border-bottom);
	background-color: none !important;
}
.v-btn:after {
	border-bottom: var(--border-bottom) !important;
	background-color: none !important;
}
</style>

<script>
export default {
	computed: {
		dynamicStyle() {
			return {
				"--border": "0.7px solid " + (this.$router.currentRoute.path == "/faqs" ? "white" : "#2D2926"),
				"--border-bottom": "3px solid " + (this.$router.currentRoute.path == "/faqs" ? "white" : "#2D2926")
			};
		}
	}
};
</script>