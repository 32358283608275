<template>
  <v-sheet v-if="show" class="mt-4 duo-sheet" style="max-width: 1271px; padding-right: 20px; padding-left: 20px;">
    <v-row>
      <v-col v-if="!$root.isBlank(dynamicForm.title)">
        <h2>{{ dynamicForm.title }}</h2>
      </v-col>
      <v-col cols="12" v-if="!$root.isBlank(dynamicForm.description)">
        <p>{{ dynamicForm.description }}</p>
      </v-col>
    </v-row>
    <v-row v-if="isShowSubmitted == true && review == false">
      <v-col cols="12">
        <h3>Thank you! your submission has been received.</h3>
      </v-col>
      <v-col cols="12">
        <v-col class="d-flex px-0">
          <v-btn @click="review = true" height="42px" block color="duoSecondary" :elevation="0" dark>Review
            submission</v-btn>
        </v-col>
      </v-col>
    </v-row>
    <div v-else>
      <v-form :ref="'form' + dynamicForm.id">
        <div class="mt-4" v-for="(item, idx) in questionList" :key="idx">
          <v-row v-if="item.type == 'CTA_Button'">
            <v-col
              :class="'d-flex justify-' + (item.map.position == 'right' ? 'end' : item.map.position == 'left' ? 'start' : 'center')"
              cols="12">
              <v-btn @click="onClickCTA(item.map.link)" min-width="320px" height="50px" color="duoSecondary"
                :elevation="0" dark>{{ item.question }}</v-btn>
            </v-col>
          </v-row>
          <v-row v-else-if="item.type == 'TC_Tickbox'">
            <v-col cols="auto" class="pr-0 mr-0">
              <v-checkbox id="hideTitle" v-model="questionList[idx].answer" value="Checked" hide-details
                :rules="[(v) => !!v || 'This field is required']" class="mt-0 pt-0"/>
            </v-col>
            <v-col class="pl-0 ml-0"> <span v-if="item.question" v-html="contentCss + item.question" /></v-col>
            <v-col cols="12">
              <sup style="color:red;" v-if="errorList[idx]">{{errorList[idx]}}</sup>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <span v-html="item.question" />

              <v-text-field v-if="item.type == 'Single_Line'" outlined dense v-model="questionList[idx].answer"
                label="Answer"
                :rules="[(v) => !!v || 'This field is required', (v) => (v && !isWordLimitExceeded(v, idx)) || 'Max '+questionList[idx].map.wordLimit+' words']" 
              />
              <v-textarea 
                v-else-if="item.type == 'Multiple_Line'" 
                outlined 
                dense 
                v-model="questionList[idx].answer"
                label="Answer"
                :rules="[(v) => !!v || 'This field is required', (v) => (v && !isWordLimitExceeded(v, idx)) || 'Max '+questionList[idx].map.wordLimit+' words']" 
              />
              <v-radio-group v-else-if="item.type == 'Single_Choice'" v-model="questionList[idx].answer"
                :rules="questionList[idx].mandatory == true ? [(v) => !!v || 'This field is required'] : []" class="ml-6">
                <div v-for="idxAnswer in (questionList[idx].map.numAnswers ? questionList[idx].map.numAnswers : 4)" :key="idxAnswer" no-gutters>
                  <v-radio :label="item.map['option' + idxAnswer]" :value="item.map['option' + idxAnswer]"></v-radio>
                </div>
              </v-radio-group>
              <div v-else-if="item.type == 'Multiple_Choice'" class="ml-6">
                <div v-for="idxAnswer in (questionList[idx].map.numAnswers ? questionList[idx].map.numAnswers : 4)" :key="idxAnswer" no-gutters>
                  <v-checkbox :id="'option' + idxAnswer" @change="convertCheckbox(idx, item.map['option' + idxAnswer])" v-model="questionList[idx].answer"
                  class="mt-0" :label="item.map['option' + idxAnswer]" :value="item.map['option' + idxAnswer]" />
                </div>
                <sub style="color:red;" v-if="errorList[idx]">{{errorList[idx]}}</sub>
              </div>
              <v-select solo v-else-if="item.type == 'Dropdown_Options'" v-model="item.answer" :items="item.map.answer"
                :rules="questionList[idx].mandatory == true ? [(v) => !!v || 'This field is required'] : []" label="Choose your answer"
                class="duo-description rounded-0" append-icon="mdi-chevron-down">
                <template v-slot:item="{ item }">
                  <span class="duo-description">{{ item }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-form>
      <div v-if="questionList.length > 0">
        <v-row>
          <v-col>
            <v-btn @click="confirmSubmit" height="42px" class="px-12" color="duoSecondary" :elevation="0" dark>Enter
              now</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <confirmation-dialog :title="confirmationDialog.title" :show="confirmationDialog.show"
      :content="confirmationDialog.content" @onCloseDialog="confirmationDialog.show = false" @onClickConfirm="submit" />
  </v-sheet>
</template>
    
<style scoped>
h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>
    
<script>
/* eslint-disable no-console */
import axios from "axios";
import confirmationDialog from '../dialog/confirmationDialog'

export default {
  components: {
    confirmationDialog
  },
  data() {
    return {
      review: false,
      contentCss: '<style scoped>p {margin-bottom: 0 !important;}</style>',
      confirmationDialog: {
        show: false,
        title: 'Confirm submission',
        content: 'Are you sure you want to submit this form?'
      },
      errorList: {}
    }
  },
  props: {
    show: {
      default: true,
    },
    isPreview: {
      default: false,
    },
    isShowSubmitted: {
      default: false,
    },
    dynamicForm: {
      default: {
        id: 0
      }
    },
    questionList: {
      default: []
    },
  },
  computed: {
    isWordLimitExceeded() {
      return (v, idx) => {
        if (this.questionList[idx].map.isWordLimit) {
          const wordCount = v ? v.trim().split(/\s+/).length : 0;
          return wordCount > this.questionList[idx].map.wordLimit;
        }
        return false;
      };
    }
  },
  methods: {
    convertCheckbox(idx, value){
      if (!this.questionList[idx].answer || !Array.isArray(this.questionList[idx].answer)) {
        this.questionList[idx].answer = [value];
      }
    },
    onClickCTA(link) {
      if(link.startsWith('http://') || link.startsWith('https://')) {
        window.location.href = link;
      }
    },
    confirmSubmit() {
      this.errorList = {};
      for(let i = 0; i < this.questionList.length; i++) {
        if (((this.questionList[i].type == 'Multiple_Choice' && this.questionList[i].mandatory == true) || this.questionList[i].type == 'TC_Tickbox') && ( !this.questionList[i].answer || this.questionList[i].answer.length == 0 )) {
          this.errorList[i] = "This field is required";
        }
      }

      if (this.$refs['form' + this.dynamicForm.id].validate() && Object.keys(this.errorList).length == 0) {
        if (this.isPreview == false) {
          this.confirmationDialog.show = true;
        } 
        // else {
        //   alert("Submitted!");
        // }
      }

    },
    submit() {
      for(let i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].answer && Array.isArray(this.questionList[i].answer)) {
          this.questionList[i].answer = JSON.stringify(this.questionList[i].answer);
        }
        
      }

      axios.post("/system/creator/form/answer/submit", { questionList: this.questionList }).then((response) => {
        if (response.status == 200) {
          this.confirmationDialog.show = false;
          alert("Thank you! your submission has been received.");
          this.review = false;
          this.$emit("onFormSubmitted");
        }
      });
    }
  }
};
</script>