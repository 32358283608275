<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1292px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1292px" max-height="353"
            style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-tiles/Homepage.ChristchurchCalling.jpg'" />
        </v-col>
      </v-row>
      <v-row>
        <div class="pa-6" style="max-width: 1138px">
          <h2>
            WIN your way to Christchurch
          </h2>
          <p>
            Keen to be one of 10 lucky agents on an exclusive 5-night famil in stunning Christchurch?
          </p>
          <p>
            You could be exploring Christchurch’s natural beauty, a blend of history, scenic wonders and modern charm….
          </p>

          <p>
            <b>
              <span style="color: #440099;">
                MAJOR PRIZE
              </span> of return Air New Zealand flights to Christchurch, accommodation and activities on a 5 night famil for 10 lucky agents.
            </b>
          </p>

          <p>
            <b>
              Minor Prizes
            </b>
          </p>
          <ul class="ml-6">
            <li>Complete the CHCNZ module in duo and go into the draw to win 5 x $200 gift cards each week.</li>
          </ul>

          <p>
            <b>
              How do you enter?
            </b>
          </p>
          <ol class="ml-6">
            <li>Sell Air New Zealand via your GDS from 15 April - 31 May 2024. The more you sell, the more chances to win, double entries for bookings to New Zealand.</li>
            <li>Complete the Christchurch training module.</li>
          </ol>

          <p>
            <b>
              What else to know?
            </b>
          </p>
          <ul class="ml-6">
            <li>8 spots will be rewarded to 'Top Sellers' during the incentive period.</li>
            <li>2 spots will be selected based on completing the Christchurch training module in duo.</li>
          </ul>

          <p><b>Don’t delay, the incentive ends on 31 May 2024.</b></p>
          <v-btn @click="visit('/training/module/144664/')" id="hero-banner-button" style="font-size: 16px;" class="mb-2 white--text" width="193px" height="50px" color="#440099" :elevation="0" absolute>Enter Now</v-btn>
          <p></p>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="pt-6 pl-3">
            <a style="font-size: 13px; color: black; font-style: italic;" @click="goToTC()"><span class="duo-purple">Terms and Conditions apply.</span></a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 28px;
}

p {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  data() {
    return {
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    visit(url) {
      this.$router.push(url);
    },
    goToTC() {
      const routeData = this.$router.resolve({ path: '/ChristchurchCallingTC' });
      window.open(routeData.href, '_blank');
    },
  }
};
</script>