<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1284px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1284px" max-height="374px"
            style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-tiles/Homepage.Quiz.USAGetaway.jpg'" />
        </v-col>
      </v-row>
      <v-row>
        <div class="pa-6" style="max-width: 1138px">
          <h2>
            WIN your way to the USA on a mystery getaway!
          </h2>
          <p>
            Together with Brand USA, we are giving you the chance to WIN your way to the USA on a mystery famil. There
            are
            <b>8 spots</b> up for grabs, so get ready to set your sights on this epic adventure! Get ready to set your
            signts on this epic adventure!
          </p>
          <p>
            You could be exploring California’s stunning coastlines, experiencing southern charm in Houston, tasting
            culinary delights around Chicago, or possibly wandering the vibrant streets of New York City or San
            Francisco…
          </p>
          <p>
            <b>
              <span style="color: #440099;">
                MAJOR PRIZE
              </span> of return Air New Zealand flights to the USA, accommodation and activities on a 5-night famil,
              departing May 2024, for 8 lucky agents.
            </b>
          </p>
          <p>
            <b>
              PLUS, five weeks of additional prizes up for grabs:
            </b>
          </p>
          <ul>
            <li>5x double passes to Chicago the Musical in Sydney, Adelaide or Melbourne</li>
            <li>10x weekly themed destination prizes</li>
          </ul>
          <p>
            <b>
              How do you enter ?
            </b>
          </p>
          <ol>
            <li>Complete the weekly destination quiz in <a id="test" style="font-weight: bold;"
                @click="visit('/quiz')">duo</a>. 5
              weeks, 5 Air New
              Zealand destinations.
            </li>
            <li>Log a valid Air New Zealand booking in your <b>duo</b> <a @click="visit('/sales')">my<b>sales</b></a>
              table to
              North America,
              between 05 February - 10 March
              <br />2024. The more bookings you enter - the more chances to win!
            </li>
          </ol>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p style="margin: 0 !important;">Each Monday <b>duo</b>news will feature the latest and greatest about each of
            our five USA destinations, but
            it will also provide you with the clues you need to complete the weekly quiz. So keep an eye out on your
            inbox.
          </p>
          <p>You need to complete all 5 weeks and log sales to be eligible to win a spot on our mystery famil.</p>
          <p><b>So, think you've got what it takes? Well, don’t delay, enter now!</b></p>
          <v-btn @click="visit('/quiz')" id="hero-banner-button" style="font-size: 16px;" class="mb-2 white--text"
            width="193px" height="50px" color="#440099" :elevation="0" absolute>Enter Now</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="padding-top: 30px;">
          <p><a style="font-size: 13px; color: grey; font-style: italic;" @click="goToQuizTC('/quizTC')">Terms and
              Conditions apply.</a></p>
        </v-col>
      </v-row>

      <v-row style="padding: 0">
        <v-col class="px-0" cols="12">
          <div>
            <hr style="border-color: gray;" />
          </div>
          <div class="d-inline-flex align-center pt-10" style="margin-bottom: -10px;">
            <h2>The winners are listed here for each week :</h2>
          </div>
        </v-col>
      </v-row>

      <v-row v-for="(value, name, index) in winnerData" :key="value">
        <v-col cols="12" class="px-0">
          <p style="margin:0 0 0 0 ; padding: 0; font-weight: bold;">{{ value.name }} :</p>
          <v-row>
            <v-col cols="3">
              <p style="margin:0px 0 4px 0; padding: 0;">Agent</p>
            </v-col>
            <v-col cols="4">
              <p style="margin:0 ; padding: 0;">Store</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8" class="px-0 py-0" style="margin-top: -10px;">
          <hr style="border-color: gray;" />
        </v-col>
        <v-col cols="4" />
        <v-col cols="3" class="px-0 py-0" style="margin-top: -20px;">
          <ul class="mr-12 pr-6" style="list-style-type: none; padding: 0;"
            v-for="(item) in winnerData[`week${index + 1}`].agent" :key="item">
            <li>
              {{ item }}
            </li>
          </ul>
        </v-col>
        <v-col cols="4" class="px-0 py-0 pb-10" style="margin-top: -20px;">
          <ul class="mr-12 pr-6" style="list-style-type: none; padding-left: 4px !important;"
            v-for="( item ) in  winnerData[`week${index + 1}`].store " :key="item">
            <li>
              {{ item }}
            </li>
          </ul>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 28px;
}

p {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: black;
}

a:hover {
  color: black !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  data() {
    return {
      winnerData: {
        week1: {
          name: 'Week 1',
          agent: [
            'M.Vaz',
            'B Lennon',
            'H.Timotius',
            'J. Frost',
            'Z. Mashford',
            'A. Overend',
            'A. Martin',
            'C. Rybalka',
            'E. Swindley',
            'J. O\'Grady',
            'D. Wheeler'
          ],
          store: [
            'Flight Centre Bentons Square (VIC)',
            'RAA Mount Gambier (SA)',
            'Flight Centre Carindale (QLD)',
            'MTA Travel, Justine Frost (VIC)',
            'RAA Mile End (SA)',
            'Flight Centre Murray St Mall (WA)',
            'Andrea Martin Travel (VIC)',
            'CT Connections (VIC)',
            'Globetrotter Corporate Travel (WA)',
            'Travel ASsociates Geelong (VIC)',
            'Flight Centre Noosa Civic (QLD)'
          ],
        },
        week2: {
          name: 'Week 2',
          agent: [
            'S. Brassey',
            'K.McColl',
            'C. Buck',
            'I. Carswell',
            'P. Hatzidimitriou',
            'K. Fox',
            'B. Mackenzie',
            'K. Borg',
            'J.Somerville',
            'O. Carvalho',
            'S. Morker',
          ],
          store: [
            'Locale Travel (NSW)',
            'Fligt Centre AShgrove (QLD)',
            'TravelManagers (WA)',
            'Travel Associates Carswell & Turner (NSW)',
            'Stage & Screen (VIC)',
            'Helloworld Foresthill (QLD)',
            'Flight Centre Tweed City (QLD)',
            'Corporate Traveller (WA)',
            'Corporate Traveller (NSW)',
            'Helloworld Shellharbour (NSW)',
            ' Gray Dawes Travel (VIC)'
          ],
        },
        week3: {
          name: 'Week 3',
          agent: [
            'B. Jeffery',
            'A. Zika',
            'T. Mansell',
            'T. Culverhouse',
            'L. Savage',
            'C. Adams',
            'K. Jaku',
            'F. Kent',
            'S. Atkins',
            'D. Ryan',
          ],
          store: [
            'Flight Centre Lismore (NSW)',
            'Flight Centre Casey Central (VIC)',
            'FCM NSW Goverment (QLD)',
            'Helloworld Buderim (QLD)',
            'RACT Travel Devonport (TAS)',
            'CTM (WA)',
            'Flight Centre Northbridge (NSW)',
            'CTM (NSW)',
            'Flight Centre Orion Springfield (QLD)',
            'BCD Travel (NSW)',
          ],
        },
        week4: {
          name: 'Week 4',
          agent: [
            'M. Thornton',
            'J. Carter',
            'A. Sampson',
            'C. Hartley',
            'S. Harding',
            'K. Henderson',
            'J. Jack',
            'D. Swinton',
            'E. Currow',
            'M. Kunze',
          ],
          store: [
            'Flight Centre Cairns Central (QLD)',
            'Flight Centre Casey Central (VIC)',
            'Corporate Traveller (VIC)',
            'BCD Travel (NSW)',
            'Pursers Travel & Cruise (QLD)',
            'Globetrotter (WA)',
            'Travel Managers (VIC)',
            'Show Group (QLD)',
            'Navan (NSW/WA Based)',
            'Flight Centre Corrimal (NSW)'
          ],
        }
      },
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    visit(url) {
      this.$router.push(url);
    },
    goToQuizTC() {
      const routeData = this.$router.resolve({ path: '/quizTC' });
      window.open(routeData.href, '_blank');
    },
  }
};
</script>