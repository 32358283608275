<template>
	<v-main style="margin:0 10px;">
		<div style="height:10px;"></div>
		<v-data-table :headers="headers" :items="dynamicFormList" sort-by="id"
			:footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }">
			<template v-slot:top>
				<v-row no-gutters style="padding:20px 10px 15px;">
					<v-col cols="12" sm="6" class="d-flex flex-wrap align-center">
						<a>
							<v-icon @click="$router.go(-1)" color="black" class="pl-4">mdi-arrow-left</v-icon>
						</a>
						<div class="d-flex px-2">
							<h2>Submitted Form</h2>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.question="{ item }">{{ $root.escapeHtml(item.question) }}</template>
			<template v-slot:item.type="{ item }">{{ item.type.replaceAll('TC_', 'T&C ').replaceAll('_', ' ') }}</template>
			<template v-slot:item.answer="{ item }">
				<template v-if="item.type == 'Form'">
					<div v-for="(answer, index) in JSON.parse(item.answer)" :key="index" class="pt-2">
						<div v-if="answer.type != 'HTML'">
							<div>{{ answer.type.replaceAll('_', ' ') }}:</div>
							<div style="color: grey;">{{ answer.submitAnswer }}</div>
							<br />
						</div>
					</div>
				</template>
				<template v-else-if="Array.isArray(item.answer)">
					{{ item.answer.join(', ') }}
				</template>
				<template v-else>
					{{ item.answer }}
				</template>
			</template>
			<template v-slot:no-data>Empty list</template>
		</v-data-table>
	</v-main>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";

export default {
	mounted() {
		this.getList();
	},
	data() {
		return {
			headers: [
				{ text: "No", value: "no", width: '70px' },
				{ text: "Content", value: "question" },
				{ text: "Type", value: "type" },
				{ text: "Answer", value: "answer" },
				{ text: "", value: "action", sortable: false },
			],
			entity: {},
			dynamicFormList: [],
		}
	},
	methods: {
		getList() {
			axios.get("/system/creator/form/content/submission/list?dynamicFormId=" + this.$route.params.id + "&personId=" + this.$route.params.personId).then(response => {
				this.dynamicFormList = response.data;
			})
		},
	}
}
</script>