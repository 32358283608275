<template>
  <v-main
    :style="{ backgroundImage: 'url(' + (entity.backgroundImage ? entity.backgroundImage : 'https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg') + ')', backgroundSize: 'cover', margin: '10px' }"
    fluid>
    <div v-if="isPreview == false">
      <v-container fluid>
        <div class="navbar d-flex flex-wrap pt-5">
          <img style="width: 216px" :src="$bucket + '/image/logo-duo-reverse.svg'" @click="$store.commit('debug')" />
          <v-spacer />
          <img style="width: 182px" :src="$bucket + '/image/logo-airnz.svg'" />
        </div>
        <!-- <router-view :key="$route.fullPath"></router-view> -->
      </v-container>
      <v-container fluid style="max-width: 1271px">
        <v-form ref="form">
          <v-sheet class="pa-8">
            <v-row>
              <!-- <v-col>
              <h1>{{ entity.title }}</h1>
            </v-col> -->
              <v-col>
                <v-text-field v-model="entity.title" label="Title" :rules="[(v) => !!v || 'Enter Title']" />
              </v-col>
              <v-col cols="auto" >
                <v-btn @click="$router.push('/administration/creator/page/landing')" color="duo-secondary">Cancel</v-btn>
                <v-btn @click="showPreview" color="duo-secondary" class="mx-2">Preview</v-btn>
                <v-btn @click="save" color="duo-primary">
                  Save</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="entity.istnc == undefined || entity.istnc == false">
              <v-col cols="3">
                <v-select v-if="entity.istnc == undefined || entity.istnc == false" v-model="entity.map.headingStyle" :items="titleList" dense label="Set heading size"
                  class="duo-description rounded-0" append-icon="mdi-chevron-down" item-value="value"
                  item-text="labelSelect">
                  <template v-slot:selection="{ item }">
                    <div class="d-flex justify-center pl-6 py-2" style="width: 100%" v-html="item.label"></div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="auto">
                <v-checkbox id="hideTitle" v-model="entity.map.hideTitle" class="mt-0 pt-0">
                  <template v-slot:label>
                    <span style="font-size: 14px; color: #2d2926 !important">Hide title</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="entity.istnc == undefined || entity.istnc == false">
              <!-- <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1271px" max-height="376px" style="background-size: cover; background-position: center center;"
            :src="entity.heroImage" />
        </v-col> -->
              <v-col cols="5">

                <v-row class="d-flex align-center" no-gutters>
                  <v-col><v-text-field v-if="attachmentLabel.length > 0" v-model="attachmentLabel"
                      label="Upload Hero Image" prepend-icon="mdi-file" @click="openFileDialog()" readonly />
                    <v-text-field v-else v-model="entity.heroImage" label="Upload Hero Image" prepend-icon="mdi-file"
                      @click="openFileDialog()" readonly />
                    <input type="file" ref="attachment" accept="image/png, image/jpeg, image/jpg" style="display: none;"
                      @change="showImage" required /></v-col>
                  <v-col cols="auto"> <v-icon color="duoQuaternary" class="mb-0 white--text"
                      @click="delete entity.heroImage; attachment = null; attachmentLabel = ''; refresh();">mdi-trash-can</v-icon></v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="auto"> <v-btn color="duoQuaternary" class="mb-0 white--text"
                      @click="showImageLib('hero')">Images Library</v-btn></v-col>
                  <v-col class="pl-2"><sub>Reccomended image size: <b>1271 x 370 px</b></sub></v-col>
                </v-row>

              </v-col>
              <v-col cols="5">
                <v-text-field v-model="entity.slug" label="Page Url" :rules="[(v) => !!v || 'Enter Page Url']" />
              </v-col>
              <v-col cols="12">
                <span><b>Set background image</b></span>
                <v-row class="d-flex align-center" no-gutters>
                  <v-col cols="auto"> <v-btn color="duoQuaternary" class="mb-0 white--text"
                      @click="showImageLib('background')">Background Library</v-btn></v-col>
                  <v-col><v-text-field v-if="attachment1Label.length > 0" v-model="attachment1Label"
                      label="Upload Hero Image" prepend-icon="mdi-file" @click="openFile1Dialog()" readonly />
                    <v-text-field v-else v-model="entity.backgroundImage" label="Upload Background Image"
                      prepend-icon="mdi-file" @click="openFile1Dialog()" readonly />
                    <input type="file" ref="attachment1" accept="image/png, image/jpeg, image/jpg" style="display: none;"
                      @change="showImage1" required /></v-col>
                  <v-col cols="auto"> <v-icon color="duoQuaternary" class="mb-0 white--text"
                      @click="delete entity.backgroundImage; attachment1 = null; attachment1Label = ''; refresh();">mdi-trash-can</v-icon></v-col>
                </v-row>
                <sub>Reccomended image size: <b>1366 x 768 px</b></sub>
              </v-col>
            </v-row>
          </v-sheet>
        </v-form>
        <div v-if="entity.istnc == true">
          <editor v-if="loaded == true" v-model="tncText"
          :api-key="$tinyMceKey" :init="initEditor" />
        </div>
        <div v-else class="mt-6" v-for="(item, idx) in entity.map.contentList" :key="idx">
          <p><b>{{ item.type.replaceAll("_", " ") }}</b></p>
          <v-sheet class="pa-8">
            <v-row>
              <v-col cols="10">
                <div v-if="item.type == 'Text'">
                  <editor v-if="loaded == true" v-model="entity.map.contentList[idx].content"
                  :api-key="$tinyMceKey" :init="initEditor" />
                </div>
                <div v-else-if="item.type == 'Text_Image_In_Grey_Box'">
                  <v-row class="d-flex align-center" no-gutters>
                    <v-col cols="auto"> <v-btn color="duoQuaternary" class="mb-0 white--text"
                        @click="showImageLib('image1', idx)">Images Library</v-btn></v-col>
                    <v-col><v-text-field v-model="entity.map.contentList[idx].content.image1" placeholder="Upload File"
                        prepend-icon="mdi-file" @click="openNestedFileDialog('attachment' + idx + 'image1')"
                        readonly></v-text-field>
                      <input type="file" :id="'attachment' + idx + 'image1'" accept="image/png, image/jpeg, image/jpg"
                        style="display: none;" @change="showNestedImage($event, 'image1', idx)" /> </v-col>

                    <v-col cols="auto"> <v-icon
                        v-if="!entity.map.contentList[idx].content.image2 && !entity.map.contentList[idx].content.image3"
                        color="duoQuaternary" class="mb-0 white--text"
                        @click="delete entity.map.contentList[idx].content.image1; refresh();">mdi-trash-can</v-icon></v-col>
                  </v-row>
                  <editor v-if="loaded == true" v-model="entity.map.contentList[idx].text"
                  :api-key="$tinyMceKey" :init="initEditor" />
                </div>
                <div v-else-if="item.type == 'Images_Block'">
                  <v-row class="d-flex align-center" no-gutters>
                    <v-col cols="auto"> <v-btn color="duoQuaternary" class="mb-0 white--text"
                        @click="showImageLib('image1', idx)">Images Library</v-btn></v-col>
                    <v-col><v-text-field v-model="entity.map.contentList[idx].content.image1" placeholder="Upload File"
                        prepend-icon="mdi-file" @click="openNestedFileDialog('attachment' + idx + 'image1')"
                        readonly></v-text-field>
                      <input type="file" :id="'attachment' + idx + 'image1'" accept="image/png, image/jpeg, image/jpg"
                        style="display: none;" @change="showNestedImage($event, 'image1', idx)" /> </v-col>

                    <v-col cols="auto"> <v-icon
                        v-if="!entity.map.contentList[idx].content.image2 && !entity.map.contentList[idx].content.image3"
                        color="duoQuaternary" class="mb-0 white--text"
                        @click="delete entity.map.contentList[idx].content.image1; refresh();">mdi-trash-can</v-icon></v-col>
                  </v-row>
                  <v-row class="d-flex align-center" no-gutters>
                    <v-col cols="auto"> <v-btn :disabled="!entity.map.contentList[idx].content.image1"
                        color="duoQuaternary" class="mb-0 white--text" @click="showImageLib('image2', idx)">Images
                        Library</v-btn></v-col>
                    <v-col><v-text-field :disabled="!entity.map.contentList[idx].content.image1"
                        v-model="entity.map.contentList[idx].content.image2" placeholder="Upload File"
                        prepend-icon="mdi-file" @click="openNestedFileDialog('attachment' + idx + 'image2')"
                        readonly></v-text-field>

                      <input type="file" :id="'attachment' + idx + 'image2'" accept="image/png, image/jpeg, image/jpg"
                        style="display: none;" @change="showNestedImage($event, 'image2', idx)" /> </v-col>
                    <v-col cols="auto"> <v-icon v-if="!entity.map.contentList[idx].content.image3"
                        :disabled="!entity.map.contentList[idx].content.image1" color="duoQuaternary"
                        class="mb-0 white--text"
                        @click="delete entity.map.contentList[idx].content.image2; refresh();">mdi-trash-can</v-icon></v-col>
                  </v-row>
                  <v-row class="d-flex align-center" no-gutters>
                    <v-col cols="auto"> <v-btn :disabled="!entity.map.contentList[idx].content.image2"
                        color="duoQuaternary" class="mb-0 white--text" @click="showImageLib('image3', idx)">Images
                        Library</v-btn></v-col>
                    <v-col> <v-text-field :disabled="!entity.map.contentList[idx].content.image2"
                        v-model="entity.map.contentList[idx].content.image3" placeholder="Upload File"
                        prepend-icon="mdi-file" @click="openNestedFileDialog('attachment' + idx + 'image3')"
                        readonly></v-text-field>
                      <input type="file" :id="'attachment' + idx + 'image3'" accept="image/png, image/jpeg, image/jpg"
                        style="display: none;" @change="showNestedImage($event, 'image3', idx)" /></v-col>
                    <v-col cols="auto"> <v-icon :disabled="!entity.map.contentList[idx].content.image2"
                        color="duoQuaternary" class="mb-0 white--text"
                        @click="delete entity.map.contentList[idx].content.image3; refresh();">mdi-trash-can</v-icon></v-col>
                  </v-row>



                </div>
                <div v-else-if="item.type == 'Video'">
                  <v-text-field @blur="covertVideo(idx)" v-model="entity.map.contentList[idx].content"
                    label="Video Url" />
                </div>
                <div v-else-if="item.type == 'CTA_Button'">
                  <v-chip-group v-model="ctaButtonSelected" mandatory column class="pt-2">
                    <v-chip v-for="(item, idx) in ctaButtonTab" :key="idx" color="primary" outlined pill
                      style="cursor: pointer; width: 75px; height: 24px;" class="d-flex justify-center">{{ item
                      }}</v-chip>
                  </v-chip-group>
                  <v-text-field v-if="ctaButtonSelected == 0" v-model="entity.map.contentList[idx].content.label"
                    label="Button Text" />
                  <v-text-field v-if="ctaButtonSelected == 1" v-model="entity.map.contentList[idx].content.link"
                    label="Button Link" :rules="[(v) => !!v || 'Enter Link', (v) => $root.isURL(v) || 'Invalid link, link should start with http:// or https://']" />
                  <!-- <v-text-field  label="Button Position" /> -->
                  <v-radio-group v-if="ctaButtonSelected == 2" v-model="entity.map.contentList[idx].content.position" row>
                    <v-radio label="Left" value="left"></v-radio>
                    <v-radio label="Center" value="center"></v-radio>
                    <v-radio label="Right" value="right"></v-radio>
                  </v-radio-group>
                </div>
                <div v-else-if="item.type == 'Dynamic_Form'">
                  <v-select v-model="entity.map.contentList[idx].content" :rules="[(v) => !!v || 'Select Dynamic Form']"
                    :items="dynamicFormList" item-value="id" item-text="title" label="Select Dynamic Form"
                    class="{ 'line-blue': entity.link != null }" append-icon="mdi-chevron-down">
                    <template v-slot:item="{ item }">
                      <span class="duo-description">{{ item.title }}</span>
                    </template>
                  </v-select>
                </div>

                <div v-else-if="item.type == 'Terms_and_Conditions'">
                  <v-chip-group v-model="tncButtonSelected" mandatory column class="pt-2">
                    <v-chip v-for="(item, idx) in tncButtonTab" :key="idx" color="primary" outlined pill
                      style="cursor: pointer; width: 75px; height: 24px;" class="d-flex justify-center">{{ item
                      }}</v-chip>
                  </v-chip-group>

                  <v-text-field v-if="tncButtonSelected == 0" v-model="entity.map.contentList[idx].content.label"
                    label="Button Text" />
                  
                  <v-select v-if="tncButtonSelected == 1" v-model="entity.map.contentList[idx].content.pages" :rules="[(v) => !!v || 'Select T&C page']"
                    :items="pageList" item-value="id" item-text="title" label="Select T&C page"
                    class="{ 'line-blue': entity.link != null }" append-icon="mdi-chevron-down">
                    <template v-slot:item="{ item }">
                      <span class="duo-description">{{ item.title }}</span>
                    </template>
                  </v-select>
                  
                  <v-radio-group v-if="tncButtonSelected == 2" v-model="entity.map.contentList[idx].content.position" row>
                    <v-radio label="Left" value="left"></v-radio>
                    <v-radio label="Center" value="center"></v-radio>
                    <v-radio label="Right" value="right"></v-radio>
                  </v-radio-group>
                </div>

              </v-col>
              <v-col cols="2">
                <v-icon v-if="idx > 0" @click="shiftUp(item, idx)">mdi-arrow-up</v-icon>
                <v-icon v-if="idx < entity.map.contentList.length - 1"
                  @click="shiftDown(item, idx)">mdi-arrow-down</v-icon>
                <v-icon @click="removeContent(idx)">mdi-trash-can</v-icon>
              </v-col>
            </v-row>
          </v-sheet>
        </div>

        <v-row class="mt-12" v-if="entity.istnc == undefined || entity.istnc == false">
          <v-col cols="4">
            <v-select v-model="selectedType" :items="typeList" solo outlined dense label="Add content block"
              class="duo-description rounded-0" append-icon="mdi-chevron-down">
              <template v-slot:selection="{ item }">
                <span>{{ item.replaceAll('_', ' ') }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span class="duo-description">{{ item.replaceAll('_', ' ') }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="addContent" class="white--text" color="duoSecondary"> Add </v-btn>
          </v-col>
        </v-row>

      </v-container>
    </div>
    <div v-else>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="isPreview = false" color="duo-secondary" class="mr-2">Back</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <duo-landing-page :is-preview="true" :entity="entity" :question-map="questionMap" />
    </div>
    <div>

    </div>

    <dialog-images-picker :show="dialogLibrary.show" :list="dialogLibrary.list" @onClickImage="onSelectImage"
      @onCloseDialog="dialogLibrary.show = false" />

    <confirmation-dialog title="Confirm leave page" :show="dialogConfirmLeavePage"
      content="Any unsaved progress will be lost <br/> Do you want to leave this page?"
      @onCloseDialog="dialogConfirmLeavePage = false" @onClickConfirm="confirmLeavePage" />
  </v-main>
</template>
  
<style scoped>
h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>
  
<script>
/* eslint-disable no-console */
import editor from "@tinymce/tinymce-vue";
import axios from "axios";
import confirmationDialog from "./dialog/confirmationDialog.vue"
import dialogImagesPicker from "./dialog/dialogImagesPicker.vue"
import duoLandingPage from "./creator/duoLandingPage.vue"

export default {
  components: {
    editor,
    dialogImagesPicker,
    duoLandingPage,
    confirmationDialog
  },
  data() {
    return {
      tncText: "",
      loaded: true,
      isPreview: false,
      typeList: ['Text', 'Text_Image_In_Grey_Box', 'Images_Block', 'Video', 'CTA_Button', 'Dynamic_Form', 'Terms_and_Conditions'],
      //typeList: ['Text', 'Images_Block', 'Video', 'CTA_Button', 'Dynamic_Form'],
      titleList: [
        { value: 'h1', label: '<style scoped>h1{font-family: "AirNZ Black Italic"; font-size: 38px;}</style><h1>Heading 1</h1>', labelSelect: 'Heading 1 (ANZ Bold)' },
        { value: 'h2', label: '<style scoped>h2{font-family: "AirNZ Sans Semibold"; font-size: 25px;}</style><h2>Heading 2</h2>', labelSelect: 'Heading 2 (ANZ Semibold)' },
        { value: 'h3', label: '<style scoped>h3{font-family: "AirNZ Sans Semibold"; font-size: 21px;}</style><h3>Heading 3</h3>', labelSelect: 'Heading 3 (ANZ Semibold)' },
      ],
      tncButtonTab: ['Text', 'Page', 'Position'],
      tncButtonSelected: 0,
      ctaButtonTab: ['Text', 'Link', 'Position'],
      ctaButtonSelected: 0,
      selectedType: null,
      pageImageList: [],
      backgroundImageList: [],
      dynamicFormList: [],
      pageList: [],
      dialogLibrary: {
        show: false,
        target: '',
        list: [],
        idx: undefined,
      },
      entity: {
        backgroundImage: "https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg",
        map: {
          contentList: []
        }
      },
      entityOld: "",
      questionMap: {},
      attachment: null,
      attachmentLabel: "",
      attachment1: null,
      attachment1Label: "",
      initEditor: {
        height: 320,
        width: '100%',
        selector: '',
        menubar: false,
        plugins: ["code", "paste", "table", "lists", "link", "textcolor"],
        paste_as_text: true,
        fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px",
        toolbar:
          "undo redo | formatselect fontsizeselect | bold italic underline forecolor | \
					alignleft aligncenter alignright alignjustify | link | bullist numlist outdent indent | table | code removeformat | pastetext | help",
        content_style: 'body{ font-size:17px; } ul {margin-left: 48px;}',
      },
      leave: false,
      nextPath: "/administration",
      dialogConfirmLeavePage: false
    }
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getData();
    this.getImageLib();
    this.getBackgroundImageLib();
    this.getDynamicFormList();
    this.getTCPageList();
  },
  beforeRouteLeave(to, from, next) {
    if (this.leave == true) {
      next();
    } else {
      next(false);
      this.beforeLeave(to.fullPath);
    }
  },

  methods: {
    beforeLeave(path) {
      this.nextPath = path;
      if (JSON.stringify(this.entity) === this.entityOld && this.entityOld.length > 1) {
        this.leave = true;
        this.$router.push(this.nextPath);
      } else {
        this.dialogConfirmLeavePage = true;
      }
    },
    confirmLeavePage() {
      this.leave = true;
      this.$router.push(this.nextPath);
    },
    getImageLib() {
      axios.get("/system/creator/page/library/list")
        .then((response) => {
          this.pageImageList = response.data;
        });
    },
    getBackgroundImageLib() {
      axios.get("/system/creator/page/library/background/list")
        .then((response) => {
          this.backgroundImageList = response.data;
        });
    },
    getDynamicFormList() {
      axios.get("/system/creator/form/list")
        .then((response) => {
          this.dynamicFormList = response.data;
        });
    },
    getTCPageList() {
      axios.get("/system/creator/page/list/tc")
        .then((response) => {
          this.pageList = response.data;
          console.log(this.pageList);
        });
    },
    onSelectImage(item) {

      let result = this.$bucket + '/' + item;
      if (this.dialogLibrary.target == 'hero') {
        this.entity.heroImage = result;
      } else if (this.dialogLibrary.target == 'background') {
        this.entity.backgroundImage = result;
      } else if (this.dialogLibrary.target.startsWith('image')) {
        this.entity.map.contentList[this.dialogLibrary.idx].content[this.dialogLibrary.target] = result;
        this.$forceUpdate();
      }

      this.dialogLibrary.show = false;
    },
    refresh() {
      this.$forceUpdate();
    },
    showImageLib(target, idx = undefined) {
      if (target == 'background') {
        this.dialogLibrary.list = this.backgroundImageList;
      } else {
        this.dialogLibrary.list = this.pageImageList;
      }

      this.dialogLibrary.show = true;
      this.dialogLibrary.target = target;
      this.dialogLibrary.idx = idx;
    },
    getData() {
      axios.get("/system/creator/page?id=" + this.$route.params.id).then(
        response => {
          if (!response.data.map.contentList) {
            response.data.map.contentList = [];
          }
          this.entity = response.data;
          this.entityOld = JSON.stringify(response.data);

          if (this.entity.map.contentList[0] != undefined) {
            this.tncText = this.entity.map.contentList[0].content;
          }
        }
      );
    },
    clearData() {
      this.attachment = null;
      this.attachmentLabel = "";

      this.attachment1 = null;
      this.attachment1Label = "";
    },
    openFileDialog() {
      this.$refs.attachment.click();
    },
    openFile1Dialog() {
      this.$refs.attachment1.click();
    },
    openNestedFileDialog(value) {
      document.getElementById(value).click();
    },
    showImage(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {

        if (files[0].size > 2097152) {
          this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
          return;
        }

        let imageName = files[0].name;
        if (imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.entity.heroImage = fr.result;
          this.attachment = files[0]; // this is an image file that can be sent to server...
          this.attachmentLabel = files[0].name;
          this.imgError = false;
        });
      } else {
        this.attachment = null;
        this.attachmentLabel = "";
      }
    },
    showImage1(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].size > 2097152) {
          this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
          return;
        }

        let imageName = files[0].name;
        if (imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.entity.backgroundImage = fr.result;
          console.log(this.entity.backgroundImage);
          this.attachment1 = files[0]; // this is an image file that can be sent to server...
          this.attachment1Label = files[0].name;
          this.imgError = false;
        });
        this.refresh();
      } else {
        this.attachment1 = null;
        this.attachment1Label = "";
      }
    },
    showNestedImage(e, value, idx) {
      const files = e.target.files;
      console.log(files);
      if (files[0] !== undefined) {

        if (files[0].size > 2097152) {
          this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
          return;
        }

        let imageName = files[0].name;
        if (imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          //entity.map.contentList[idx].content.image2
          this.entity.map.contentList[idx].content[value] = files[0].name;
          this.entity.map.contentList[idx].content[value + 'File'] = fr.result;
          console.log(this.entity.map.contentList[idx]);
          //this.attachment = files[0]; // this is an image file that can be sent to server...
          this.imgError = false;

          this.$forceUpdate();
        });

      }
    },
    covertVideo(idx) {
      this.entity.map.contentList[idx].content = this.$root.convertVideoUrl(this.entity.map.contentList[idx].content);
    },
    addContent() {
      if (this.selectedType != null) {
        let obj = { type: this.selectedType, content: "" };
        if (this.selectedType == "Images_Block" || this.selectedType == "Text_Image_In_Grey_Box" || this.selectedType == "CTA_Button"|| this.selectedType == "Terms_and_Conditions") {
          obj.content = {}
        }

        this.entity.map.contentList.push(obj);
      }
    },
    shiftUp(item, currIdx) {
      this.loaded = false;
      this.entity.map.contentList.splice(currIdx, 1);
      this.entity.map.contentList.splice(currIdx - 1, 0, item);
      this.loaded = true;
    },
    shiftDown(item, currIdx) {
      this.loaded = false;
      this.entity.map.contentList.splice(currIdx, 1);
      this.entity.map.contentList.splice(currIdx + 1, 0, item);
      this.loaded = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        let url = "/system/creator/page/edit"

        let formData = new FormData();

        if (this.entity.istnc == true) {
          if (this.entity.map.contentList[0] == undefined || this.entity.map.contentList[0].content == null) {
            this.entity.map.contentList.push({content: this.tncText, type: "Text"});
          }
          this.entity.map.contentList[0].content = this.tncText;
        }

        formData.append("entity", JSON.stringify(this.entity));
        if (this.attachment) {
          formData.append("attachment", this.attachment);
        }
        if (this.attachment1) {
          formData.append("attachment1", this.attachment1);
        }
        axios.post(url, formData).then(() => {
          this.clearData();
          this.getData();
        })
      }
    },
    showPreview() {
      axios.post("/system/creator/page/question/list", { entity: this.entity }).then((response) => {
        this.questionMap = response.data;
        if (this.entity.istnc == true) {
          if (this.entity.map.contentList[0] == undefined || this.entity.map.contentList[0].content == null) {
            this.entity.map.contentList.push({content: this.tncText, type: "Text"});
          }
          this.entity.map.contentList[0].content = this.tncText;
        }

        this.isPreview = true;
      })
    },
    removeContent(idx) {
      this.entity.map.contentList.splice(idx, 1)
    },
  }
};
</script>