<template>
    <v-dialog v-model="show" content-class="v-dialog--custom" persistent>
        <v-card height="100%" background-color="transparent">
            <v-card-text class="ma-0 pa-0" style="position: absolute; height: 100%">
                <div style="position: relative">
                    <v-icon large color="grey" style="position: absolute; right: 0px; top: 0px; z-index: 1;" @click="
                        onCloseDialog();
                    ">mdi-close</v-icon>
                </div>
                <video v-if="link && link.endsWith('.mp4')" width="100%" height="100%" class="iframe-size" controls controlsList="nodownload">
                    <source :src="link" type="video/mp4">
                </video>
                <iframe v-else :src="link" :title="title" frameborder="0" scrolling="no" width="100%" height="100%" class="iframe-size"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<style>
.v-dialog--custom {
	width: 902px;
	height: 508px;
    overflow-y: hidden;
}
</style>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "",
        },
        link: {
            type: String,
            default: "",
        }
    },
    methods: {
        onCloseDialog() {
            this.$emit("onCloseDialog");
        },
    }
}
</script>