<template>
  <v-main style="margin: 10px">
    <v-card class="d-flex flex-column align-content-center" min-height="155">
      <v-data-table :headers="headers" :items="notifications" :loading="loading" :search="search"
        :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }">
        <template v-slot:top>
          <v-row no-gutters style="padding: 20px 10px 15px">
            <v-col cols="12" sm="6">
              <div class="d-flex px-2">
                <h2>{{ $root.capitalizeFirst(title) }} list</h2>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="d-flex px-2">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" hide-details
                  style="margin-right: 10px" />
                <v-btn small fab dark color="secondary" title="Add" @click="showAddDialog(newEntity())">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.sentFlag="{ item }">
          <v-icon v-if="item.sentFlag === true" color="green" title="Sent">mdi-check</v-icon>
          <v-icon v-else title="Not Sent">mdi-minus</v-icon>
        </template>
        <template v-slot:item.domainList="{ item }">
          <div class="d-flex flex-row">
            {{ (item.map.domainFilter && item.map.domainFilter == true) ? item.map.domainList : '' }}
          </div>
        </template>
        <template v-slot:item.agencyGroupList="{ item }">
          <div class="d-flex flex-row">
            {{ (item.map.agencyGroupFilter && item.map.agencyGroupFilter == true) ? item.map.agencyGroupList : '' }}
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <div class="d-flex flex-row">
            <v-icon :disabled="item.sentFlag && item.sentFlag === true" class="ml-2"
              @click="showAddDialog(item)">mdi-pencil</v-icon>
            <v-icon :disabled="item.sentFlag && item.sentFlag === true" class="ml-2"
              @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogAdd" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ (this.entity.id ? "Edit " : "Add ") + title }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="entity.title" label="Title" counter="50"
              :rules="[v => !!v || 'Enter Title', v => (v && (v || '').length <= 50) || 'Title length should not above 50']" />
            <v-menu ref="datePickerAnnouncement" v-model="datePicker.announcementDate" :close-on-content-click="false"
              :return-value.sync="entity.transitMap.announcementDate" transition="scale-transition" offset-y
              max-width="320px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field id="announcementDate" :v-model="entity.transitMap.announcementDate"
                  :value="$root.parseDate(entity.transitMap.announcementDate)" readonly v-on="on" v-bind="attrs"
                  :rules="[(v) => !!v || 'Enter Announcement Date']" @click:prepend="datePicker.announcementDate = true">
                  <template v-slot:append>
                    <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                  </template>
                  <template v-slot:label>
                    <span style="font-size: 17px; color: black !important">Announcement Date</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="entity.transitMap.announcementDate" no-title scrollable style="width: 100%"
                :allowed-dates="$root.disablePastDates">
                <v-row no-gutters justify="space-around" class="pa-4">
                  <v-btn width="120" class="mx-0" outlined color="primary"
                    @click="$refs.datePickerAnnouncement.save(entity.transitMap.announcementDate)">Confirm</v-btn>
                  <v-btn width="120" class="mx-0" outlined @click="datePicker.announcementDate = false">Cancel</v-btn>
                </v-row>
              </v-date-picker>
            </v-menu>
            <v-menu ref="datePickerEnd" v-model="datePicker.endDate" :close-on-content-click="false"
              :return-value.sync="entity.transitMap.endDate" transition="scale-transition" offset-y max-width="320px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field id="endDate" :v-model="entity.transitMap.endDate"
                  :value="$root.parseDate(entity.transitMap.endDate)" readonly v-on="on" v-bind="attrs"
                  @click:prepend="datePicker.endDate = true">
                  <template v-slot:append>
                    <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                  </template>
                  <template v-slot:label>
                    <span style="font-size: 17px; color: black !important">End Date</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="entity.transitMap.endDate" no-title scrollable style="width: 100%"
                :allowed-dates="ruleAnnouncementDate">
                <v-row no-gutters justify="space-around" class="pa-4">
                  <v-btn width="120" class="mx-0" outlined color="primary"
                    @click="$refs.datePickerEnd.save(entity.transitMap.endDate)">Confirm</v-btn>
                  <v-btn width="120" class="mx-0" outlined @click="datePicker.endDate = false">Cancel</v-btn>
                </v-row>
              </v-date-picker>
            </v-menu>
            <v-text-field v-model="entity.map.link" label="Link"
              :rules="[v => !v || !v.includes(' ') || 'Link should not contains space', v => !v || (!!v && v.length > 0 && (v.startsWith('http://') || v.startsWith('https://'))) || 'Link should be start with http:// or https://']" />
            <v-textarea v-model="entity.message" label="Message" counter="150"
              :rules="[v => !!v || 'Enter Message', v => (v && (v || '').length <= 150) || 'Message length should not above 150']" />
            <v-checkbox :disabled="entity.map.allFilter == true" id="domainFilter" v-model="entity.map.domainFilter"
              class="mt-0">
              <template v-slot:label>
                <span style="font-size: 14px; color: #2d2926 !important"><b>Only</b>
                  visible
                  to
                  users with domain ending</span>
              </template>
            </v-checkbox>
            <v-text-field :disabled="entity.map.domainFilter == false" class="mt-0 pt-0" v-model="entity.map.domainList"
              label="Domain List" hide-details />
              <sub>Tip: use comma to separate between lists, example: @airnz.co.nz, @mytravel.com</sub>
            <v-checkbox :disabled="entity.map.allFilter == true" id="agencyGroupFilter"
              v-model="entity.map.agencyGroupFilter" class="mt-8 pt-0">
              <template v-slot:label>
                <span style="font-size: 14px; color: #2d2926 !important"><b>Only</b>
                  visible
                  to
                  account / parent account that starts with</span>
              </template>
            </v-checkbox>
            <v-text-field :disabled="!entity.map.agencyGroupFilter || entity.map.agencyGroupFilter == false"
              class="mt-0 py-0" v-model="entity.map.agencyGroupList" label="Account / Parent Account List" hide-details />
              <sub>Tip: use comma to separate between lists, example: MTA, Travel Managers, Express</sub>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn @click="addNotification" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete {{ title }}</span>
        </v-card-title>
        <v-card-text>Are you sure you want to delete '{{ entity.title }}' ({{ entity.id }})?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogDelete = false">Cancel</v-btn>
          <v-btn @click="deleteNotification" color="accent">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    title: "notification",
    loading: true,
    search: "",
    entity: { transitMap: {}, map: {} },
    valid: true,
    datePicker: {
      announcementDate: false,
      endDate: false
    },
    notifications: [
      // { id: 1, title: "Lorem Ipsum Dolor Sit Amet", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc cursus accumsan risus, at semper odio ultrices non. Praesent non suscipit tellus lalala.", announcementDate: "Wed 16 March 2021", sentFlag: false },
      // { id: 2, title: "New promotion available", message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc cursus accumsan risus, at semper odio ultrices non. Praesent non suscipit tellus lalala.", announcementDate: "Wed 16 March 2021", sentFlag: true }
    ],
    headers: [
      { text: "Title", value: "title" },
      { text: "Message", value: "message" },
      { text: "Announcement Date", value: "transitMap.announcementDate" },
      { text: "End Date", value: "transitMap.endDate" },
      { text: "Sent", value: "sentFlag", width: "10px" },
      { text: "Domain", value: "domainList" },
      { text: "Parent Account", value: "agencyGroupList" },
      { text: "", value: "action", sortable: false }
    ],
    dialogAdd: false,
    dialogDelete: false
  }),

  mounted() {
    this.list();
  },

  methods: {
    list() {
      this.loading = true;
      axios.get("/system/notification/list").then((response) => {
        this.loading = false;
        if (response.status === 200) {
          this.notifications = response.data;
        }
      });
    },
    showAddDialog(item) {
      this.entity = this.$root.clone(item);
      this.dialogAdd = true;
    },
    showDeleteDialog(item) {
      this.entity = this.$root.clone(item);
      this.dialogDelete = true;
    },
    closeDialog() {
      this.entity = this.newEntity();
      this.dialogAdd = false;
    },
    addNotification() {
      if (this.$refs.form.validate()) {
        if (this.entity.transitMap.endDate && this.entity.transitMap.endDate != null) {
          let announcementDate = new Date(this.entity.transitMap.announcementDate);
          let endDate = new Date(this.entity.transitMap.endDate);
          if (endDate < announcementDate) {
            window.alert("End Date must be equal or greater than Announcement Date");
            return;
          }

          this.entity.transitMap.endDate = this.entity.transitMap.endDate.substr(0, 10) + " 23:59:59";
        }

        //mandatory
        this.entity.transitMap.announcementDate = this.entity.transitMap.announcementDate.substr(0, 10) + " 00:00:00";

        this.loading = true;
        axios.post("/system/notification/" + (this.entity.id ? "edit" : "add"), { entity: this.entity }).then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.list();
            this.dialogAdd = false;
            this.entity = this.newEntity();
          }
        });
      }
    },
    deleteNotification() {
      axios
        .post("/system/notification/delete", { id: this.entity.id })
        .then(() => {
          this.dialogDelete = false;
          this.entity = this.newEntity();
          this.list();
        })
        .catch(() => { });
    },
    newEntity() {
      return { transitMap: {}, map: {} };
    },
    ruleAnnouncementDate(val) {
      if (this.entity.transitMap.announcementDate && this.entity.transitMap.announcementDate != null) {
        return val >= new Date(this.entity.transitMap.announcementDate).toISOString().slice(0, 10);
      } else {
        return val >= new Date().toISOString().slice(0, 10);
      }
    }
  }
};
</script>