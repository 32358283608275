<template>
	<v-main>
		<v-container fluid :style="{ backgroundSize: 'cover', backgroundAttachment: 'fixed', minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundImage: 'url(' + $bucket + '/image/background/Login.BG.jpg' + ')' }">
			<v-col class="d-flex flex-column" style="padding-top: 112px">
				<v-spacer></v-spacer>
				<v-row no-gutters justify="center" style="margin-bottom: 26px; max-height: 50px">
					<img :src="$bucket + '/image/logo-airnz.svg'" style="width: 300px; align-self: center" />
				</v-row>
				<v-row no-gutters justify="space-around">
					<v-card rounded="lg" min-width="300px" width="476px">
						<!-- card head -->
						<v-row
							no-gutters
							justify="center"
							align="center"
							:style="{ backgroundColor: '#2D2926', backgroundImage: 'url(' + $bucket + '/image/logo-duo.svg' + ')', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundSize: '216px 30px', height: '66px' }"
						></v-row>
						<!-- card head end -->

						<!-- register form -->
						<v-form v-model="isDisabled" ref="form" autocomplete="off" style="padding: 30px 40px">
							<v-row no-gutters align="start">
								<h2 class="duo pt-0" style="font-family: AirNZ Sans Semibold; font-size: 28px !important">Registration</h2>
							</v-row>
							<p class="duo-intro" style="line-height: 21px">Creating an Air New Zealand <b>duo</b> account is easy, simply fill out the form below.</p>
							<!-- firstname -->
							<v-text-field id="firstName" v-model="firstName" :rules="[ruleName]" :class="{ 'line-blue': firstName != null && firstName.length > 0 }">
								<template v-slot:label>
									<span class="duo">First name*</span>
								</template>
							</v-text-field>
							<!-- firstname end -->

							<!-- lastname -->
							<v-text-field id="lastName" v-model="lastName" :rules="[ruleName]" :class="{ 'line-blue': lastName != null && lastName.length > 0 }">
								<template v-slot:label>
									<span class="duo">Last name*</span>
								</template>
							</v-text-field>
							<!-- lastname end -->

							<!-- Email -->
							<v-text-field
								id="email"
								v-model="email"
								type="email"
								persistent-hint
								validate-on-blur
								:hint="errormail.show === false ? errormail.hint : ''"
								:rules="[ruleEmail]"
								:loading="emailFetching"
								:class="{ 'line-blue': !errormail.show && email != null && email.length > 0 }"
							>
								<template v-slot:label>
									<span class="duo">Work email*</span>
								</template></v-text-field
							>
							<v-card
								class="pa-2 white--text"
								v-if="emailHint.length > 0"
								style="font-size: 12px; color: rgba(0, 0, 0, 0.75); margin: 10px 0; background-color: rgb(11, 187, 239) !important; border-color: rgb(11, 187, 239) !important"
								color="blue lighten-3"
								v-html="emailHint"
							></v-card>
							<!-- Email end -->

							<!-- Mobile Number -->
							<v-text-field
								id="mobileNumber"
								v-model="mobileNumber"
								persistent-hint
								hide-details="true"
								type="number"
								:loading="mobileNumberFetching"
								:class="{ 'line-blue': !errormobile.show && mobileNumber != null && mobileNumber.length > 0 }"
							>
								<template v-slot:label>
									<span class="duo">Mobile number</span>
								</template>
							</v-text-field>
							<div class="duo-hint pa-0 pt-2" v-if="errormobile.show === false" style="color: rgba(0, 0, 0, 0.6)" v-html="errormobile.hint"></div>
							<v-card
								class="pa-2 white--text"
								v-if="mobileNumberHint.length > 0"
								style="font-size: 12px; color: rgba(0, 0, 0, 0.75); margin: 10px 0; background-color: rgb(11, 187, 239) !important; border-color: rgb(11, 187, 239) !important"
								color="blue lighten-3"
								v-html="mobileNumberHint"
							></v-card>
							<!-- Mobile Number end -->

							<!-- PCC Code -->
							<v-text-field
								id="agencyCode"
								class="agencyCode"
								v-model="agencyCode"
								persistent-hint
								hint="Agency Pseudo Code (PCC) is an alphanumeric identifier for a travel agency using the global distribution system (GDS). A valid PCC must be a combination of three, four, six or nine numbers and characters."
								:rules="[ruleAgencyCode, agencyCodeMatch]"
								maxlength="9"
								:loading="agencyCodeFetching"
								:class="{ 'line-blue': agencyCode != null && agencyCode.length > 0 && agencyCodeMatch }"
								:error="agencyCodeHint.length > 0 && !agencyCodeMatch"
								:error-messages="agencyCodeHint.length > 0 && !agencyCodeMatch ? 'Sorry, we do not have your PCC. Please email duosupport@airnz.co.nz and we will get you set up.' : ''"
							>
								<template v-slot:label>
									<span class="duo">Agency Pseudo Code (PCC)*</span>
								</template>
							</v-text-field>
							<!-- PCC Code end -->

							<v-row no-gutters class="mt-6" justify="center">
								<v-divider class="registration-divider" style="max-width: 125px"></v-divider>
							</v-row>

							<!-- Password -->
							<v-row no-gutters class="font-weight-bold pa-0 my-4" style="font-size: 18px"> Password </v-row>
							<v-text-field
								id="password"
								v-model="password"
								persistent-hint
								:rules="[(v) => !!v || 'Enter a strong password', (v) => (v && v.match('^(?=.*\\d)(?=.*[^A-Za-z0-9]).{8,}$') && true) || 'Your password must be STRONG']"
								:append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (passwordType = !passwordType)"
								:type="passwordType ? 'password' : 'text'"
								:class="{ 'line-blue': password != '' && password.length > 0 }"
							>
								<template v-slot:label>
									<span class="duo">Password*</span>
								</template>
								<template v-slot:message>
									<span>Your password must be <b>STRONG</b></span>
									<ul>
										<li>Passwords must have at least one non-alphanumeric character</li>
										<li>Passwords must have at least one digit ('0'-'9')</li>
										<li>Minimum 8 characters</li>
									</ul>
								</template>
							</v-text-field>
							<v-row v-if="password.length > 0" no-gutters justify="center">
								<p style="font-size: 13px !important; font-family: 'AirNZ Sans Regular' !important">Password strength</p>
								&nbsp;
								<p style="font-size: 13px !important; font-family: 'AirNZ Sans Semibold' !important">{{ passwordStrength.message }} password</p>
							</v-row>

							<v-text-field
								id="confirmPassword"
								v-model="confirmPassword"
								:rules="[(v) => !!v || 'Please confirm your password', (v) => (v && v === password) || 'Password does not match']"
								:append-icon="confirmPasswordType ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (confirmPasswordType = !confirmPasswordType)"
								:type="confirmPasswordType ? 'password' : 'text'"
								:class="{ 'line-blue': confirmPassword != null && confirmPassword.length > 0 }"
							>
								<template v-slot:label>
									<span class="duo">Confirm password*</span>
								</template>
							</v-text-field>
							<!-- Password end -->

							<!-- acceptance -->
							<v-row no-gutters class="my-4">
								<v-col cols="12">
									<v-checkbox dense id="acceptance" class="align-end mt-0" hide-details v-model="acceptance" type="checkbox" :rules="[(v) => !!v || 'Please accept our terms and conditions and privacy policy']">
										<template v-slot:label>
											<span class="pt-4" style="font-size: 14px; color: #2d2926">
												I accept the <b>duo </b> <a href="/#/terms-and-conditions" target="_blank" @click.stop>Terms and Conditions</a> and <br />
												Air New Zealand
												<a href="https://www.airnewzealand.com.au/privacy-policy" target="_blank" @click.stop>Privacy Policy.</a>*
											</span>
										</template>
									</v-checkbox>
								</v-col>
								<span v-if="acceptance == null || acceptance == false" class="red--text"><sub>*Please accept our terms and conditions and privacy policy</sub></span>
							</v-row>
							<!-- acceptance end -->

							<!-- actions -->
							<v-col cols="12" class="d-flex flex-column pa-0 pt-4" align-self="center">
								<v-row no-gutters class="mb-4" justify="center" style="font-size: 17px !important">
									<v-btn id="registerBtn" class="btn-register elevation-0" :disabled="!isDisabled" @click="register"> Register </v-btn>
								</v-row>
								<v-btn id="loginBtn" color="accent" height="63px" outlined block @click="$router.push('/')" style="font-size: 17px; color: #2d2926 !important">
									Already registered?&nbsp;
									<strong>Login</strong>
								</v-btn>
							</v-col>
							<!-- actions end -->
						</v-form>
						<!-- register form end -->
					</v-card>
				</v-row>
				<v-spacer></v-spacer>
			</v-col>
		</v-container>

		<v-dialog v-model="showRegistrationDialog" width="500">
			<div style="padding: 7px 7px 50px 7px; background-color: white">
				<div style="text-align: right">
					<v-btn icon color="secondary" to="/">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<h2 class="duo" style="text-align: center">Almost there, check your email</h2>

				<div style="padding: 0 15%">
					<!-- <p class="duo" :style="{ 'line-height': 'normal', 'text-align': 'center' }">
						We have sent confirmation of your registration to your email address. To complete your <b>duo</b> account set up, click the link within the registration email, and login.
					</p> -->
					<p class="duo" :style="{ 'line-height': 'normal', 'text-align': 'center' }">
						To complete your <b>duo</b> registration, check your email for a confirmation link. If it's not in your inbox, please check your junk/spam folder.
					</p>
				</div>

				<div style="text-align: center; margin-top: 30px">
					<v-btn large class="duo-primary" @click="$router.push({ path: '/' })">Close</v-btn>
				</div>
			</div>
		</v-dialog>
		<v-dialog v-model="showRegistrationDuplicateDialog" width="500">
			<div style="padding: 7px 7px 50px 7px; background-color: white">
				<div style="text-align: right">
					<v-btn icon color="secondary" to="/">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<h2 class="duo" style="text-align: center">You are already registered to <b>duo</b></h2>

				<div style="padding: 0 15%">
					<p class="duo" v-if="emailHint.length > 0 && mobileNumberHint.length > 0" :style="{ 'line-height': 'normal', 'text-align': 'center' }">
						{{ email }} and {{ mobileNumber }} are already registered to <b>duo</b>, please login instead. If you are having problems logging in you can rest your password
						<a class="duo" href="/#/password/forgot">here</a>
						.
						<!-- {{ email }} and {{ mobileNumber }} are already registered to <b>duo</b>, please login instead. Click
						<a class="duo" href="/#/">here</a> to continue to login screen.-->
					</p>
					<p class="duo" v-else-if="emailHint.length > 0" :style="{ 'line-height': 'normal', 'text-align': 'center' }">
						{{ email }} has already been registered to <b>duo</b>, please login instead. If you are having problems logging in you can reset your password
						<a class="duo" href="/#/password/forgot">here</a>
						.
						<!-- {{ email }} are already registered to <b>duo</b>, please login instead. Click <a class="duo" href="/#/">here</a> to continue to
						login screen.-->
					</p>
					<p class="duo" v-else :style="{ 'line-height': 'normal', 'text-align': 'center' }">
						{{ mobileNumber }} has already been registered to <b>duo</b>, please login instead. If you are having problems logging in you can reset your password
						<a class="duo" href="/#/password/forgot">here</a>
						, or contact duosupport@airnz.co.nz to update your registered email address.
						<!-- {{ mobileNumber }} are already registered to <b>duo</b>, please login instead. Click <a class="duo" href="/#/">here</a> to
						continue to login screen.-->
					</p>
				</div>

				<div style="text-align: center; margin-top: 30px">
					<v-btn large class="duo-primary" @click="$router.push({ path: '/' })">Login</v-btn>
				</div>
			</div>
		</v-dialog>
	</v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
// import googlePlaces from "./googlePlaces.vue";
import lodash from "lodash";

export default {
	components: {
		// googlePlaces,
	},
	data() {
		return {
			isDisabled: true,
			title: null,
			firstName: null,
			lastName: null,
			email: null,
			emailFetching: false,
			emailHint: "",
			emailBannedList: [],
			mobileNumber: null,
			mobileNumberFetching: false,
			mobileNumberHint: "",
			mobileAgent: false,
			manualEditAddress: false,
			agencyCodeMatch: false,
			agencyCodeFetched: false,
			agencyCodeBannedList: [],
			bannedAgencyCode: false,
			agencyCode: null,
			agencyCodeValid: false,
			agencyCodeFetching: false,
			agencyCodeHint: "",
			agencyGroupList: [],
			agencyGroupId: null,
			agencyNameList: [],
			agencyName: null,
			agencyId: null,
			address: null,
			selectedAddress: null,
			unitAgencyAddress: null,
			streetAgencyAddress: null,
			suburbAgencyAddress: null,
			stateAgencyAddress: null,
			postCodeAgencyAddress: null,
			password: "",
			passwordType: true,
			confirmPassword: null,
			confirmPasswordType: true,
			acceptance: null,
			formValidationError: false,
			registering: false,
			showRegistrationDialog: false,
			showRegistrationDuplicateDialog: false,
			showRegistrationForm: true,
			errormail: { show: false, content: "", hint: "" },
			errormobile: {
				show: false,
				content: "",
				hint: "We will only contact you about your <strong>duo</strong> account activity and the progress of rewards.",
			},
			postalAdressflag: false,
			fetchingPostalAddress: false,
			freeAgentList: [],
			unitPostalAddress: null,
			streetPostalAddress: null,
			suburbPostalAddress: null,
			statePostalAddress: null,
			postCodePostalAddress: null,
		};
	},
	watch: {
		agencyCode: function () {
			if (this.agencyCode) {
				this.agencyCode = this.agencyCode.toUpperCase();
			}
		},
	},
	mounted: function () {
		this.listAgencyGroup();
		this.listBannedEmail();
		this.listBannedAgencyCode();
		this.getFreeAgentAgency();
	},
	created: function () {
		this.debouncedCheckEmail = lodash.debounce(this.checkEmail, 500);
		this.debouncedCheckMobileNumber = lodash.debounce(this.checkMobileNumber, 500);
		this.debouncedFindAgency = lodash.debounce(this.findAgency, 500);
		this.debouncedCheckFreeAgent = lodash.debounce(this.checkFreeAgent, 500);
		this.debouncedValidatePostalAddress = lodash.debounce(this.validatePostalAddress, 500);
	},
	methods: {
		ruleEmail(v) {
			// Allow certain email that ends with asterisk (*) , so need to remove for debouncedCheckEmail
			let isAllowedRegEmail = this.$root.isAllowedRegEmail(v) ? true : false;
			this.debouncedCheckEmail(isAllowedRegEmail ? v.slice(0, -1) : v);
			if (!!v) {
				if (isAllowedRegEmail) {
					return true;
				} else if (v.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/)) {
					let type = null;
					let prefix = v.slice(v.indexOf("@"));
					for (let o of this.emailBannedList) {
						if (prefix.indexOf(o.prefix) == 0) {
							type = o.type;
							break;
						}
					}
					if (type) {
						if (type === "Personal") {
							return "duo cannot accept personal email address, please enter a work email address. If this is your work email address please contact duosupport@airnz.co.nz for assistance.";
						}
						return "This email domain cannot be accepted. The duo program is available to Australian travel agents only.";
					} else {
						return true;
					}
				} else {
					return "Please enter a valid email address";
				}
			} else {
				return "Please enter a valid email address";
			}
		},
		checkEmail(v) {
			this.emailHint = "";
			if (!v) {
				return;
			}
			this.emailFetching = true;
			axios
				.post("/system/authentication/email/check", { email: v })
				.then((response) => {
					if (response.data == true) {
						this.emailHint = 'Great News! This email address is already registered to duo, login to check out what’s new. Click  <a href="/#/" style="font-size:unset;color: rgb(6,93,119)  !important;">here</a> to continue to login page.';
					}
				})
				.catch(() => {})
				.finally(() => {
					this.emailFetching = false;
				});
		},
		checkMobileNumber(v) {
			this.mobileNumberHint = "";
			if (!v) {
				return;
			}
			this.mobileNumberFetching = true;
			axios
				.post("/system/authentication/mobile/check", {
					mobileNumber: v,
				})
				.then((response) => {
					if (response.data == true) {
						this.mobileNumberHint =
							'Great News! This contact number is already registered to <b>duo</b>, login to check out what’s new. Click <a href="/#/" style="font-size:unset;color: rgb(6,93,119)  !important;">here</a> to continue to login page.';
					}
				})
				.catch(() => {})
				.finally(() => {
					this.mobileNumberFetching = false;
				});
		},
		validatePostalAddress(v) {
			if (!v) {
				return;
			}
			if (this.selectedAddress == v) {
				// user just selected an address from addressify, don't bother going to backend
				return;
			}
			if (this.postalAdressflag == true) {
				return;
			}
			this.fetchingPostalAddress = true;
		},
		ruleName(v) {
			if (!!v) {
				if (v.match(/[^A-Za-z_.-\s]/)) {
					return "Only characters allowed";
				} else {
					return true;
				}
			} else {
				return "Only characters allowed";
			}
		},
		ruleAgencyCode(v) {
			this.agencyCodeValid = false;
			this.bannedAgencyCode = false;
			this.debouncedFindAgency(v);
			if (!!v) {
				if ((v.length == 3 || v.length == 4 || v.length == 6 || v.length == 9) && v.match(/^[a-z0-9]+$/i)) {
					this.agencyCodeValid = true;
					if (this.agencyCodeBannedList.includes(v)) {
						this.bannedAgencyCode = true;
						return "This PCC has been recognised as a ticketing agency and cannot be accepted. The duo program is not currently available to ticketing agents.";
					} else {
						return true;
					}
				} else {
					return "A valid PCC must be a combination of 3,4,6 or 9 numbers and characters";
				}
			} else {
				return "Enter a valid PCC";
			}
		},
		findAgency(v) {
			// form is being validated, do not call back-end
			if (this.registering) {
				this.registering = false;
				return;
			}
			this.agencyCodeHint = "";
			if (!v) {
				return;
			}
			if ((v.length == 3 || v.length == 4 || v.length == 6 || v.length == 9) && v.match(/^[a-z0-9]+$/i)) {
				if (this.agencyCodeBannedList.includes(v)) {
					return;
				}
			} else {
				return;
			}
			this.agencyCodeFetching = true;
			axios
				.post("/system/common/agency/find", {
					code: v,
				})
				.then((response) => {
					// have we ever fetched ever?
					this.agencyCodeFetched = true;
					let data = response.data;

					if (data === "Invalid") {
					} else if (data === "Banned") {
					} else if (data && data.id) {
						this.agencyId = data.id;
						this.agencyGroupId = data.agencyGroupId;
						this.agencyName = data.name;

						this.unitAgencyAddress = data.unit;
						this.streetAgencyAddress = data.street;
						this.suburbAgencyAddress = data.suburb;
						this.stateAgencyAddress = data.state;
						this.postCodeAgencyAddress = data.postCode;

						if (this.suburbAgencyAddress == "" || this.streetAgencyAddress == "" || this.postCodeAgencyAddress == "") {
							this.manualEditAddress = true;
						}

						this.agencyCodeMatch = true;
						this.agencyCodeHint =
							"We have successfully matched your PCC in our system and updated your agency details below. Please check the details are correct. All fields highlighted grey cannot be amended, please contact duosupport@airnz.co.nz if they are incorrect.";
						this.checkFreeAgent();
					} else {
						this.agencyGroupId = null;
						this.agencyName = null;

						this.unitAgencyAddress = null;
						this.streetAgencyAddress = null;
						this.suburbAgencyAddress = null;
						this.stateAgencyAddress = null;
						this.postCodeAgencyAddress = null;

						this.agencyCodeMatch = false;
						this.agencyCodeHint = "Sorry, we do not have your PCC. Please email duosupport@airnz.co.nz and we will get you set up.";

						this.checkFreeAgent();
					}
				})
				.catch(() => {})
				.finally(() => {
					this.agencyCodeFetching = false;
				});
		},
		listBannedEmail() {
			axios
				.get("/system/common/email/banned/list")
				.then((response) => {
					this.emailBannedList = response.data;
				})
				.catch(() => {});
		},
		listBannedAgencyCode() {
			axios
				.get("/system/common/agency/code/banned/list")
				.then((response) => {
					this.agencyCodeBannedList = response.data;
				})
				.catch(() => {});
		},
		listAgencyGroup() {
			axios
				.get("/system/common/agency/group/list")
				.then((response) => {
					this.agencyGroupList = response.data;
				})
				.catch(() => {});
		},
		register() {
			this.formValidationError = false;
			this.registering = true;
			let isValid = this.$refs.form.validate();
			setTimeout(() => {
				if (isValid) {
					axios
						.post("/system/authentication/register", {
							title: this.title,
							firstName: this.firstName,
							lastName: this.lastName,
							email: this.email,
							mobileNumber: this.mobileNumber,
							mobileAgent: this.mobileAgent,
							agencyId: this.agencyId,
							agencyCode: this.agencyCode,
							agencyGroupId: this.agencyGroupId,
							agencyName: this.agencyName,
							manualEditAddress: this.manualEditAddress,
							unit: this.unitAgencyAddress,
							street: this.streetAgencyAddress,
							suburb: this.suburbAgencyAddress,
							state: this.stateAgencyAddress,
							postCode: this.postCodeAgencyAddress,
							password: this.password,
							confirmPassword: this.confirmPassword,
							acceptance: this.acceptance,
							personalAddress: true,
							unitPersonal: this.unitPostalAddress,
							streetPersonal: this.streetPostalAddress,
							suburbPersonal: this.suburbPostalAddress,
							statePersonal: this.statePostalAddress,
							postCodePersonal: this.postCodePostalAddress,
							statePostalAddress: this.statePostalAddress,
						})
						.then(() => {
							this.showRegistrationDialog = true;
							this.showRegistrationForm = false;
						})
						.catch((error) => {
							// refactor
							if (error.response.data.errorList[0].includes("has been registered")) {
								if (this.mobileNumberHint.length > 0 || this.emailHint.length > 0) {
									this.showRegistrationDuplicateDialog = true;
									this.showRegistrationForm = false;
								} else {
									this.formValidationError = true;
								}
							} else {
								// this.$store.commit("showNotification", error.response.data);
							}
						});
				} else {
					if (this.mobileNumberHint.length > 0 || this.emailHint.length > 0) {
						this.showRegistrationDuplicateDialog = true;
						this.showRegistrationForm = false;
					} else if (!this.agencyCodeMatch) {
						this.$store.commit("showNotification", "PCC Code doesn't match");
					} else {
						this.formValidationError = true;
					}
				}
			}, 500);
		},
		getFreeAgentAgency() {
			axios
				.get("/system/common/agency/list/free/agent")
				.then((response) => {
					this.freeAgentList = response.data;
				})
				.catch(() => {});
		},
		checkFreeAgent() {
			this.freeAgentList.forEach((element) => {
				if (element.agencyGroupId == this.agencyGroupId && element.name == this.agencyName) {
					this.mobileAgent = true;
				} else if (element.agencyGroupId == this.agencyGroupId && element.name == "freeText") {
					this.mobileAgent = true;
				}
			});
		},
	},
	computed: {
		passwordStrength() {
			let data = {
				message: "Weak",
				isVeryWeak: false,
				isWeak: false,
				isFair: false,
				isStrong: false,
			};
			const regex = /^(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/g;
			let isSpecial = this.password.match(regex);
			data.isWeak = this.password.length >= 1 && this.password.length < 8 ? true : data.isWeak;

			data.message = data.isWeak ? "Weak" : data.message;
			// data.isWeak = isSpecial || this.password.length >= 8 ? true : data.isWeak;
			// data.message = data.isWeak ? "Weak" : data.message;
			data.isStrong = isSpecial && this.password.length >= 8 ? true : data.isStrong;
			data.message = data.isStrong ? "Strong" : data.message;

			return data;
		},
	},
};
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	--webkit-box-shadow: 0 0 0 30px none inset !important;
	background-color: none !important;
}
input:-internal-autofill-selected {
	background-color: none !important;
}
.v-input input {
	font-size: 17px !important;
}
div.v-messages__message {
	line-height: 18px;
	overflow-wrap: break-word;
}
.btn-register.theme--light.v-btn {
	width: 192px !important;
	height: 50px !important;
	font-size: 17px !important;
	color: #ffffff !important;
	background-color: #00b0b9 !important;
	border-radius: 4px;
	opacity: 1;
	text-transform: none !important;
	letter-spacing: 0;
	padding-bottom: 1px;
}
.duo-hint {
	font-size: 12px;
	line-height: 1.5;
	color: #2d2926;
}
.v-messages__message {
	font-size: 10px;
	line-height: 12px !important;
	/* color: #2d2926; */
}
.registration-divider.theme--light.v-divider {
	border-color: #c6c6c6;
}
</style>
