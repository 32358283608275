<template>
	<v-app v-if="$vuetify.breakpoint.mdAndUp">
		<!-- Disabled for admin preview -->
		<v-navigation-drawer v-if="$store.state.principal.administrator == false" style="box-shadow: unset" :width="mini ? '43' : ''" app v-model="drawer" :mini-variant.sync="mini" absolute permanent temporary hide-overlay expand-on-hover>
			<v-list-item style="color: white !important; padding-left: 10px"></v-list-item>

			<v-list>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/dashboard" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-duo-dashboard"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/sales" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-anz-airplane"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>sales </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/training" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="duo-icon-training"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>training </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/resources" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-briefcase"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>resources </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/profile" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-anz-person"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>profile </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="logout" style="color: white !important" to="#" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-duo-exit"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Log out</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<template v-slot:append>
				<div class="logo-label-airnz pa-2" style="text-align: center">
					<img src="/icon/label-airnz.svg" style="width: 180px; align-self: center" />
				</div>
				<div class="logo-airnz pa-4" style="text-align: center">
					<!-- <v-icon class="icon-anz-logo" style="color: white"></v-icon> -->
					<v-img src="/icon/logo-airnz.svg"></v-img>
				</div>
			</template>
		</v-navigation-drawer>
		<v-main :style="{
				paddingLeft: '50px',
				paddingBottom: '0px',
				display: 'flex',
				flexDirection: 'column',
			}" class="pt-0 mt-0" v-if="$router.currentRoute.path.startsWith('/page/')">
			<router-view :key="$route.fullPath"></router-view>
		</v-main>
		<v-main
			v-else
			style="padding-left: 30px; padding-bottom: 0px; height: 100%; display: flex; flex-direction: column; background-repeat: no-repeat; background-size: cover; background-position: center top; "
			:style="{
				backgroundImage: $route.fullPath.includes('/dashboard') ? 'url(https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/AirNZ.BG.jpg)' : 
					$route.fullPath.includes('/sales') ? 'url(https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/MySales.BG.jpg)' :
					$route.fullPath.includes('/training') ? 'url(https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/Training.BG.jpg)' :
					$route.fullPath.includes('/resources') ? 'url(https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/MySales.BG.jpg)' :
					$route.fullPath.includes('/TradeToolkit') ? 'url(https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg)' :
					$route.fullPath.includes('/profile') ? 'url(https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/Profile.BG.jpg)' :
					'url(https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/AirNZ.BG.jpg)',
				backgroundSize: 'cover',
				backgroundPosition: 'center top',
				backgroundAttachment: 'fixed',
			}"
		>
		<!-- <v-main
			v-else
			:style="{
				paddingLeft: '50px',
				paddingBottom: '0px',
				backgroundImage: 'url(' + backgroundPicture() + ')',
				backgroundSize: 'cover',
				backgroundPosition: backgroundPicture().includes('AgencySupport.BG.jpg') ? 'center top' : 'center bottom',
				backgroundAttachment: 'fixed',
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
			}"
		> -->
			<!-- <v-container style="min-width: 102%;" :style="{'backgroundColor' : $route.path.includes('/training/module') ? '#000000' : 'transparent' }"> -->
				<v-container style="min-width: 102%;" :style="{'backgroundColor' : $route.path.includes('/training/module') ? this.$store.state.trainingModuleBgColor : 'transparent' }">
				<div v-if="$router.currentRoute.path != '/faqs'" class="navbar d-flex flex-wrap pt-5">
					<img v-if="this.$router.currentRoute.path.includes('/training/module')" style="width: 216px" class="pl-10" :src="$bucket + '/image/logo/logo-duo-reverse-white_color.svg'" @click="$store.commit('debug')" />
					<img v-else style="width: 216px" class="pl-10" :src="$bucket + '/image/logo-duo-reverse.svg'" @click="$store.commit('debug')" />

					<img v-if="this.$router.currentRoute.path.includes('/training/module')" style="width: 182px" class="mr-10" :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
					<img v-else style="width: 182px" class="mr-10" :src="$bucket + '/image/logo-airnz.svg'" />
				</div>
				<div v-else class="navbar d-flex flex-wrap pt-5">
					<img style="width: 216px" :src="$bucket + '/image/logo/logo-duo-reverse-white_color.svg'" @click="$store.commit('debug')" />
					<img style="width: 182px" :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
				</div>
				<router-view :key="$route.fullPath"></router-view>
			</v-container>
			<v-container style="margin-top:0px;">
				<duoFooter />
			</v-container>
		</v-main>

		<notification></notification>

		<img v-show="$root.loader.value" src="loader.svg" class="loader" />
	</v-app>

	<menu-mobile v-else></menu-mobile>
</template>

<style scoped>
.v-navigation-drawer--mini-variant .logo-label-airnz {
	display: none;
}

.v-navigation-drawer--is-mouseover .logo-airnz {
	display: none;
}

a.v-list-item {
	padding: 0 !important;
	height: 56px;
}
.v-app-bar {
	background: rgba(255, 255, 255, 0) !important;
}
.navbar {
	display: flex;
	justify-content: space-between !important;
	align-items: center;
	padding: 0 10px;
	width: 100%;
}
.v-navigation-drawer {
	height: 100% !important;
	position: fixed;
	background: #2d2926;
}
.v-list > .v-list-item:hover,
.v-list > .v-list-item:active {
	background-color: #440099 !important;
	text-decoration: none !important;
}
.v-list-item > .v-list-item__icon {
	margin-top: 20px;
}
.v-list-item:hover > .v-list-item__icon {
	background-color: #221551 !important;
	min-width: 56px;
	height: 56px;
	padding-right: 15px !important;
	margin: 0 13px 0 auto !important;
}
.v-list-item--link:before,
.v-list-item--active {
	background-color: #440099;
}
.v-list-item--active > .v-list-item__icon {
	background-color: #221551 !important;
	min-width: 56px;
	height: 56px;
	padding-right: 15px !important;
	margin: 0 13px 0 auto !important;
}
.v-list-item__icon > i {
	margin-left: 20px;
	font-size: 17px;
}
.v-application .primary--text {
	color: white !important;
	caret-color: white;
}
a.v-list-item:hover {
	text-decoration: none !important;
	background-color: #440099 !important;
}
a:hover {
	text-decoration: none !important;
	color: #440099 !important;
	background: none;
}
i:hover {
	text-decoration: none !important;
}
</style>

<script>
import duoFooter from "./duoFooter.vue";
import notification from "./notification.vue";
import menuMobile from "./mobile/menuMobile.vue";
/* eslint-disable no-console */
export default {
	components: {
		duoFooter,
		notification,
		menuMobile
	},
	data() {
		return {
			drawer: true,
			showProfile: false,
			mini: true,
		};
	},
	watch: {
		mini: function (o) {
			if (o) {
				this.showProfile = false;
			}
		},
	},
	methods: {
		backgroundPicture() {
			if (this.$router.currentRoute.path == "/dashboard") {
				return this.$bucket + "/image/background/AirNZ.BG.jpg";
			} else if (this.$router.currentRoute.path == "/bdm" 
			|| this.$router.currentRoute.path == "/TradeToolkit" 
			|| this.$router.currentRoute.path == "/TimeOutBeforeTakeOff" 
			|| this.$router.currentRoute.path == "/MTATravelAugustIncentive" 
			|| this.$router.currentRoute.path == "/CreditsAndRefunds"
			|| this.$router.currentRoute.path.includes("/SeatsToSuit")
			|| this.$router.currentRoute.path == "/EconomyStretch" 
			|| this.$router.currentRoute.path == "/travelManagersConference" 
			|| this.$router.currentRoute.path == "/BoundForTheBigApple" 
			|| this.$router.currentRoute.path == "/cabinOfPossibility" 
			|| this.$router.currentRoute.path == "/newYork" 
			|| this.$router.currentRoute.path == "/discoverNorthAmerica" 
			|| this.$router.currentRoute.path == "/takeFlightWithDuo" 
			|| this.$router.currentRoute.path == "/winYourWayToNewYorkState" 
			|| this.$router.currentRoute.path == "/mtaTravelIncentive" 
			|| this.$router.currentRoute.path == "/travelManagersIncentive" 
			|| this.$router.currentRoute.path == "/destinationDashLanding" 
			|| this.$router.currentRoute.path == "/destinationDashTermCondition" 
			|| this.$router.currentRoute.path == "/destinationDashAuckland" 
			|| this.$router.currentRoute.path == "/destinationDashChristchurch" 
			|| this.$router.currentRoute.path == "/destinationDashQueenstown" 
			|| this.$router.currentRoute.path == "/christmasChase" 
			|| this.$router.currentRoute.path == "/christmasChaseTermCondition" 
			|| this.$router.currentRoute.path == "/USAGetaway" 
			|| this.$router.currentRoute.path == "/quizTC" 
			|| this.$router.currentRoute.path == "/ChristchurchCalling" 
			|| this.$router.currentRoute.path == "/ChristchurchCallingTC" 
			|| this.$router.currentRoute.path == "/NewSkiLand" 
			|| this.$router.currentRoute.path == "/NewSkiLandTC" 
			|| this.$router.currentRoute.path == "/LuxeInLisbon" 
			|| this.$router.currentRoute.path == "/quiz") {
				return this.$bucket + "/image/background/BDM.Map.BG.jpg";
			} else if (this.$router.currentRoute.path == "/sales" || this.$router.currentRoute.path.includes("/resources")) {
				return this.$bucket + "/image/background/MySales.BG.jpg";
			} else if (this.$router.currentRoute.path == "/profile/personal" || this.$router.currentRoute.path == "/profile/company" || this.$router.currentRoute.path == "/profile/accountsetting") {
				return this.$bucket + "/image/background/EditDetails.BG.jpg";
			} else if (this.$router.currentRoute.path == "/agency") {
				return this.$bucket + "/image/background/AgencySupport.BG.jpg";
			} else if (this.$router.currentRoute.path == "/profile") {
				return this.$bucket + "/image/background/Profile.BG.jpg";
			} else if (this.$router.currentRoute.path == "/faqs") {
				return this.$bucket + "/image/background/FAQ.BG.jpg";
			} else if (this.$router.currentRoute.path == "/virtualCard") {
				return this.$bucket + "/image/background/Virtual.Card.BG.jpg";
			} else if (String(this.$router.currentRoute.path).startsWith("/training") || this.$router.currentRoute.path == "/seeMoreNZ" || this.$router.currentRoute.path == "/destinationDash") {
				return this.$bucket + "/image/background/Training.BG.jpg";
			}
			return this.$bucket + "/image/" + "background-violet.jpg";
		},
		logout() {
			this.$store
				.dispatch("logout")
				.then(() => {
					this.$router.push("/login");
				})
				.catch(() => {});
		}
	},
};
</script>
