<template>
  <v-main style="margin: 10px">
    <v-data-table :headers="headers" :items="agentList" :items-per-page="15" :search="keyword">
      <template v-slot:top>
        <v-row no-gutters style="padding: 20px 10px 0px">
          <v-col cols="12" sm="8">
            <div class="d-flex px-2">
              <h2 v-if="$store.state.principal.role != 'bdm'">Agent info</h2>
              <h2 v-else>My duo agents</h2>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="d-flex px-2">
              <v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details
                style="margin-right: 10px"></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-inline-flex justify-end align-center pt-0" cols="12">
            <div>
              <div class="filter-label">State</div>
              <v-autocomplete dense solo v-model="state" :items="stateFilter" label="State"
                append-icon="mdi-chevron-down" />
            </div>
            <div class="px-4">
              <v-btn medium color="primary" @click="getCSVAgentByState" style="text-transform: none;"><v-icon
                  left>mdi-export</v-icon> Export Agent Info Report</v-btn>
              <v-btn v-if="$store.state.principal.role != 'super_user'" medium color="primary" @click="getCSVAgent"
                style="text-transform: none;"><v-icon left>mdi-export</v-icon> Export Managed Agent Info Report</v-btn>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.name="{ item }">{{
        item.firstName + " " + item.lastName
      }}</template>

      <template v-slot:item.tag="props">
        <v-edit-dialog :return-value.sync="props.item.transitMap.tag" large persistent @save="saveTag(props.item.id)"
          @cancel="cancel" @open="open(props.item)" @close="close">
          <v-row class="text-center my-1">
            <div class="state-badge py-1 px-3 secondary text-no-wrap rounded-pill"
              v-for="tag of props.item.transitMap.tag" :key="tag">
              {{ tag }}
            </div>
            <v-icon>mdi-plus-circle-multiple-outline</v-icon>
          </v-row>
          <template v-slot:input>
            <div style="min-width: 400px">
              <div class="mt-4 mb-2 text-h6">Update Tag</div>
              <div class="d-inline-flex" style="width: 100%" v-for="(inputTag, indexTag) in tagList" :key="indexTag">
                <v-btn @click="removeTag(indexTag)" color="red" icon dark>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-text-field v-model="tagList[indexTag]" :rules="[(v) => !!v || 'This field is required']" label="Tag"
                  style="margin-right: 10px" outlined></v-text-field>
                <v-btn @click="removeTag(indexTag)" color="red" icon dark>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
              <div>
                <v-btn @click="addTag()" color="primary darken-1" small :disabled="!tagList[tagList.length - 1] != ''">Add
                  Tag</v-btn>
              </div>
            </div>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end pr-4">
          
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-icon class="mr-2" v-on="{ ...tooltip }" v-if="$store.state.principal.role == 'super_user' && item.status == 'Pending'" @click="resendEmail(item)">mdi-email</v-icon>
            </template>
            <span>Resend Activation Email</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-icon class="mr-2" v-on="{ ...tooltip }" v-if="$store.state.principal.role == 'super_user' && item.status != 'Pending'" @click="showImpersonateDialog(item)
                ">mdi-login-variant</v-icon>
            </template>
            <span>Impersonate</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-icon class="mr-2" v-on="{ ...tooltip }" v-if="$store.state.principal.role == 'super_user' && item.status != 'Pending'" @click="onConfirmChange(item)
                ">{{ item.status == 'Active' ? 'mdi-cancel' : 'mdi-check-circle-outline' }}</v-icon>
            </template>
            <span>{{ item.status == 'Active' ? 'Deactivate Agent' : 'Activate Agent' }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-icon class="mr-2" v-on="{ ...tooltip }" @click="$router.push(`/administration/agent/info/${item.id}/details`)
              ">mdi-account-group-outline</v-icon>
            </template>
            <span>Agent Info</span>
          </v-tooltip>  
          
        </div>
      </template>
    </v-data-table>
    <confirmation-dialog :title="confirmationDialog.title" :show="confirmationDialog.show"
      :content="confirmationDialog.content" @onCloseDialog="confirmationDialog.show = false" @onClickConfirm="changeStatus" />
    <dialog-impersonate :show="dialogImpersonate.show" :user="dialogImpersonate.item"
      @onCloseDialog="dialogImpersonate.show = false" />

    <v-snackbar
			v-model="snackbar"
			:timeout="800"
			color="success"
			variant="outlined"
		>
			Email sent
		</v-snackbar>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from "axios";
import confirmationDialog from "./dialog/confirmationDialog.vue";
import dialogImpersonate from "./dialog/dialogImpersonate.vue";

export default {
  components: {
    confirmationDialog,
    dialogImpersonate,
  },
  data: function () {
    return {
      snackbar: false,
      agencyGroupId: 0,
      keyword: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Account Name", value: "agencyName" },
        { text: "Account Manager", value: "bdmAdminName" },
        { text: "State", value: "agencyState", filter: f => { return (f + '').toLowerCase().includes(this['state'].toLowerCase()) } },
        { text: "Email", value: "email" },
        //{ text: "Store", value: "storeBranch" },
        { text: "Agency Code", value: "agencyCode" },
        { text: "Status", value: "status" },
        { text: "Tag", value: "tag" },
        { text: "", value: "action", sortable: false },
      ],
      agentList: [],
      agent: { map: {} },
      entity: {
        map: {},
      },
      tagList: [],
      confirmationDialog: {
        show: false,
        item: {},
        title: '',
        content: ''
      },
      dialogImpersonate: {
        show: false,
        item: {},
      },
      state: "",
      stateFilter: [''],
    };
  },
  mounted: function () {
    this.getList();

    this.stateFilter = this.$store.state.principal.role != 'bdm' ? ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'] : this.$store.state.principal.states;
    this.stateFilter.unshift('');
  },
  methods: {
    resendEmail(item) {
      axios
        .post("/system/authentication/registration/resend", { email: item.email })
        .then((response) => {
          if (response.status == 200) {
            this.snackbar = true;
          }
      });
    },
    showImpersonateDialog(item) {
      this.dialogImpersonate.item = item;
      this.dialogImpersonate.show = true;
    },
    onConfirmChange(item) {
      this.confirmationDialog.item = item;
      let status = this.confirmationDialog.item.status == 'Active' ? 'deactivate this account?' : 'set this account as active?'
      this.confirmationDialog.content = 'Are you sure you want to ' + status + ' <br/> <br/><b>' + item.firstName + ' ' + item.lastName + '<br/>' + item.email + ' ' + '<br/></b>';
      this.confirmationDialog.show = true;
    },
    onCloseConfirmationDialog() {
      this.confirmationDialog.show = false;
      this.confirmationDialog.content = '';
      this.confirmationDialog.item = {};
    },
    changeStatus() {
      let status = this.confirmationDialog.item.status == 'Active' ? 'Inactive' : 'Active'
      axios
        .post("/system/person/status/change", { entity: { id: this.confirmationDialog.item.id, status: status } })
        .then((response) => {
          if (response.status == 200) {
            this.getList();
            this.onCloseConfirmationDialog();
          }
        });
    },
    getList() {
      axios
        .get("/system/person/info/list")
        .then((response) => {
          this.agentList = response.data;
        })
        .catch(() => { });
    },
    open(item) {
      this.agent = item;
      this.tagList =
        !this.agent.transitMap.tag || this.agent.transitMap.tag.length == 0
          ? [""]
          : this.agent.transitMap.tag;
    },
    addTag() {
      this.tagList.push("");
    },
    removeTag(index) {
      this.tagList.splice(index, 1);
    },
    saveTag(id) {
      for (let idx in this.tagList) {
        if (this.tagList[idx] == "") {
          this.tagList.splice(idx, 1);
        }
      }

      axios
        .post("/system/person/tag/save", { id: id, tag: this.tagList })
        .then(() => {
          this.getList();
        });
    },
    cancel() { },
    close() { },
    getCSVAgent() {
      window.open(axios.defaults.baseURL + "/system/person/agent/info/export");
    },
    getCSVAgentByState() {
      window.open(axios.defaults.baseURL + "/system/person/agent/info/export?filter=state");
    }
  },
};
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}

.filter-label {
  font-size: 12px;
  padding-top: 9px;
}</style>