<template>
	<v-container fluid>
		<subHeader />

		<!--ADD BOOKING-->
		<v-row class="d-flex justify-center align-center">
			<v-container class="container-pnr">
				<h2 class="duo" style="font-family: AirNZ Sans Regular !important; font-size: 28px !important; color: black !important">
					Add booking to
					<span style="font-family: AirNZ Sans Semibold !important">duo</span>
				</h2>
				<p class="duo" style="font-size: 17px; margin-bottom: 30px; color: black !important">
					To enter a <b>duo</b> campaign and to track your sales, add your Air New Zealand PNR below. Once received, the status of each booking will be updated in your my<b>sales</b> table below. Refer to the terms and conditions of participating campaigns for entry criteria.
				</p>

				<v-row no-gutters v-for="(booking, index) in bookingList" :key="index" class="custom-v-card mt-6 mb-2 elevation-0" align="center" justify="center" style="border-radius: 6px">
					<v-col class="d-flex flex-column">
						<!-- action -->
						<v-row no-gutters class="d-flex flex-row" justify="space-between" style="padding: 24px 24px 15px 24px">
							<v-col class="px-1" cols="auto" xl="2" lg="2" md="2" sm="4" xs="12" style="font-family: AirNZ Sans Semibold; font-size: 18px; line-height: 21px; color: black !important">
								<strong>New PNR</strong>
							</v-col>
							<v-spacer></v-spacer>
							<v-col cols="10" sm="8" class="d-none d-sm-block">
								<v-row no-gutters v-if="index == 0" class="px-0" justify="end">
									<span style="color: black !important; font-size: 17px" class="mr-2 duo d-none d-md-block pt-2">Add PNR</span>
									<v-btn id="addPnrBtn" @click="addPNR()" class="btn-add-pnr" tile icon color="primary">
										<v-icon color="white" size="26px">mdi-plus</v-icon>
									</v-btn>
								</v-row>
								<v-row no-gutters v-else class="px-0" justify="end">
									<v-btn tile small icon color="primary" id="deletePnrBtn" @click="deletePNR(index)" class="no-background-hover mr-0">
										<v-img class="icon-trash" src="/icon/icon-trash.svg" contain width="20" height="20"></v-img>
									</v-btn>
								</v-row>
							</v-col>
						</v-row>
						<!-- action end -->

						<v-form ref="form" class="mb-2" v-model="valid" autocomplete="off" lazy-validation :style="{ margin: '0 auto', display: 'block', width: '100%' }">
							<v-row no-gutters class="row-pnr-input d-flex justify-space-between pr-1">
								<!-- pnr boxes -->
								<v-col >
									<v-pnr-input
										class="my-2 d-flex justify-center"
										ref="pnrCodeInput"
										:input-classes="String('pnr-input' + ' ' + index)"
										separator=" "
										:bookingCode="bookingCode"
										:num-inputs="5"
										:should-auto-focus="true"
										@on-change="handleOnChange"
										@on-complete="handleOnComplete"
										@on-blur="handleOnBlur(index + 1)"
									/>
									<span :id="String(index + 1)" class="error--text pl-5 mt-n6 duo"></span>
								</v-col>
								<!-- pnr boxes end -->

								<!-- checkbox booking group -->
								<v-col class="pb-10 px-2" hidden>
									<v-row no-gutters align="center" justify="center" style="min-width: 280px; height: 100%">
										<v-checkbox id="bookingGroup" v-model="booking.bookingGroup">
											<template v-slot:label>
												<span style="font-size: 17px; color: black !important">Is this part of a group booking?</span>
											</template>
										</v-checkbox>
									</v-row>
								</v-col>
								<!-- checkbox booking group end -->

								<!-- datepicker -->
								<v-col class="px-5" style="min-width: 200px">
									<v-row no-gutters>
										<v-menu ref="datePickerCreation" v-model="booking.menu" :close-on-content-click="false" :return-value.sync="booking.map.creationDate" transition="scale-transition" offset-y max-width="320px">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													id="pnrCreationDate"
													:v-model="booking.map.creationDate"
													:value="parseDate(booking.map.creationDate)"
													readonly
													v-on="on"
													v-bind="attrs"
													:rules="[(v) => !!v || 'Enter a PNR Creation Date']"
													@click:prepend="booking.menu = true"
												>
													<template v-slot:append>
														<img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
													</template>
													<template v-slot:label>
														<span style="font-size: 17px; color: black !important">PNR creation date</span>
													</template>
												</v-text-field>
											</template>
											<v-date-picker v-model="booking.map.creationDate" :allowed-dates="allowedDates" :max="currentDate" no-title scrollable style="width: 100%">
												<v-row no-gutters justify="space-around" class="pa-4">
													<v-btn width="120" class="mx-0" outlined color="primary" @click="$refs.datePickerCreation[index].save(booking.map.creationDate)">Confirm</v-btn>
													<v-btn width="120" class="mx-0" outlined @click="booking.menu = false">Cancel</v-btn>
												</v-row>
											</v-date-picker>
										</v-menu>
									</v-row>
									<v-row no-gutters class="d-flex flex-row" justify="end">
										<v-btn class="ma-0 pa-0 mr-n2" text @click="resetFormPnr(booking, index)"> Clear all </v-btn>
									</v-row>
								</v-col>
								<!-- datepicker end -->

								<!-- action small -->
								<v-col cols="12" class="d-block d-sm-none mt-4 pr-1" align-self="center">
									<v-row no-gutters v-if="index == 0" class="px-0" cols="auto" align="center" justify="end">
										<span style="color: black !important; font-size: 17px" class="mr-2 d-none d-md-block pt-1">Add PNR</span>
										<v-btn id="addPnrBtnSm" @click="addPNR()" style="background-color: #221551 !important; border-radius: 5px; color: white !important" tile icon color="primary">
											<v-icon class="icon-duo-plus"></v-icon>
										</v-btn>
									</v-row>
									<v-row no-gutters v-else class="px-0" cols="auto" align="center" justify="end">
										<v-btn tile small icon color="primary" id="deletePnrBtnSm" @click="deletePNR(index)" class="no-background-hover">
											<v-img class="icon-trash" src="/icon/icon-trash.svg" contain width="20" height="20"></v-img>
										</v-btn>
									</v-row>
								</v-col>
								<!-- action small end -->
							</v-row>
						</v-form>
					</v-col>
				</v-row>
				<v-row justify="end" v-if="bookingList.length > 0">
					<v-btn :disabled="!valid" outlined elevation="0" id="submitPnr" class="btn-enter" @click="submitAddBooking()">Enter</v-btn>
					<!--					<v-btn @click="resetValidation()" outlined class="btn-cancel my-3">Cancel</v-btn>-->
				</v-row>
			</v-container>
		</v-row>

		<!-- MY SALES TABLE -->
		<v-row class="d-flex justify-center align-center">
			<mySales @on-change="handleOnChangeTable"></mySales>
		</v-row>

		<!--END OF ADD BOOKING-->
	</v-container>
	
</template>

<style scoped>
.container-pnr {
	width: 80%;
}
.v-btn--text {
	text-transform: none !important;
	text-decoration: underline;
	letter-spacing: 0px;
}
.btn-enter {
	width: 162px !important;
	height: 46px !important;
	border: 1px solid #00b0b9;
	background-color: #00b0b9 !important;
	border-radius: 4px;
	opacity: 1;
	color: white !important;
	text-transform: none !important;
	font-size: 17px !important;
	letter-spacing: 0px;
}
.btn-cancel {
	width: 162px !important;
	height: 46px !important;
	border: 1px solid black;
	border-radius: 6px;
	color: black !important;
	opacity: 1;
	text-transform: none !important;
}
.theme--light.v-label {
	color: black !important;
	font-size: 17px !important;
}
.btn-add-pnr {
	width: 40px !important;
	height: 40px !important;
	margin: 0px !important;
	background-color: #440099 !important;
	border-radius: 5px;
	color: white !important;
}
.pnr-input {
	width: 50px;
	height: 45px;
	/* padding: 5px; */
	margin: 0 3px;
	font-size: 20px;
	border-radius: 4px;
	border: 1px solid rgb(0, 0, 0);
	text-align: center;
}
.no-background-hover::before {
	background-color: transparent !important;
}
.error--text {
	font-family: "AirNZ Sans Regular", arial, sans-serif !important;
	color: #ec040f !important;
	font-size: 12px !important;
}
/*
.v-application .reduce-box.mr-1 {
    margin-right: 20px !important;
}
*/
.reduce-box.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
	padding: 0 0px;
	min-height: unset;
}
.reduce-box fieldset {
	width: 50px;
	height: 50px;
}
.reduce-box .v-text-field__slot {
	width: 50px;
	height: 50px;
	margin-bottom: -5px;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
	background: #c9c9c9;
}
.custom-v-card {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0.9);
	color: rgba(0, 0, 0, 0.87);
	border-width: thin;
	max-width: 100%;
	outline: none;
	text-decoration: none;
	transition-property: box-shadow, opacity;
	overflow-wrap: break-word;
	position: relative;
	white-space: normal;
	min-height: 166px;
	opacity: 0.9;
}
.xl-add-pnr {
	position: absolute;
	top: 0;
	right: 0;
	margin: 35px 40px 0 0;
}
/* secondary */
button.duo-secondary > span {
	color: #008392;
}
button.duo-secondary:hover > span {
	color: #00626e;
}
button.duo-secondary {
	border: thin solid black !important;
	color: black !important;
}
button.duo-primary {
	border: thin solid #008392 !important;
}
button.duo-secondary .v-btn__content {
	color: black !important;
}
button.duo-secondary:hover .v-btn__content {
	color: #00626e !important;
}
button.duo-secondary:hover {
	border: thin solid #00626e !important;
}
button.duo-secondary::before {
	background-color: transparent !important;
}
.v-expansion-panel-header__icon {
	padding-right: 0px;
}
.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
	color: #440099 !important;
}
.row-pnr-input {
	padding: 0 15px 0 25px;
}
/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
	.container-pnr {
		width: 95%;
	}
}
@media (max-width: 600px) {
	.theme--light.v-label {
		color: black !important;
		font-size: 14px !important;
	}
	.row-pnr-input {
		padding: 0 10px 10px 10px;
	}
	.container-pnr {
		padding: 0px 5px;
	}
	.row-pnr-head {
		padding: 20px 10px 0;
	}
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
import subHeader from "../subHeader.vue";
import mySales from "../mySales.vue";
import pnrInput from "../pnrInput.vue";

export default {
	components: {
		subHeader,
		mySales,
		"v-pnr-input": pnrInput,
	},
	data() {
		return {
			bookingCode: "",
			date: new Date().toISOString().substr(0, 10),
			month_names: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			entity: { map: {} },
			titleList: [],
			valid: true,
			editProfilePage: { show: false },

			pnrCreationDate: false,
			id: 0,
			bookingList: [{ bookingCode: null, map: {}, error: false, bookingGroup: false }],
			isGroup: false,
			currentDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toJSON().slice(0, 10).replace(/-/g, "-"),
			allowedDates: (val) => val >= new Date(new Date().getTime() + -90 * 24 * 60 * 60 * 1000).toJSON().slice(0, 10).replace(/-/g, "-"),
			existingBookingList: [],
			validUniquePNRCreationDate: false,
		};
	},
	mounted: function () {
		this.profile();
		this.listTitle();
		this.getBookingList();

		this.$root.$on("add", (data) => {
			console.log("add ==>", data);
			this.EditPNR(data);
		});
	},
	methods: {
		handleOnChangeTable(value) {
			let index = this.existingBookingList.findIndex((el) => el.bookingCode === value.bookingCode);
			this.existingBookingList.splice(index, 1);
		},
		profile() {
			axios
				.get("/system/person/me")
				.then((response) => {
					this.entity = response.data;
				})
				.catch(() => {});
		},
		listTitle() {
			axios
				.get("/system/common/title/list")
				.then((response) => {
					this.titleList = response.data;
				})
				.catch(() => {});
		},
		//ADD BOOKING METHODS
		addPNR: function () {
			// if (this.bookingList.length < 9)
			this.bookingCode = "";
			this.bookingList.push({
				bookingCode: null,
				map: {},
				error: false,
				bookingGroup: false,
			});
		},
		EditPNR(payload) {
			this.bookingCode = payload.bookingCode.slice(0, -1);
			if (this.bookingList.filter((x) => x.bookingCode == payload.bookingCode).length == 0) {
				if (this.bookingList.length == 1) {
					if (this.bookingList[0].bookingCode == null) {
						this.bookingList[0] = {
							bookingCode: payload.bookingCode.slice(0, -1),
							map: { creationDate: payload.creationNewDate },
							error: false,
							bookingGroup: false,
						};
					}
					this.bookingCode = payload.bookingCode.slice(0, -1);
					this.$refs["pnrCodeInput"][0].handleOnEdit(this.bookingCode);
				} else {
					this.bookingList.push({
						bookingCode: payload.bookingCode.slice(0, -1),
						map: { creationDate: payload.creationNewDate },
						error: false,
						bookingGroup: false,
					});
				}
			}
			this.$emit("on-edit");
		},
		deletePNR: function (index) {
			this.bookingList.splice(index, 1);
			// if (index === 0) this.addPNR();
		},
		handleOnBlur: function (id) {
			let current = parseInt(id - 1);
			if (this.bookingList[current].bookingCode == null || this.bookingList[current].bookingCode.length != 5) {
				document.getElementById(id).textContent = "Enter your Air New Zealand PNR";
			}
		},
		getBookingList() {
			axios
				.get("/system/sales/booking/list/me")
				.then((response) => {
					this.existingBookingList = response.data;
				})
				.catch(() => {});
		},
		resetFormPnr(booking, index) {
			this.handleClearInput("pnrCodeInput", index);
			this.$refs.form[index].reset();
			this.bookingList.filter((x) => x.bookingCode !== booking.bookingCode);
			this.bookingList[index].map.creationDate = '';
		},
		resetValidation() {
			for (var i = 1; i <= this.bookingList.length; i++) {
				this.handleClearInput("pnrCodeInput", i - 1);
				this.$refs.form[i - 1].reset();
				document.getElementById(i).textContent = "";
			}
			while (this.bookingList.length != 1) {
				this.bookingList.pop();
			}
		},
		submitAddBooking() {
			try {
				this.checkPnrCodeValue();
				this.checkUniquePNRCreationDate();
				if (this.validUniquePNRCreationDate) {
					axios
						.post("/system/sales/add/booking", {
							// headers: {
							// 	"Content-Type": "multipart/form-data"
							// }
							bookingList: this.bookingList,
						})
						.then((response) => {
							this.$store.commit("showNotification", { title: "Awesome", content: "Once your PNR has been through the<br>validation process, the booking status in<br>your my<b>sales</b> table will be updated.", color: "#00B0B9" });
							this.bookingList = [{ bookingCode: null, map: {}, error: false, bookingGroup: false }];
							this.$root.$emit("update");
							this.getBookingList();
							this.resetValidation();
						})
						.catch(() => {});
				} else {
					this.$store.commit("showNotification", { title: "Error", content: "Looks like you’ve already entered this PNR.<br>Please check your entry details.", color: "#440099" });
				}
			} catch (e) {
				this.$store.commit("showNotification", { content: e });
			}
		},
		handleOnComplete(value, indexItem) {
			let pnrCount = this.bookingList.filter((x) => x.bookingCode == value).length;
			if (pnrCount > 1) {
				document.getElementById(parseInt(indexItem) + 1).textContent = "Looks like you’ve already entered this PNR. Please check your entry details.";
			} else {
				document.getElementById(parseInt(indexItem) + 1).textContent = "";
			}
			this.bookingList[indexItem].bookingCode = value;
		},
		handleOnChange(value, indexItem) {
			if (value) {
				this.bookingList[indexItem].bookingCode = value;
			} else if (value == undefined) {
				document.getElementById(parseInt(indexItem) + 1).textContent = "Only digit and alphabetical characters allowed";
			}
		},
		handleOnInput(event, booking) {
			booking.bookingCode = String(event).toUpperCase();
		},
		handleOnPaste(event, booking) {
			const pastedData = event.clipboardData.getData("text/plain").toUpperCase().toString();
			booking.bookingCode = String(booking.bookingCode + pastedData).substring(0, 5);
		},
		handleClearInput(ref, index) {
			this.$refs[ref][index].$el.value=null;
			if (ref === 'pnrCodeInput') {
				this.$refs[ref][index]['_data']['pnr']=['','','',''];
			}
		},
		checkPnrCodeValue() {
			for (var booking of this.bookingList) {
				if (booking.bookingCode == null || booking.bookingCode.length < 5) {
					throw "Field PNR is required";
				} else {
					booking.bookingCode = booking.bookingCode.substring(0, 5) + "H";
				}
			}
		},
		checkUniquePNRCreationDate() {
			this.validUniquePNRCreationDate = true;
			let indexBookingNow = 0;
			for (var booking of this.bookingList) {
				let indexBookingLoop = 0;
				this.bookingList.forEach((element) => {
					// if (indexBookingNow != indexBookingLoop && element.bookingCode == booking.bookingCode && element.map.creationDate == booking.map.creationDate) {
					if (indexBookingNow != indexBookingLoop && element.bookingCode == booking.bookingCode) {
						this.validUniquePNRCreationDate = false;
					}
					indexBookingLoop++;
				});
				indexBookingNow++;
			}
		},
		parseDate(date) {
			if (!date) return "";
			let day = date.substr(8, 10);
			let monthNumber = parseInt(date.substring(5, 7)) - 1;
			let year = date.substring(0, 4);
			return day + " " + this.month_names[monthNumber] + " " + year;
		},
	},
};
</script>
