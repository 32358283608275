<template>
	<v-main style="margin:0 10px;">
		<div style="height:10px;"></div>
		<v-data-table :headers="headers" :items="dynamicFormList" sort-by="id" :search="keyword"
			:footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }">
			<template v-slot:top>
				<v-row no-gutters style="padding:20px 10px 15px;">
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<h2>{{ $root.capitalizeFirst(title) }} list</h2>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details
								style="margin-right:10px;" />
							<v-btn small fab dark color="secondary" title="Add" @click="showAddDialog">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.description="{ item }">{{ $root.ellipsify(item.description, 100) }}</template>
			<template v-slot:item.active="{ item }">
				<v-icon v-if="item.active === true" color="green" title="Active">mdi-check</v-icon>
				<v-icon v-else color="red" title="Inactive">mdi-block-helper</v-icon>
			</template>
			<template v-slot:item.action="{ item }">
				<div class="d-flex flex-row">
					<v-icon
						@click="$router.push('/administration/creator/form/' + item.id)">mdi-clipboard-edit-outline</v-icon>
					<v-icon class="ml-2" @click="showUpdateDialog(item)">mdi-pencil</v-icon>
					<v-icon class="ml-2" @click="entity = item; confirmationDialog = true">mdi-delete</v-icon>
				</div>
			</template>
			<template v-slot:no-data>Empty list</template>
		</v-data-table>

		<v-dialog v-model="dialogUpdate" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">{{ entity.id ? "Update Form Info" : "Add New Form" }}</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form">
						<v-text-field v-model="entity.title" :rules="[(v) => !!v || 'Enter Title']" label="Title" />
						<v-textarea v-model="entity.description" label="Description" rows="3" :rules="[(v) => !!v || 'Enter Description']" />
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="closeDialog">Cancel</v-btn>
					<v-btn @click="save" color="primary">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<confirmation-dialog title="Confirm delete" :show="confirmationDialog"
			content="Once dynamic form deleted, all submitted answers by user will also be deleted <br/> Are you sure?"
			@onCloseDialog="entity = {}; confirmationDialog = false" @onClickConfirm="remove" />
	</v-main>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";
import confirmationDialog from "./dialog/confirmationDialog.vue";

export default {
	components: {
		confirmationDialog
	},
	mounted() {
		this.getList();
	},
	data() {
		return {
			headers: [
				{ text: "Title", value: "title" },
				{ text: "Description", value: "description" },
				{ text: "", value: "action", sortable: false },
			],
			keyword: "",
			entity: {},
			dynamicFormList: [],
			dialogUpdate: false,
			confirmationDialog: false,
		}
	},
	methods: {
		hostname() {
			return window.location.origin;
		},
		getList() {
			axios.get("/system/creator/form/list").then(response => {
				this.dynamicFormList = response.data;
			})
		},
		showAddDialog() {
			this.entity = {};
			this.dialogUpdate = true;
		},
		showUpdateDialog(item) {
			this.entity = { ...item };
			this.dialogUpdate = true;
		},
		closeDialog() {
			this.dialogUpdate = false;
			this.entity = {};
		},
		save() {
			if (this.$refs.form.validate()) {
				let url = this.entity.id ? "/system/creator/form/edit" : "/system/creator/form/add";
				axios.post(url, { entity: this.entity }).then(
					(response) => {
						if (response.status == 200) {
							this.getList();
							this.closeDialog();
						}
					}
				)
			}
		},
		remove() {
			axios.post('/system/creator/form/delete', { id: this.entity.id }).then(
				(response) => {
					if (response.status == 200) {
						this.getList();
						this.confirmationDialog = false;
						this.entity = {};
					}
				}
			)
		}
	}
}
</script>