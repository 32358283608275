import { render, staticRenderFns } from "./cabinOfPossibility.vue?vue&type=template&id=c20ac126&scoped=true&"
import script from "./cabinOfPossibility.vue?vue&type=script&lang=js&"
export * from "./cabinOfPossibility.vue?vue&type=script&lang=js&"
import style0 from "./cabinOfPossibility.vue?vue&type=style&index=0&id=c20ac126&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c20ac126",
  null
  
)

export default component.exports