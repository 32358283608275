<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center" style="margin-bottom: -1.5vw;" :style="[bgColor === 'purple' ? { backgroundColor: '#221551' } : bgColor === 'black' ? { backgroundColor: '#000000'} : { backgroundColor: '#FFFFFF' }]">
	<!-- <v-container fluid class="d-flex align-center pa-0 justify-center" style="margin-bottom: -1.5vw;" :style="{backgroundColor : parsedBackgroundColor}"> -->
		<v-row class="d-flex flex-wrap .duo-custom-template-full">
			<v-col cols="12" style="margin-top: 1vw;" v-if="!isPreview">
				<v-row class="d-flex justify-space-between align-center">
					<v-col class="duo-custom justify-left align-center">
						<div style="font-family: AirNZ Sans Regular !important; color: #FFFFFF;" class="pl-10"><h2>{{ title }}</h2></div>
					</v-col>
					<v-col cols="auto" class="justify-right">
						<v-row class="d-flex justify-right no-gutters mr-10">
							<v-col class="duo-custom pr-3 d-flex align-center">
								<div v-html="page.valueMap.headerIconTitle" class="text-right align-center" style="font-family: AirNZ Sans Regular !important; color: #FFFFFF;"></div>
							</v-col>
							<v-col cols="auto" class="d-flex align-end">
								<v-avatar v-if="$root.isBlank(page.valueMap.headerIcon)" :color="parsedHeaderIconBackgroundColor" size="58" class="ml-auto">
									<v-icon :color="parsedHeaderIconColor" size="27" class="duo-icon-training"></v-icon>
								</v-avatar>
								<v-img v-else :src="$base + '/asset/file/' + page.valueMap.headerIcon" width="60" class="ml-auto"></v-img>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12">
				<v-card width="100%" min-height="416" rounded="0" :elevation="0" :style="[bgColor === 'purple' ? { backgroundColor: '#221551' } : bgColor === 'black' ? { backgroundColor: '#000000'} : { backgroundColor: '#FFFFFF' }]">
				<!-- <v-card width="100%" min-height="416" rounded="0" :elevation="0" :style="{backgroundColor : parsedBackgroundColor}"> -->
					<v-card-text class="ma-0 pa-0">
						<v-form v-model="validForm" ref="form" lazy-validation>
							<v-container class="d-flex align-center" style="height: auto">
								<v-row class="px-0 py-0">
									<v-col cols="12" class="d-flex justify-left align-center text-wrap" style="padding: 0px;">
										<!-- Question with Picture -->
										<v-card
											class="d-flex justify-center align-center"
											style="border-radius: 0px; padding-left: 0px; margin-left: -10vw"
											:style="{backgroundImage: 'url(' + ($root.isBlank(imageNameQuestion) ? ($bucket + '/image/training-tiles/DuoTraining.jpg') : ('\'' + imageNameQuestion + '\'') ) + ')', backgroundSize: 'cover'}"
											min-width="110vw"
											min-height="400px"
											elevation="0"
											dark
										>
										<span v-if="!active" :style="{'marginLeft' : width < 1600 ? '-12.5vw' : '0vw'}" style="width: 50vw; padding:30px; line-height:40px; font-weight:bold; font-size: 35px; font-family: AirNZ Black Italic; display: table; list-style-position: inside;" v-html="question"></span>
										</v-card>
									</v-col>
									<v-item-group :style="{'marginLeft' : width < 1600 ? '-7.5vw' : '2vw'}" class="d-flex col-12 justify-space-between flex-wrap" v-model="optionSelected" :multiple="isMultipleAnswer">
									<!-- <v-item-group class="d-flex col-12" v-model="optionSelected" :multiple="isMultipleAnswer"> -->

										<div :class="'d-flex align-center px-0 py-0 ' + columnOption">
											<!-- Answers 1 with Pictures -->
											<v-item v-slot="{ active, toggle }">
												<v-card
													class="d-flex justify-center align-center"
													style="width: 250px; height: 250px; border-radius: 0px; margin:4px; border: 3px solid #C6C6C6;"
													:style="[{backgroundImage: 'url(' + ($root.isBlank(imageNameAnswer1) ? ($bucket + '/image/training-tiles/DuoTraining.jpg') : ('\'' + imageNameAnswer1 + '\'') ) + ')', backgroundSize: 'cover'}, 
																{borderColor: !isSelected ? 'white' : responseOptionId[0] == 1 ? responseOptionId[0] == correctAnswer ? '#04A0A8' : '#C73C09' : 1 == correctAnswer ? '#04A0A8' : '#FFFFFF'}]"
													width="100%"
													height="100%"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, 1, page.valueMap.feedback)"
												>
													<v-scroll-y-transition>
														<span style="padding: 30px; font-size: 30px; font-weight: bold; line-height: 30px;"><div v-html="answer1" style="display: table; margin: auto; list-style-position: inside;"></div></span>
													</v-scroll-y-transition>
												</v-card>

											</v-item>
										</div>

										<div :class="'d-flex align-center px-0 py-0 ' + columnOption">
											<!-- Answers 2 with Pictures -->
											<v-item v-slot="{ active, toggle }">
												<v-card
													class="d-flex justify-center align-center"
													style="width: 250px; height: 250px; border-radius: 0px; margin:4px; border: 3px solid #C6C6C6;"
													:style="[{backgroundImage: 'url(' + ($root.isBlank(imageNameAnswer2) ? ($bucket + '/image/training-tiles/DuoTraining.jpg') : ('\'' + imageNameAnswer2 + '\'') ) + ')', backgroundSize: 'cover'}, 
																{borderColor: !isSelected ? 'white' : responseOptionId[0] == 2 ? responseOptionId[0] == correctAnswer ? '#04A0A8' : '#C73C09' : 2 == correctAnswer ? '#04A0A8' : '#FFFFFF'}]"
													width="100%"
													height="100%"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, 2, page.valueMap.feedback)"
												>
													<v-scroll-y-transition>
														<span style="padding: 30px; font-size: 30px; font-weight: bold; line-height: 30px;"><div v-html="answer2" style="display: table; margin: auto; list-style-position: inside;"></div></span>
													</v-scroll-y-transition>
												</v-card>

											</v-item>
										</div>

										<div :class="'d-flex align-center px-0 py-0 ' + columnOption">
											<!-- Answers 3 with Pictures -->
											<v-item v-slot="{ active, toggle }">
												<v-card
													class="d-flex justify-center align-center"
													style="width: 250px; height: 250px; border-radius: 0px; margin:4px; border: 3px solid #C6C6C6;"
													:style="[{backgroundImage: 'url(' + ($root.isBlank(imageNameAnswer3) ? ($bucket + '/image/training-tiles/DuoTraining.jpg') : ('\'' + imageNameAnswer3 + '\'') ) + ')', backgroundSize: 'cover'}, 
															{borderColor: !isSelected ? 'white' : responseOptionId[0] == 3 ? responseOptionId[0] == correctAnswer ? '#04A0A8' : '#C73C09' : 3 == correctAnswer ? '#04A0A8' : '#FFFFFF'}]"
													width="100%"
													height="100%"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, 3, page.valueMap.feedback)"
												>
													<v-scroll-y-transition>
														<span style="padding: 30px; font-size: 30px; font-weight: bold; line-height: 30px;"><div v-html="answer3" style="display: table; margin: auto; list-style-position: inside;"></div></span>
													</v-scroll-y-transition>
												</v-card>

											</v-item>
										</div>

										<div :class="'d-flex align-center px-0 py-0 ' + columnOption">
											<!-- Answers 4 with Pictures -->
											<v-item v-slot="{ active, toggle }">
												<v-card
													class="d-flex justify-center align-center"
													style="width: 250px; height: 250px; border-radius: 0px; margin:4px; border: 3px solid #C6C6C6;"
													:style="[{backgroundImage: 'url(' + ($root.isBlank(imageNameAnswer4) ? ($bucket + '/image/training-tiles/DuoTraining.jpg') : ('\'' + imageNameAnswer4 + '\'') ) + ')', backgroundSize: 'cover'}, , 
															{borderColor: !isSelected ? 'white' : responseOptionId[0] == 4 ? responseOptionId[0] == correctAnswer ? '#04A0A8' : '#C73C09' : 4 == correctAnswer ? '#04A0A8' : '#FFFFFF'}]"
													width="100%"
													height="100%"
													elevation="0"
													dark
													@click="onClickOption(toggle, !active, 4, page.valueMap.feedback)"
												>
													<v-scroll-y-transition>
														<span style="padding: 30px; font-size: 30px; font-weight: bold; line-height: 30px;"><div v-html="answer4" style="display: table; margin: auto; list-style-position: inside;"></div></span>
													</v-scroll-y-transition>
												</v-card>

											</v-item>
										</div>

									</v-item-group>
								</v-row>
							</v-container>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" class="d-flex justify-end">
				<v-row class="d-flex flex-wrap">
					<v-col cols="11" class="d-flex justify-end pr-0" style="margin-bottom: 13vw;">
						<div>
							<span v-if="isSelected" style="padding-right: 20px;" >
								<span v-if="isCorrect" style="color: #04a0a8; font-weight: bold">
									<v-icon color="#04a0a8" title="That's right!">mdi-check-circle-outline</v-icon>
									That's right!</span>
								<span v-else style="color: #C73C09; font-weight: bold">
									<v-icon color="#C73C09" title="Incorrect">mdi-close-circle-outline</v-icon>
									Incorrect.</span>
							</span>
							<v-btn style="background-color:#04A0A8; border-color: #04A0A8;" :disabled="!validForm || !isSubmitted" height="50" width="193" elevation="0" outlined class="white--text ml-auto text-transform-none" :right="true" @click="nextPage()">Next</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		},
		bgColor: {
			type: String,
			required: true
		},

		//

		question: {
			type: String,
			required: true
		},
		imageNameQuestion: {
			type: String,
			required: true
		},
		answer1: {
			type: String,
			required: true
		},
		answer2: {
			type: String,
			required: true
		},
		answer3: {
			type: String,
			required: true
		},
		answer4: {
			type: String,
			required: true
		},
		imageNameAnswer1: {
			type: String,
			required: true
		},
		imageNameAnswer2: {
			type: String,
			required: true
		},
		imageNameAnswer3: {
			type: String,
			required: true
		},
		imageNameAnswer4: {
			type: String,
			required: true
		},
		correctAnswer: {
			type: Number,
			required: true
		},

		isCorrect: {
			type: Boolean,
			default: null,
			required: false
		},
		isSelected: {
			type: Boolean,
			default: null,
			required: false
		},
		//
		
	},
	computed: {
		parsedHeaderBackgroundColor() {
			if (this.page.valueMap.headerBackgroundColor) {
				return this.page.valueMap.headerBackgroundColor.hexa;
			} else {
				return "#2D2926";
			}
		},
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa;
			} else {
				return "#FFFFFFFF";
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa;
			} else {
				return "#440099";
			}
		},
		columnOption() {
			if (this.page.type == "multiple-choice-1") {
				// horizontal
				return "col-3";
			} else if (this.page.type == "multiple-choice-2") {
				// vertical
				return "col-12";
			} else if (this.page.type == "multiple-choice-picture" || this.page.type == "new-quiz-1") {
				// vertical
				return "col-3";
			} else {
				// horizontal
				return "col-2";
			}
		},
		isMultipleAnswer() {
			return this.getActualCorrectId().length > 1;
		}
	},
	data: function () {
		return {
			validForm: true,
			isSubmitted: false,
			answerFeedback: null,
			responseOptionId: [],
			optionSelected: null,
			active: false,
			width: 0,
			unanswered: true,
		};
	},
	mounted: function () {
		this.width = window.innerWidth;
	},
	methods: {
		validateAnswer(responseOptionId) {
			this.isSubmitted = true;
			this.isSelected = true;
			var actualCorrectId = this.getActualCorrectId();
			this.isCorrect = actualCorrectId == responseOptionId[0] ? true : false
			if (this.$store.state.retakeTrainingModule == false && this.unanswered == false) {
				this.responseOptionId[0] = responseOptionId
			}
		},
		fetchAnswer(response) {
			let pageScore = response.data.map.pageScore.find(item => parseInt(item.pageId) === this.page.id);
			if (pageScore != undefined) {
				this.unanswered = false;
				this.validateAnswer(JSON.parse(pageScore.answer))
			}
		},
		getActualCorrectId() {
			return  this.page.valueMap.correctAnswer ? this.page.valueMap.correctAnswer : 0
		},
		submit() {
			if (this.$refs.form.validate()) {
				this.validateAnswer(this.responseOptionId);

				if (!this.isPreview) {
					this.page.valueMap.responseOptionId = this.responseOptionId;
					// this.save(correctOption);
					this.$emit("submit");
				}
			}
		},
		save() {
			// TODO
			// API Check + Save User Answer
			axios
				.post("/system/page/edit", { page: this.page })
				.then(() => {})
				.catch(() => {});
		},
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.validForm = true;
				this.isSubmitted = false;
				this.isSelected = false;
				this.isCorrect = null;
				this.answerFeedback = null;
				this.responseOptionId = [];
				this.optionSelected = null;
				this.$emit("nextPage");
			}
		},
		onClickOption(toggle, active, optionId, feedback) {
			if (!this.isSelected) {
				toggle();
				if (active) {
					// only one answer
					if (this.getActualCorrectId().length === 1) {
						this.responseOptionId = [];
						this.responseOptionId.push(optionId);
					} else {
						if (this.responseOptionId.indexOf(optionId) === -1) {
							this.responseOptionId.push(optionId);
						}
					}
				} else {
					this.responseOptionId =	this.responseOptionId.filter(option => option !== optionId);
				}
				this.isSelected = !this.$root.isBlank(this.responseOptionId) ? true : false;
				this.answerFeedback = feedback;
				this.submit()
			}
		}
	}
};
</script>

<style scoped>
.transparent-card {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.form-group {
	height: 80vh;
}
.radio-question .v-label {
	font-size: 20px !important;
}
.radio-question .error--text {
	font-size: 20px !important;
}
.v-application .title {
	font-size: 26px !important;
	font-weight: 600;
	letter-spacing: 1px !important;
}
.correct-answer {
	color: green;
	font-size: 20px;
	font-weight: bold;
}
.wrong-answer {
	color: red;
	font-size: 20px;
	font-weight: bold;
}
.question-answer {
	border-left: 2px solid rgba(224, 224, 224, 0.5);
}
</style>