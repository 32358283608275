<template>
  <v-container fluid>
    <subHeader />
    <div class="quiz">
      <iframe :src="iframeSrc" frameborder="0" scrolling="yes" width="100%" height="100%"
        id="ngxFrame67c7fc45-b1e8-4e2f-93d8-7327c7e9803a" allowTransparency="true"></iframe>
    </div>
  </v-container>
</template>

<style scoped>
.quiz {
  height: 130vh;
  overflow-y: auto !important;
  width: 100%;
}

.quiz iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('principal'));
    return {
      iframeSrc: `https://xd.wayin.com/display/container/dc/67c7fc45-b1e8-4e2f-93d8-7327c7e9803a?nc.customerid=${userData?.email}&nc.customeridtype=${userData?.id}`
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>