<template>
	<div class="pr-2" style="display: flex">
		<!--    To turn off autocomplete when pnr-input is password-->
		<input v-if="inputType === 'password'" autocomplete="off" name="hidden" type="text" style="display: none" />
		<template v-if="$vuetify.breakpoint.mdAndUp">
			<singlePnrInput
				v-for="(item, i) in numInputs"
				:key="i"
				:focus="activeInput === i"
				:value="pnr[i]"
				:separator="separator"
				:input-type="inputType"
				:input-classes="inputClasses"
				:is-last-child="i === numInputs - 1"
				:should-auto-focus="shouldAutoFocus"
				@on-change="handleOnChange"
				@on-keydown="handleOnKeyDown"
				@on-paste="handleOnPaste"
				@on-focus="handleOnFocus(i)"
				@on-blur="handleOnBlur"
			/>
		</template>
		<template v-else>
			<singlePnrInputMobile
				v-for="(item, i) in numInputs"
				:key="i"
				:focus="activeInput === i"
				:value="pnr[i]"
				:separator="separator"
				:input-type="inputType"
				:input-classes="inputClasses"
				:is-last-child="i === numInputs - 1"
				:should-auto-focus="shouldAutoFocus"
				@on-change="handleOnChange"
				@on-keydown="handleOnKeyDown"
				@on-paste="handleOnPaste"
				@on-focus="handleOnFocus(i)"
				@on-blur="handleOnBlur"
			/>
		</template>
		<div style="display: flex; align-items: center">
			<input maxlength="1" pattern="[0-9a-zA-Z]" value="H" class="h-square lighten-2 pnr-input" disabled="disabled" />
		</div>
	</div>

</template>
<style scoped>
.h-square {
	color: #2D2926;
	background-color: rgb(224, 224, 224) !important;
}
</style>
<script>
import singlePnrInput from "./singlePnrInput.vue";
import singlePnrInputMobile from "./mobile/singlePnrInputMobile.vue";

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default {
	name: "pnrInput",
	components: {
		singlePnrInput,
		singlePnrInputMobile
	},
	props: {
		bookingCode: {
			type: String,
			default: ""
		},
		numInputs: {
			default: 5
		},
		separator: {
			type: String,
			default: " "
		},
		inputClasses: {
			type: String
		},
		inputType: {
			type: String,
			validator(value) {
				return ["number", "tel", "password"].includes(value);
			}
		},
		shouldAutoFocus: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeInput: 0,
			pnr: this.bookingCode.split("") || [],
			oldPnr: []
		};
	},

	methods: {
		/* eslint-disable no-console */
		handleOnFocus(index) {
			this.activeInput = index;
		},
		handleOnBlur() {
			if (!this.pnr[this.activeInput]) {
				return this.$emit("on-blur");
			}
			this.activeInput = -1;
		},
		// Helper to return OTP from input
		checkFilledAllInputs(indexItem) {
			if (this.pnr.join("").length === this.numInputs) {
				return this.$emit("on-complete", this.pnr.join(""), indexItem);
			}
			return "Wait until the user enters the required number of characters";
		},
		// Focus on input by index
		focusInput(input) {
			this.activeInput = Math.max(Math.min(this.numInputs - 1, input), 0);
		},
		// Focus on next input
		focusNextInput() {
			this.focusInput(this.activeInput + 1);
		},
		// Focus on previous input
		focusPrevInput() {
			this.focusInput(this.activeInput - 1);
		},
		// Change OTP value at focused input
		changeCodeAtFocus(value, indexItem) {
			if (typeof value == "string") this.pnr = Array.from(this.pnr);

			this.oldPnr = Object.assign([], this.pnr);
			this.$set(this.pnr, this.activeInput, value);
			if (this.oldPnr.join("") !== this.pnr.join("")) {
				this.$emit("on-change", this.pnr.join(""), indexItem);
				this.checkFilledAllInputs(indexItem);
			}
		},
		// Handle pasted OTP
		handleOnPaste(event, indexItem) {
			event.preventDefault();
			const pastedData = event.clipboardData
				.getData("text/plain")
				.toUpperCase()
				.toString()
				.slice(0, this.numInputs - this.activeInput)
				.split("");
			if (!pastedData.join("").match(/^[a-zA-Z0-9]+$/i)) {
				return this.$emit("on-change", undefined, indexItem);
			}
			if (typeof this.pnr == "string") this.pnr = Array.from(this.pnr);
			// Paste data from focused input onwards
			const currentCharsInOtp = this.pnr.slice(0, this.activeInput);
			const combinedWithPastedData = currentCharsInOtp.concat(pastedData);
			this.$set(this, "pnr", combinedWithPastedData.slice(0, this.numInputs));
			this.focusInput(combinedWithPastedData.slice(0, this.numInputs).length);
			return this.checkFilledAllInputs(indexItem);
		},
		handleOnChange(value, indexItem) {
			this.changeCodeAtFocus(value, indexItem);
			this.focusNextInput();
		},
		// Handle cases of backspace, delete, left arrow, right arrow
		handleOnKeyDown(event, indexItem) {
			switch (event.keyCode) {
				case BACKSPACE:
					event.preventDefault();
					this.changeCodeAtFocus("", indexItem);
					this.focusPrevInput();
					break;
				case DELETE:
					event.preventDefault();
					this.changeCodeAtFocus("", indexItem);
					break;
				case LEFT_ARROW:
					event.preventDefault();
					this.focusPrevInput();
					break;
				case RIGHT_ARROW:
					event.preventDefault();
					this.focusNextInput();
					break;
				default:
					break;
			}
		}
	}
};
</script>
