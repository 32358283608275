<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1266" max-height="345"
            style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-tiles/Homepage.NewSkiLand.jpg'" />
        </v-col>
      </v-row>
      <v-row>
        <div class="pa-6" style="max-width: 1138px">
          <h2>
            Discover a winter wonderland the size of New Zealand
          </h2>
          <p>
            Want to be in to WIN the ultimate experience in New Ski-Land for you and a friend, while learning all about how to sell a New Zealand winter holiday with confidence?
          </p>
          <p>
            Enter now to be in the draw to win from an exciting prize pool, including a major prize valued at over <b class="duo-purple">$10k</b>, with flights, accommodation, ski passes, a range of winter activities, and more! Plus, there are minor prizes up for grabs too.
          </p>

          <p>
            <b>To be in the draw to win the MAJOR & MINOR PRIZES:</b>
          </p>

          <div>
            <b>MINOR PRIZES:</b>
          </div>
          <ol style="margin-left:2rem;">
            <li>Complete the registration form <a href="https://traveltrade.newzealand.com/au/winter-newskiland/incentive/" target="_blank"><span class="duo-purple">here</span></a>.</li>
            <li>Register for the Tourism New Zealand <a href="https://traveltrade.newzealand.com/au/contact/sign-up-to-our-newsletter/" target="_blank"><span class="duo-purple">newsletter</span></a>.</li>
          </ol>

          <div class="pt-4">
            <b>MAJOR PRIZE</b><br />
            Complete the two steps above and:
          </div>
          <ol style="margin-left:2rem;">
            <li>Log any Air New Zealand bookings from Australia to New Zealand in 
              <router-link to="/sales" style="color: black; text-decoration: none;">
                <u class="duo-purple"><b>duo</b>sales</u>
              </router-link> 
            from 15 April – 19 May 2024.</li>
            <li><u>Or</u> complete the Tourism New Zealand "How to sell Ski" <a href="https://onlinemodules.newzealand.com/login/index.php" target="_blank"><span class="duo-purple">training module</span></a>.</li>
          </ol>

          <p>
            <b>
              Remember the more sales you log the more chances to WIN!
            </b>
          </p>

        <div style="padding:1rem;background-color:#EAEAEA;">
        <table>
          <tr>
            <td width="60%">
              <p><b>What’s up for grabs:</b></p>
              <p><b class="duo-purple">MAJOR PRIZE:</b></p>
              <p>
                <b>Air New Zealand</b> - 2x return Economy flights from Australia to New Zealand<br/>
                <b>THL</b> - 7-day rental in a maui Ultima (2 Berth) return out of Christchurch or Queenstown<br/>
                <b>Kathmandu</b> - $2,000 Kathmandu voucher<br/>
              </p>
            </td>
            <td width="40%">
               <v-img style="background-size: cover; background-position: center center;" :src="$bucket + '/image/dashboard-tiles/Plane.NewSkiLand.png'" />
            </td>
          </tr>
        </table>
        <b>Christchurch</b>
        <hr style="margin:7px 0"/>
        <ul style="margin-left:2rem">
          <li><b>Black Cat Cruises</b> - Akaroa Nature Cruise</li>
          <li><b>The Mayfair</b> - 2 nights stay in King Room & breakfast for two</li>
          <li><b>Willowbank Wildlife Reserve</b> - Entry to Willowbank Wildlife Reserve for 2pax</li>
        </ul>
        <br/>
        <b>Mackenzie</b>
        <hr style="margin:7px 0"/>
        <ul style="margin-left:2rem">
          <li><b>The Hermitage</b> - 1 x night at The Hermitage in Premium Plus Super King room including dinner, and breakfast</li>
          <li><b>Big Sky Stargazing</b> - Stargazing tour</li>
          <li><b>Tekapo Springs</b> - Tekapo Springs Winter Wonderland & Wellness Package for Two</li>
        </ul>
        <table style="margin-left:12em;">
        <tr>
          <td>
            <ul>
              <li>Hot Pools/Steam & Sauna</li>
              <li>Ice Skating</li>
              <li>Snow Tube</li>
            </ul>
          </td>
          <td width="10%"></td>
          <td style="vertical-align:top;">
            <ul>
              <li>Snow Play</li>
              <li>1-hour Swedish Massage at Glacial Day Spa</li>
            </ul>
          </td>
        </tr>
        </table>
        <br/>
        <b>Queenstown</b>
        <hr style="margin:7px 0"/>
        <ul style="margin-left:2rem">
          <li><b>Onsen</b> - The Original Onsen Experience</li>
          <li><b>Altitude Tour</b> - Altitude Wine Sampler Tour</li>
          <li><b>Real NZ</b> - Milford Sound Day Trip from Queenstown</li>
          <li><b>Scenic Suites Queenstown</b> - 2 nights’ accommodation in Scenic Suites Queenstown one-bedroom suite including breakfast and free parking</li>
          <li><b>NZSki</b> - Intro to Snow Package</li>
          <li><b>Real NZ</b> - Cardrona Ski Package (with Lift Pass, Rentals & Lessons)</li>
        </ul>
        </div>

        <div style="height:1.5rem"></div>

      <div style="padding:1rem;background-color:#EAEAEA;">
        <p><b class="duo-purple">MINOR PRIZES:</b></p>
        <ul style="margin-left:2rem">
          <li><b>Kathmandu</b> - 5 x $200 vouchers</li>
          <li><b>New Zealand Wine</b></li>
          <li><b>Tourism New Zealand</b> - 10x merchandise packs, including: backpack, drink bottle, keep cup, notepad, pen and pin</li>
        </ul>
        </div>
        </div>
      </v-row>

      <v-row class="px-6 pt-8 pb-1" style="max-width: 1138px">
        <h2>
          Booking a New Zealand winter holiday Tips from your Air New Zealand BDMs
        </h2>
        <p>
          Check out our video below of NSW BDMs Stella and Gabe as they share some tips for booking your customer's next getaway to New Ski-Land this winter season. 
        </p>
      </v-row>
      
      <v-row style="max-width: 1138px">
        <v-col cols="12">
          <div style="text-align:center;">
          <iframe width="875" height="492" src="https://www.youtube.com/embed/lddxCCdPy_4?si=Vifq5IPVTCvRAKDZ" title="Discover the Ultimate Ski Land - Tips from your Air New Zealand BDM&#39;s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <br/>
          <a class="pl-4" style="font-size: 13px; color: black; font-style: italic;" :href="$base + '/asset/file/New-Ski-Land-Incentive-terms-and-conditions.pdf'" target="_blank"><span class="duo-purple">Terms and Conditions apply.</span></a>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 28px;
}

p {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  data() {
    return {}
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    visit(url) {
      this.$router.push(url);
    },
    goToTC() {
      const routeData = this.$router.resolve({ path: '/NewSkiLandTC' });
      window.open(routeData.href, '_blank');
    },
  }
};
</script>